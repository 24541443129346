import axios from 'axios';
import config from '../config.json';

export const approveOrderByAdmin = async (formData) => {
  try {
    const { data } = await axios.post(
      config.serverUrl + '/admin/order/update',
      formData
    );
    return data;
  } catch (error) {
    console.log('error in approve orders:--', error);
    return error;
  }
};

import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva } from "class-variance-authority";

import { cn } from "../../lib/utils"

const buttonVariants = cva(
  "h-9 flex items-center justify-center font-bold text-sm px-4 rounded-lg transition-all disabled:pointer-events-none disabled:opacity-70",
  {
    variants: {
      variant: {
        default:
          "bg-btn-primary hover:bg-btn-primary/80 border-none text-white",
        secondary:
          "bg-transparent hover:bg-white/20 text-black/80 border border-solid border-black/25",
        tag:"bg-white/20 border-none text-gray-600 h-5 rounded-md px-2 opacity-100 cursor-text"
      },
    },
    defaultVariants: {
      variant: "default"
    },
  }
)

const Button = React.forwardRef(({ className, variant, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "button"
  return (
    (<Comp
      className={cn(buttonVariants({ variant, className }))}
      ref={ref}
      {...props} />)
  );
})
Button.displayName = "Button"

export { Button, buttonVariants }

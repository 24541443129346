import React from 'react';
import Body from './Body';

const Connect = () => {
  return (
    <main className=' flex flex-col justify-between items-center bg-theme'>
      <Body />
    </main>
  );
};

export default Connect;

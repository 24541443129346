import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getOrderId } from '../helpers';
import { LuUserCircle2 } from 'react-icons/lu';
import StudioHeader from './header/StudioHeader';

export default function LeftCol() {
  const [userAuthData, setUserAuthData] = useState();
  const { pathname } = useLocation();
  const orderId = getOrderId(pathname);
  const styles = {
    tabs: 'cursor-pointer transition-all text-base font-medium flex items-center justify-center sm:justify-start px-2 py-1.5 transition-all w-full rounded-lg hover:bg-black/5'
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('authData'));
    if (data) {
      setUserAuthData(data);
    }
  }, []);

  return (
    <div className='w-full sm:w-[212px] sm:h-full justify-end bg-bg-secondary border-r border-solid border-white/20'>
      <div className='flex flex-col justify-start h-full w-full px-3 sm:py-8'>
        {userAuthData ? (
          <p className='hidden h-10 text-tx-primary text-sm font-medium sm:flex items-center gap-4 pb-6 ps-2 border-b border-solid border-white/30'>
            <LuUserCircle2 size={20} className='opacity-60' />{' '}
            {userAuthData.email?.slice(0, 10) + '...'}
          </p>
        ) : null}
        <div className='sm:flex-1 flex sm:flex-col items-start sm:mt-6'>
          <Link
            to='/studio/view-orders'
            className={`${styles.tabs} gap-4 ${
              pathname === '/studio/view-orders' || orderId
                ? 'text-btn-primary'
                : 'text-tx-secondary'
            }`}
          >View orders</Link>
          <Link
            to='/studio/create-order'
            className={`${styles.tabs} gap-4 ${
              pathname === '/studio/create-order'
                ? 'text-btn-primary'
                : 'text-tx-secondary'
            }`}
          > Create order</Link>
          <Link
            to='/studio/about'
            className={`${styles.tabs} gap-4 ${
              pathname === '/studio/about'
                ? 'text-btn-primary'
                : 'text-tx-secondary'
            }`}
          > About</Link>
          <StudioHeader fromHeader={false} />
        </div>
        <p className='hidden sm:block text-[13px] font-light text-tx-secondary'>
          Help: support@3dhoudini.com
        </p>
      </div>
    </div>
  );
}

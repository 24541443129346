import { akcbLoader } from '../../assets/images';

const AkcbLoader = () => {
  return (
    <div className='h-80 w-full flex justify-center items-center text-tx-secondary '>
      <img height={60} width={60} src={akcbLoader} alt='loader' />
    </div>
  );
};

export default AkcbLoader;

import Home from '../pages/home';
import About from '../pages/about';
import Team from '../pages/team';
import News from '../pages/news';
import wagmiProject from '../pages/wagmiProject';
import MonumentsProject from '../pages/monumentsProject';
import LearnMore from '../pages/monumentsProject/learnMore';
import ContactUs from '../pages/contact';
import PrintToken from '../pages/printToken';
import Printer from '../pages/printer';
import TandC from '../pages/t&c';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import ShippingInformation from '../pages/shipping-policy';
import ReturnPolicy from '../pages/ReturnPolicy/ReturnPolicy';
import PrintYourNFT from '../pages/printYourNFT';
import Connect from '../pages/servicebureau/connect';
import ViewOrders from '../pages/servicebureau/view-orders';
import OrderDetails from '../pages/servicebureau/view-orders/orderId';
import CreateOrder from '../pages/servicebureau/create-order';
import StudioAbout from '../pages/servicebureau/about';
import Admin from '../pages/servicebureau/admin';

export const allRoutes = [
  {
    path: '/',
    Component: Home
  },
  {
    path: '/about',
    Component: About
  },
  {
    path: '/team',
    Component: Team
  },
  {
    path: '/news',
    Component: News
  },
  {
    path: '/projects/wagmi',
    Component: wagmiProject
  },
  {
    path: '/projects/monuments',
    Component: MonumentsProject
  },
  {
    path: '/projects/monuments/learnmore',
    Component: LearnMore
  },
  {
    path: '/contact',
    Component: ContactUs
  },
  {
    path: '/print-token',
    Component: PrintToken
  },
  {
    path: '/printer',
    Component: Printer
  },
  {
    path: '/terms-and-conditions',
    Component: TandC
  },
  {
    path: '/privacy-policy',
    Component: PrivacyPolicy
  },
  {
    path: '/shipping-information',
    Component: ShippingInformation
  },
  {
    path: '/return-policy',
    Component: ReturnPolicy
  },
  {
    path: '/print-your-nft',
    Component: PrintYourNFT
  },
  {
    path: '/studio/connect',
    Component: Connect
  },
  {
    path: '/studio/view-orders',
    Component: ViewOrders
  },
  {
    path: '/studio/view-orders/:orderId',
    Component: OrderDetails
  },
  {
    path: '/studio/create-order',
    Component: CreateOrder
  },
  {
    path: '/studio/about',
    Component: StudioAbout
  },
  {
    path: '/studio/admin',
    Component: Admin
  }
];

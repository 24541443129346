import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ContactForm from '../../components/ContactForm';
import axios from 'axios';
import config from '../../config.json';
import { showToast } from '../../components/toast';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import './modal.css';
import LoadingBar from 'react-top-loading-bar';

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(false);
  const ref = useRef(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    companyName: Yup.string(),
    message: Yup.string().required('Request is required')
  });

  const generateRequestId = () => {
    const timestamp = new Date().getTime();
    return `${timestamp}`;
  };

  const initialValues = {
    name: '',
    email: '',
    companyName: '',
    message: ''
  };

  const handleCaptchaComplete = () => {
    // Enable the submit button or perform any other actions
    setIsCaptchaCompleted(true);
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      ref.current.continuousStart();
      if (!isCaptchaCompleted) {
        showToast('error', 'Please complete the captcha.');
        return;
      }
      const { data } = await axios.post(config.server_url + 'contact-us', {
        ...values,
        requestId: generateRequestId()
      });
      await axios.post(
        'https://api.studio.3dhoudini.tech/send-contact-us-confirmation',
        {
          ...values
        }
      );
      showToast('success', data.message);
      resetForm();
      // setSuccessModalOpen(true);
    } catch (e) {
      if (e.response?.data?.message) {
        showToast('warn', e.response.data.message);
      } else {
        showToast('error', 'Something went wrong!');
      }
    } finally {
      setLoading(false);
      ref?.current?.complete();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    const notification = location.state?.notification;

    if (notification === 'success') {
      setSuccessModalOpen(true);
    }
  }, [location.state?.notification]);

  const handleModalOkayClick = () => {
    setSuccessModalOpen(false);
    navigate('/about');
  };

  return (
    <>
      <LoadingBar
        color='#FF5733'
        height={3}
        containerStyle={{ zIndex: 101 }}
        transitionTime={300}
        ref={ref}
      />
      {/*{loading && (*/}
      {/*  <div className='loader-overlay'>*/}
      {/*    <div className='loader'>Wait...</div>*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className='contact-container animate-toastFade overflow-hidden flex justify-center items-start bg-theme'>
        <div className='inner-contact w-[90%] md:w-[1240px]'>
          <h1 className='text-center mt-16 md:mt-20 text-5xl md:text-7xl font-extrabold text-black'>
            Get in touch
          </h1>
          <div className='about-contact grid justify-items-center mb-40 md:mb-72'>
            <p className='contact-sub-info mb-8 md:mb-8 pt-12 md:pt-20 text-lg md:text-2xl font-normal text-black/80'>
              What would you like to know?
            </p>
            <ContactForm
              formik={formik}
              onCaptchaComplete={handleCaptchaComplete}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={successModalOpen}
        onRequestClose={() => setSuccessModalOpen(false)}
        className='modal'
        overlayClassName='overlay'
      >
        <h2>Success!</h2>
        <p>Your message has been sent successfully!</p>
        <button onClick={handleModalOkayClick}>Okay</button>
      </Modal>
    </>
  );
};

export default ContactUs;

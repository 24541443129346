import React from 'react';

const LeftImageCard = ({ cardData }) => {
  console.log('left', cardData.title);
  return (
    <>
      <div className='left-text relative z-1 content-center md:mr-0'>
        {cardData.title === 'Key Features' ? (
          <h2 className='text-2xl font-bold text-black '>
            {cardData.title}
          </h2>
        ) : (<>
          <h2 className='text-2xl font-bold text-black'>{cardData.title}</h2>
          <h2 className='text-4xl font-extrabold text-black'>
            {cardData.heading}
          </h2>
          <p className='md:mr-4 md:text-lg text-base leading-snug text-black'>
            {cardData.description}
          </p></>
        )}
        {/*
        <h2 className='text-4xl font-extrabold text-black'>
          {cardData.heading}
        </h2>
        <p className='md:mr-4 md:text-lg text-base leading-snug text-black'>
          {cardData.description}
        </p>*/}
      </div>

      {cardData.image ? (
        <img className='drop-shadow-2xl card-img' src={cardData.image} alt='' width='320px'/>
      ) : (
        ''
      )}
    </>
  );
};

export default LeftImageCard;

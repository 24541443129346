const entitiesForDataSharing = [
  'Credit reference agencies, banks, and payment issuers (such as Visa, MasterCard and American Express)',
  'Third-party marketing providers, such as Facebook, Instagram, or Twitter to deliver advertising to you if certain conditions are met',
  'A business that acquires all or part of 3dhoudini to continue to provide services to you in a manner consistent with this Policy.',
  'Governmental bodies, regulators, law enforcement agencies, courts/tribunals, and insurers are required to do so to comply with our legal obligations or to exercise our legal rights, or to prevent, detect, or investigate a crime, to safeguard and protect our employees, customers, and other individuals.'
];
const dataSecurityMeasures = [
  'Encryption of data',
  'Regular cybersecurity assessments internally, as well as, of service providers who may handle your personal data',
  'Penetration testing of systems',
  'Security controls that protect the entire company infrastructure from external attack and unauthorized access',
  'Internal policies setting out our data security approach and training for employees'
];

const dataProcessingRights = [
  'The right to receive a copy of personal data we hold about you',
  'The right to request that we delete personal data we hold about you when we no longer have a reason to retain it',
  'The right to request that the data relating to you be corrected or updated',
  'The right to opt out of any marketing communications that we may send you using your personal data',
  'The right to restrict the processing of personal data',
  'The right to object to the processing of personal data',
  'You may exercise any of the above rights by contacting us at hello@3dHoudini.com'
];

const legalBasisForDataProcessing = [
  'The legal basis for processing customer data is that it is necessary for the legitimate interests of 3dHoudini, in:',
  'Facilitating the purchase, sale, and trade of goods and services to our customers',
  'Promoting, marketing, and advertising of our services',
  'Sending promotional communications which are relevant and tailored to individual customers’ preferences and needs',
  'Improving existing services and developing new ones',
  'Complying with our legal and regulatory obligations',
  'Preventing, investigating, and detecting crime or working with law enforcement concerning customer’s complaints, disputes, or the like',
  'Any other purpose aimed at complying with our duties or exercising our rights under a contract for goods or services to a customer or complying with our legal obligations'
];

export const PrivacyPolicy = [
  {
    id: 'news-1', // Unique identifier for the first news item
    comments: 5,
    headline: 'What this Policy Does',
    description: [
      {
        type: 'text',
        value:
          '3D Houdini, Inc. is a company registered in the State of Delaware, and is referred to herein as &ldquo;3dHoudini &rdquo;, &ldquo;us &rdquo; or  &ldquo;we &rdquo;'
      },
      {
        type: 'text',
        value:
          '3dHoudini recognizes the need for appropriate protection and management of personal information shared with us. This Privacy Policy applies when you visit our websites at www.3dhoudini.com, www.3dhoudini.io, and similarly identified platforms, regardless of the domain extension (the “Sites”), and the related services made available by 3dHoudini through other platforms (collectively, the &ldquo;Services &rdquo;).           '
      },
      {
        type: 'points',
        values: [
          'This Privacy Policy describes:',
          'What personal data we collect',
          'How we use the personal data we collect',
          'How we ensure your privacy is maintained',
          'Your legal rights and other choices relating to your personal data'
        ]
      },
      {
        type: 'text',
        value:
          'By engaging with the Sites you are accepting and consenting to the practices described in this Privacy Policy.'
      },
      {
        type: 'sub_head',
        value: 'Personal Information We Collect           '
      },
      {
        type: 'text',
        value: '3dHoudini collects the following personal data about you:'
      },
      {
        type: 'points',
        values: [
          'Your first and last names, age, date of birth, gender, occupation, nationality, languages spoken, marital status',
          'Your contact details: postal address, including billing and delivery address, telephone numbers (including mobile numbers), and e-mail address',
          'Purchases, sales, and trades or similar orders made by you',
          'Your online browsing activities',
          'Your username and password',
          'When you make a purchase or place an order with us, your payment details, which may include credit card information',
          'Your communication and marketing preferences',
          'Your photos when adding to sell & trade items on our marketplace',
          'Your interests, feedback survey responses, and preferences',
          'Your location',
          'Your correspondence, SMS messages, and communications',
          'Publicly available data, including data that you have shared via public social media platforms',
          'Recruitment information',
          'Other Information. This list is non-exhaustive. In specific instances, we may need to collect additional data for the purposes set out in this Privacy Policy. For example, we may collect other personal details you choose to provide to us when corresponding with us by e-mail, telephone participating in surveys, sweepstakes, contests, or otherwise visiting and interacting within our service.'
        ]
      },
      {
        type: 'sub_head',
        value: 'How we use personal data'
      },
      {
        type: 'text',
        value: 'General Uses'
      },
      {
        type: 'points',
        values: [
          'To provide goods and services to you',
          'To make a tailored website available to you',
          'To perform a contract with you',
          'To respond to your inquiries and fulfill your requests',
          'To send administrative information to you, such as information regarding the service',
          'To manage any registered account that you have with us',
          'To verify your identity',
          'To perform quality controls and customer satisfaction',
          'To provide authentication, verification, and accurate assessment of a product and provide you with related customer service',
          'To ship products purchased, sold, or traded by you',
          'For customer insight and marketing research to better understand your needs and preferences with your agreement, to contact you electronically about promotional offers and products which we think may interest you',
          'For crime and fraud prevention, detection, and related purposes',
          'Where we have a legal right or duty to use or disclose your information',
          'For security purposes, such as to protect our company, our customers, or our platforms, to address complaints you make'
        ]
      },
      {
        type: 'text',
        value: 'Promotional Communications'
      },
      {
        type: 'text',
        value:
          'With your consent, we use personal data to send you private messages via email, text, and other electronic communication channels to update you about products or services that are of interest or relevance to you. You have the right to opt-out of receiving these types of communications at any time by making use of the “unsubscribe” links included in the messages, via the contact channels set out in this Policy.           '
      },
      {
        type: 'text',
        value: 'Online Advertising'
      },
      {
        type: 'text',
        value:
          'If you visit our websites, you may be served personalized advertisements for our products or services while using other websites or social media platforms.  Any advertising you see will relate to products you have viewed while browsing our websites on your computer or other devices, or which we believe are of interest to you.   These advertisements are provided via our service providers using “cookies” and similar technologies placed on your computer and other devices.  You can get further information on the use of cookies, including how to disable or remove them at any time, below '
      },
      {
        type: 'text',
        value: 'Our Service Providers and Suppliers'
      },
      {
        type: 'text',
        value:
          'We may need to share your personal data with some of our service partners in order to make certain services available to you.  These include IT, delivery and marketing service providers, fraud prevention, and identity identification partners.'
      },
      {
        type: 'text',
        value:
          'We only allow our service providers to handle your personal data when they have assured us that they apply appropriate data protection and service controls.  We also impose contractual obligations on our service providers relating to data protection security and they can only use your data to provide services to us and to you, and for no other purpose.            '
      },
      {
        type: 'text',
        value: 'Other Third Parties'
      },
      {
        type: 'text',
        value: 'We may share your data with:'
      },
      {
        type: 'points',
        values: entitiesForDataSharing
      },
      {
        type: 'text',
        value:
          'For internal business reasons, we may anonymize, aggregate, and de-identify the personal data that we collect and use such data for our own internal business purposes, including sharing it with our current and prospective customers, business partners, affiliated businesses, agents, and other third parties for commercial, statistical and market research purposes, for example, to allow those parties to analyze patterns among groups of people, and conducting research on demographics, interests, and behavior. Sharing excludes text messaging originator opt-in data and consent; this information will not be shared with any third parties.'
      },
      {
        type: 'sub_head',
        value: 'How We Protect Your Data'
      },
      {
        type: 'points',
        values: dataSecurityMeasures
      },

      {
        type: 'text',
        value: 'Our security measures include:'
      },
      {
        type: 'points',
        values: dataProcessingRights
      },
      {
        type: 'sub_head',
        value: 'Basis for Using Personal Data'
      },

      {
        type: 'points',
        values: legalBasisForDataProcessing
      },
      {
        type: 'sub_head',
        value: 'Cookies + other web technologies        '
      },
      {
        type: 'text',
        value:
          'Cookies: Our Services may use persistent and temporary “cookies” and similar technologies (collectively, “cookies”). Cookies are small text files sent to your computer for record-keeping purposes and this information is stored in a file on your computer’s hard drive. A persistent cookie retains user preferences for a particular website allowing those preferences to be used in future browsing sessions and remains valid until its set expiry date (unless deleted by the user before the expiry date). A temporary cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.         '
      },
      {
        type: 'text',
        value:
          'Information collected through the use of cookies includes user operating system data, unique device ID, app usage data, login information, browser type and version, time zone setting or browser plug-in types and versions, location data, content interaction information, device functionality, and service metrics information, such as when a device is in use, application usage, connectivity data, and occurrences of technical errors.         '
      },
      {
        type: 'text',
        value:
          'We use cookies for several purposes, including: (i) to improve user experience, (ii) to collect anonymous and aggregated statistical data about users’ visits or use of our Service. We use this data to analyze how our Service is used and to improve it, as well as (iii) to advertise third-party products online.         '
      },
      {
        type: 'text',
        value:
          'When you enter our websites, you will be shown a pop-up cookie notification banner that informs the user that our site uses cookies and similar technologies. You will have the opportunity to “Accept all Cookies” or “Reject Cookies.”  We deem you to have consented to non-essential cookies unless you have disabled them using your browser settings.  If you have already consented to cookies on our website, you may change your preference at any time by completely clearing all cookies on your browser and then re-entering our site.  You can always disable cookies in your browser’s preferences even if you have consented to the use of cookies in the past.          '
      },
      {
        type: 'sub_head',
        value: 'Children'
      },
      {
        type: 'text',
        value:
          'The Service is not directed to children under 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information without parental consent, please contact us. If we become aware that a child under 13 has provided us with personal information without parental consent, we take steps to remove such information and terminate the child,s account.         '
      },
      {
        type: 'text',
        value:
          'Some of these third parties include YouTube Terms of Service and Google Privacy Policy. YouTube API Services are being used.'
      },
      {
        type: 'sub_head',
        value: 'California residents'
      },
      {
        type: 'text',
        value:
          'We do not disclose your personal information to third parties for the purpose of directly marketing their goods or services to you unless you first agree to such disclosure. If you have any questions regarding this policy or would like to change your preferences, you may contact us via the contact information provided in this Policy.        '
      },
      {
        type: 'sub_head',
        value: 'Data Processing and Storage'
      },
      {
        type: 'text',
        value:
          'We process and store information subject to this Privacy Policy in the United States Information we collect from you will be transferred to, or stored at, a destination in the United States or another destination outside of the European Economic Area (“EEA”). The countries concerned may not provide the same legal standards for the protection of your personal data that you have in the EEA or the United Kingdom. Where we transfer your personal data to countries outside of the EEA, we will take reasonable steps to ensure that your personal data will continue to be protected: By submitting your personal data, you agree to this transfer, storing or processing.  '
      },
      {
        type: 'sub_head',
        value: 'Modifications to this Privacy Policy'
      },
      {
        type: 'text',
        value:
          'We may revise this Privacy Policy from time to time. The most current version of the Privacy Policy will govern our collection, use, and disclosure of information about you. If we make material changes to this Privacy Policy, we will notify you by email or by posting a notice on the Service prior to the effective date of the changes. By continuing to access or use the Service after those changes become effective, you acknowledge the revised Privacy Policy.         '
      },
      {
        type: 'sub_head',
        value: 'Contact Us'
      },
      {
        type: 'text',
        value:
          'You may contact us concerning our Privacy Policy by writing to hello@3dHoudini.com Please write Privacy Policy Issue on the subject line or write to us at: 3dHoudini 136 Wall Street Lead, SD 57754 Updated on January, 15th 2024 '
      }
    ]
  }
];

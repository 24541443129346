export const ReturnPolicy = [
  {
    id: 'news-1', // Unique identifier for the first news item
    comments: 5,
    headline: '',
    description: [
      {
        type: 'text',
        value:
          '3dHoudini is committed to your complete satisfaction and enjoyment of your purchase. We offer a hassle-free, 7-day return policy for products that were damaged in shipping, of faulty manufacturing, or not matching the item purchased.'
      },
      {
        type: 'text',
        value:
          'For any damaged, faulty, or incorrect product, 3dHoudini will replace the item or offer a full refund based on the original payment method. Please return all items in the same condition, with the same accessories, and back to the original point of sale through which the item was sold.'
      },
      {
        type: 'text',
        value:
          'Refunds will be processed after inspection. Please allow 10 business days for the refund to be reflected on your original payment method. For any purchases made using cryptocurrency, any refunds will be issued in Fiat currency of the 3dHoudini office that processed the transaction (e.g., USD, CHF, HKD, SGD, or AED).'
      },
      {
        type: 'text',
        value:
          'Please note that we are unable to accept returns for any items that have been personalized or altered in any way. If items that were sold with the purchase are missing or damaged, we may charge a restocking fee or reject the item.'
      },
      {
        type: 'sub_head',
        value: 'Steps for Returns/Replacements:'
      },
      {
        type: 'points',
        values: [
          'First, contact 3dHoudini within 7 days from the date of shipment to request a return authorization.',
          'Once the return authorization has been issued, we will email you pre-paid shipping labels. Please follow our Shipping Information to ensure that your item is protected during transit.',
          'Be sure to include all original contents in your return package, including the boxes, documents, and accessories'
        ]
      },

      {
        type: 'text',
        value:
          'You may contact us concerning Returns and Replacements by writing to hello@3dHoudini.com'
      },
      {
        type: 'text',
        value:
          'Please write Return/Replacement Issue in the subject line or write to us at: 3dHoudini136 Wall StreetLead, SD 57754'
      },
      {
        type: 'sub_head',
        value: 'International Returns'
      },
      {
        type: 'text',
        value:
          'International buyers may return their purchase; however, any return shipping costs, customs fees, and taxes will be the buyer’s responsibility. All other aspects of the return policy listed above still apply for International returns.'
      }
    ]
  }
];

import React, { useEffect, useState } from 'react';
import LeftImageCard from './LeftImageCard';
import RightImageCard from './RightImageCard';
import MobileCard from './MobileCard';
import { aboutDetails, printTokenCenter, PrintTokens } from '../../constants';
import Loader from '../../components/loader/Loader';
import { diniICO, about2, pc, expo } from '../../assets/images';
import PrintRoadmap from '../../components/printRoadMap/PrintRoadmap';
// import {  } from '../../assets/images';
const About = () => {
  const screenWidth = window.innerWidth;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    const totalImages = images.length;

    let loadedImages = 0;

    const imageLoaded = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        setTimeout(() => {
          setIsLoading(false);
        }, 50);
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        imageLoaded();
      } else {
        image.addEventListener('load', imageLoaded);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', imageLoaded);
      });
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='about-container animate-toastFade px-4 flex justify-center mt-10   bg-theme'>
            <div className='w-[95%] md:w-[1240px] mb-20 flex flex-col justify-start items-center '>
              <h1
                className='text-center text-4xl md:text-7xl font-extrabold
              text-black  mb-4'
              >
                Tokenize Creativity,
              </h1>
              <h1
                className='text-center text-4xl md:text-7xl font-extrabold
              text-black mb-10 md:mb-10'
              >
                Manufacture the Future.
              </h1>
              {/* <h6 className='mb-12 text-center text-base md:text-2xl text-black'>
              A Decentralized Manufacturing Revolution
              </h6> */}

              <div className='justify-center text-4xl md:text-6xl text-black font-bold mt-2 md:mt-10'>
                Platform
              </div>
              {aboutDetails.map((cardData, i) => (
                <div
                  key={i}
                  className={
                    'flex flex-col md:flex-row justify-center items-center py-4 md:py-4 gap-4 md:max-w-[850px]' +
                    (cardData.className !== 'ssapData' ? ' ' : '')
                  }
                >
                  {screenWidth > 780 ? (
                    cardData.imageAlignLeft ? (
                      <LeftImageCard cardData={cardData} />
                    ) : (
                      <RightImageCard cardData={cardData} />
                    )
                  ) : (
                    <MobileCard cardData={cardData} />
                  )}
                  {/* {i === 1 && (
                    <div className='flex justify-start'>
                      <p className='text-2xl font-bold border'>Key Features</p>
                    </div>
                  )} */}
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className='flex justify-center items-center gap-4 pb-3'>
              <img
                src={diniICO}
                alt='3diniIco'
                className='drop-shadow-2xl  card-img md:w-52 w-24 my-4 '
              />
              <p className='md:mt-5 mt-2 font-bold ml-1 text-4xl md:text-6xl text-black'>
                Network
              </p>
            </div>
          </div>
          <div className='about-container animate-toastFade px-4 flex justify-center bg-theme'>
            <div className='w-[95%] md:w-[1240px] flex flex-col justify-start items-center'>
              {/* First Section */}
              <div className='flex flex-col md:flex-row justify-center items-center py-4 md:py-10 gap-4 md:max-w-[850px]'>
                <div className='content flex-1'>
                  {/*
                  <h1 className='mb-4 text-2xl font-bold text-black'>
                    3dini Network
                  </h1>
                   */}
                  <p className='md:mr-4 md:text-lg text-base leading-snug text-black'>
                    The 3dini Network is leveraging blockchain technology to
                    drive decentralization and efficiency in manufacturing. The
                    network empowers individuals and organizations. It provides
                    contributors to the 3dini manufacturing ecosystem a means to
                    earn rewards in the form of DINI coins, the network’s native
                    cryptocurrency.
                  </p>
                </div>
                <div className='img flex-1 flex justify-center w-3/5'>
                  <img
                    src={about2}
                    alt='Img'
                    className='opacity-90 md:w-4/5 3/5 rounded-lg'
                  />
                </div>
              </div>

              {/* Second Section */}
              <div className='flex flex-col md:flex-row items-start py-4 md:py-10 gap-4 md:max-w-[850px]'>
                <div className='flex flex-col flex-1 items-center'>
                  {/*
                  <div className='w-full'>
                    <p className='font-bold text-2xl mb-4 flex justify-start'>Key Features</p>
                  </div>
                  */}
                  <div className='w-full'>
                    <p className='font-bold text-2xl mb-2 flex justify-start'>
                      Dual Consensus Mechanisms
                    </p>
                  </div>
                  <ul className='list-disc list-inside pl-5'>
                    <li className='mb-2 text-base md:text-lg'>
                      <span className='font-bold '>Proof of Work (PoW)</span>{' '}
                      mechanism, like PoW mechanisms on other networks, rewards
                      participants who provide computational power to the
                      network, ensuring the security and reliability of the
                      blockchain.
                    </li>
                    <li className=' text-base md:text-lg'>
                      <span className='font-bold'>Proof of Build (PoB)</span>{' '}
                      mechanism that rewards members for their manufacturing
                      efforts, incentivizing real-world production and
                      innovation. This mechanism is unique to the 3dini Network.
                    </li>
                  </ul>
                  <div className='flex flex-1 justify-center mt-4 items-center md:w-1/2 w-4/5'>
                    <img
                      src={expo}
                      alt='3dini ICO'
                      className='drop-shadow-2xl card-img md:block my-4 w-[70%]'
                    />
                  </div>
                  <div className='mt-0'>
                    <p className='font-bold text-2xl mt-10'>Dini Coin</p>
                    <p className='text-base mt-2 md:text-lg'>
                      Contributors to the 3dini Network earn DINI coins for
                      their computational and manufacturing contributions,
                      creating a robust and dynamic ecosystem where value is
                      continually generated and shared.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='flex justify-center md:text-6xl text-4xl font-bold mt-16 md:mt-32'>
            Print Token
          </div>
          <div className='about-container animate-toastFade px-4 flex items-center justify-center md:py-0 bg-theme'>
            <div className='md:hidden flex flex-col items-center'>
              <div className=''>
                {PrintTokens.map((cardData, i) => (
                  <div
                    key={i}
                    className={`flex flex-col md:flex-row justify-center items-center py-4 md:py-10 gap-4 md:max-w-[850px] ${
                      cardData.className !== 'ssapData' ? '' : ''
                    }`}
                  >
                    {screenWidth > 780 ? (
                      cardData.imageAlignLeft ? (
                        <LeftImageCard cardData={cardData} />
                      ) : (
                        <RightImageCard cardData={cardData} />
                      )
                    ) : (
                      <MobileCard cardData={cardData} />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className='md:block hidden '>
              <div className='w-[50%] md:w-full flex flex-col justify-center items-center'>
                <div className=''>
                  {PrintTokens.map((cardData, i) => (
                    <div
                      key={i}
                      className={`flex flex-col md:flex-row justify-center items-center py-4 md:py-4 gap-1 md:max-w-[850px] ${
                        cardData.className !== 'ssapData' ? '' : ''
                      }`}
                    >
                      {screenWidth > 780 ? (
                        cardData.imageAlignLeft ? (
                          <LeftImageCard cardData={cardData} />
                        ) : (
                          <RightImageCard cardData={cardData} />
                        )
                      ) : (
                        <MobileCard cardData={cardData} />
                      )}
                    </div>
                  ))}
                </div>
                <div className=' md:w-[100px] md:pt-8'>
                  <PrintRoadmap />
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center justify-center mt-20 md:mt-36'>
            <div className='text-4xl md:text-6xl font-bold'>Print Center</div>
            <div className='text-xl md:text-2xl font-bold'>
              Your Local Hub on the 3dini Network
            </div>
          </div>
          <div className='about-container animate-toastFade px-4 flex justify-center  md:pb-20 pb-44 bg-theme'>
            <div className='w-[95%] md:w-[1240px] mb-24 flex flex-col justify-start items-center'>
              {printTokenCenter.map((cardData, i) => (
                <div
                  key={i}
                  className={`flex flex-col md:flex-row justify-center items-center py-4  gap-4 md:max-w-[850px] ${
                    cardData.className !== 'ssapData' ? '' : ''
                  }`}
                >
                  {screenWidth > 780 ? (
                    cardData.imageAlignLeft ? (
                      <LeftImageCard cardData={cardData} />
                    ) : (
                      <RightImageCard cardData={cardData} />
                    )
                  ) : (
                    <MobileCard cardData={cardData} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default About;

import React from 'react';

const MobileCard = ({ cardData }) => {
  console.log(cardData, 'mobil');
  return (
    <>
    { cardData.mobileImg !== '' && <img
        className='drop-shadow-2xl card-img w-1/2 h-1/2 my-2'
        src={cardData.mobileImg}
        alt='about-logo'
        width='200px'
      />}
      <div className='left-text relative z-1 content-center'>
        {cardData.title && (
          <h2 className='mb-2 text-2xl font-bold text-black'>
            {cardData.title}
          </h2>
        )}
        <p className='md:mr-4 md:text-xl text-base text-black'>
          {cardData.description}
        </p>
      </div>
    </>
  );
};

export default MobileCard;

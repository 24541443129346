import React, { useEffect, useRef, useState } from 'react';
import { temple, temple2, temple3 } from '../../assets/images';
import config from '../../config.json';
import LoadingBar from 'react-top-loading-bar';
import Loader from '../../components/loader/Loader';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../components/ui/select';

const usdSizeOptions = [
  {
    label: 'Mini (3.75”)',
    value: config.usd_mini_btn_id
  },
  {
    label: 'Small (5”)',
    value: config.usd_small_btn_id
  },
  {
    label: 'Medium (5.5”)',
    value: config.usd_medium_btn_id
  },
  {
    label: 'Large (6”)',
    value: config.usd_large_btn_id
  }
];

const MonumentsProject = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [sizeOptions, setSizeOptions] = useState(usdSizeOptions);
  const [selectedSize, setSelectedSize] = useState(usdSizeOptions[0]);
  const [buyButtonVisible, setBuyButtonVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    const totalImages = images.length;

    let loadedImages = 0;

    const imageLoaded = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        imageLoaded();
      } else {
        image.addEventListener('load', imageLoaded);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', imageLoaded);
      });
    };
  }, []);

  useEffect(() => {
    ref.current.continuousStart();
    let isMounted = true;
    fetch(process.env.REACT_APP_IPINFO_CREDS)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((jsonResponse) => {
        if (isMounted) {
          if (jsonResponse.country === 'IN') {
            setSizeOptions([
              {
                label: 'Mini (3.75”)',
                value: config.inr_mini_btn_id
              },
              {
                label: 'Small (5”)',
                value: config.inr_small_btn_id
              },
              {
                label: 'Medium (5.5”)',
                value: config.inr_medium_btn_id
              },
              {
                label: 'Large (6”)',
                value: config.inr_large_btn_id
              }
            ]);

            setSelectedSize({
              label: 'Mini (3.75”)',
              value: config.inr_mini_btn_id
            });
          }

          setBuyButtonVisible(true);
          ref?.current?.complete();
        }
      })
      .catch((error) => {
        console.error('Error fetching user region:', error);
        setBuyButtonVisible(true);
        ref?.current?.complete();
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const textStyle = 'text-base md:text-lg text-[#e17146] text-center';

  const learnMore =
    'w-48 h-11 rounded-[38px] my-6 md:my-0 border-solid border-2 border-[#e17146] text-[#e17146] font-bold flex items-center justify-center cursor-pointer text-lg transition-all hover:bg-white/10';

  const style = {
    text: 'text-[#e17146] leading-tight '
  };

  const handleChange = (size) => {
    setSelectedSize(size);
  };

  return (
    <>
      <LoadingBar
        color='#FF5733'
        height={3}
        containerStyle={{ zIndex: 101 }}
        transitionTime={300}
        ref={ref}
      />
      <div className='w-full bg-black flex justify-center'>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='animate-toastFade w-[90%] md:w-[1240px] flex flex-col justify-start items-center mb-48 mt-16 md:mt-20'>
            <h1 className=' text-4xl md:text-7xl text-[#e17146] font-extrabold text-center'>
              Temples and Monuments
            </h1>
            <div className='w-full flex flex-col md:flex-row gap-10 md:gap-32 items-center justify-center md:my-28 my-16'>
              <div className='flex flex-col items-center'>
                <div className='flex flex-row justify-center gap-24 items-center mr-4'>
                  <div className='flex flex-col justify-center gap-2 mr-0 pl-2'>
                    <p
                      className={
                        style.text +
                        ' text-right font-medium text-md mb-[-0.5rem]'
                      }
                    >
                      ayodhyā
                    </p>
                    <p
                      className={
                        style.text +
                        ' text-right font-medium text-2xl mb-[0.5rem]'
                      }
                    >
                      rāma maṃdira
                    </p>
                  </div>
                  <div className='flex flex-col justify-center gap-2'>
                    <p
                      className={
                        style.text + ' text-left text-lg mb-[-1rem] font-medium'
                      }
                    >
                      अयोध्या
                    </p>
                    <p
                      className={
                        style.text + ' text-left text-5xl font-bold font-teko'
                      }
                    >
                      राम मंदिर
                    </p>
                  </div>
                </div>
                <div className='mt-[-6rem] items-center flex flex-col items-center mr-4'>
                  <img
                    src={temple}
                    alt='TEMPLE'
                    className='h-auto w-3/4 md:w-96 rounded-lg border-solid border-0 border-theme-text/50 opacity-[0.7] drop-shadow-3xl'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-4 h-full items-center justify-center'>
                <div className='flex gap-2 items-center w-full'>
                  <p className='text-[#e17146] font-medium text-base'>Size: </p>
                  <Select
                    className='flex-1'
                    onValueChange={(size) => handleChange(size)}
                    value={selectedSize}
                    defaultValue={sizeOptions[0]}
                  >
                    <SelectTrigger className='w-full bg-white/20 h-7 ps-3 pe-3 py-1 text-sm text-white'>
                      <SelectValue placeholder='Select size...'>
                        <p className='font-semibold'>{selectedSize.label}</p>
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent className='bg-black border border-solid border-white/20 text-white py-1'>
                      {sizeOptions.map((size, index) => (
                        <SelectItem className='text-white' key={index} value={size}>
                          {size.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className='min-h-[366px] min-w-[288px]'>
                  {buyButtonVisible && (
                    <stripe-buy-button
                      key={selectedSize.value}
                      buy-button-id={selectedSize.value}
                      publishable-key={config.stripe_published_key}
                    />
                  )}
                </div>
              </div>
            </div>
            <p className={textStyle + ' mb-4 md:w-[58%]'}>
              We commemorate the inauguration of the Ayodhya Ram Mandir with
              this highly detailed full-color 3D printed model. It is an
              authentic reflection of the temple architecture, validated by
              VedicFutura. These limited-edition collectibles showcase the first
              of 3dHoudini’s Temple and Monuments Collection.
            </p>
            <div className='flex my-4 md:mt-20 md:mb-16 w-full md:w-2/3 justify-between'>
              <img
                src={temple2}
                alt='temple'
                className='w-[48%] border border-4 border-solid border-slate-200/10 rounded-[18px]'
              />
              <img
                src={temple3}
                alt='temple'
                className='w-[48%] border border-4 border-solid border-slate-200/10 rounded-[18px]'
              />
            </div>
            <span className={learnMore}>
              <a
                href='/projects/monuments/learnmore'
                className='w-full h-full flex items-center justify-center'
              >
                Learn More
              </a>
            </span>
            <p
              className={
                'text-xs text-slate-300/50 text-center md:w-[60%] mt-6 md:mt-12'
              }
            >
              Because of the amount of time required to produce these large
              full-color prints, our production capabilities are limited.
            </p>
            <p
              className={
                'text-xs text-slate-300/50 md:w-[60%] text-center mt-3'
              }
            >
              We also make the Ayodhya Ram Mandir in a larger size (12” x 7.5” x
              5”). If interested, please inquire about pricing.
            </p>
            <p
              className={
                'text-xs text-slate-300/50 md:w-[60%] text-center mt-6'
              }
            >
              This model is created under a license obtained from{' '}
              <a
                href='https://www.vedicfutura.com/'
                className='hover:text-slate-300/80 transition-all'
                rel='noreferrer'
                target='_blank'
              >
                VedicFutura.
              </a>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default MonumentsProject;

export const ShippingInformation = [
  {
    id: 'news-1', // Unique identifier for the first news item
    comments: 5,
    headline: '',
    description: [
      {
        type: 'sub_head',
        value: 'Receiving Your Item from 3dHoudini'
      },
      {
        type: 'text',
        value:
          '3dHoudini prides itself on delivering unmatched customer service.'
      },
      {
        type: 'text',
        value:
          'Once payment has been verified, your item will be shipped as soon as it is manufactured. To protect your purchase, a signature is required for the package to be delivered.'
      },
      {
        type: 'text',
        value: 'Notes:'
      },
      {
        type: 'points',
        values: [
          '3dHoudini ships globally company using FedEx, UPS, and USPS.',
          'We cannot deliver to P.O. Boxes. USPS is used for APO/FPO addresses.',
          'There are select countries that we are unable to ship to. We reserve the right to refuse shipment to any location and we will refund your order in such a situation.'
        ]
      },

      {
        type: 'text',
        value:
          'If you ever have any further questions, please contact us at Hello@3dhoudini.com.'
      },
      {
        type: 'sub_head',
        value: 'Shipping To 3dHoudini'
      },
      {
        type: 'text',
        value:
          'Free shipping is provided for authorized Returns and Replacements. We will email you a pre-paid shipping label. Please follow the shipping instructions carefully.'
      },
      {
        type: 'text',
        value: 'How to Ship:'
      },
      {
        type: 'points',
        values: [
          'Print out your shipping label.',
          'Pack your item along with all accessories (stickers, documents, etc.), in the same manner in which it was originally shipped to you.',
          'Tape the shipping label to the package.',
          'Schedule a pick-up or drop your package off at an authorized shipping facility.'
        ]
      },
      {
        type: 'sub_head',
        value: 'INTERNATIONAL SHIPPING'
      },
      {
        type: 'text',
        value:
          'International customers may be required to pay customs charges, as well as any associated import tax, VAT, or duties as required by your local jurisdiction. You may be responsible for paying additional duties and taxes to the carrier. Contact us with any questions about charges in your country.'
      }
    ]
  }
];

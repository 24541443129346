import React from 'react';
import { Button, Input } from '../../../components/ui';

const AdminHeader = ({
                       searchQuery,
                       totalOrders,
                       handleSearchChange,
                       handleExport
                     }) => {
  const styles = {
    input_div: 'hidden md:flex items-center justify-start gap-5 ps-8 py-6'
  };

  return (
    <div className={styles.input_div}>
      <Input
        type='text'
        placeholder='Search'
        value={searchQuery}
        onChange={handleSearchChange}
        className='h-7 text-sm sm:px-3'
      />
      <p className='text-tx-secondary text-sm'>Total: {totalOrders}</p>
      <Button
        onClick={handleExport}
        className='h-7'
        variant='secondary'
      >
        Export
      </Button>
    </div>
  );
};

export default AdminHeader;

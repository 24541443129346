import * as React from "react"
import {CaretSortIcon, CheckIcon} from "@radix-ui/react-icons"

import {Button} from "./button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"
import {countries} from "../../constants/countryOptions";

const frameworks = countries;

export function ComboboxDemo({ value, setValue }) {
  const [open, setOpen] = React.useState(false)

  return (
    <Popover open={open} onOpenChange={setOpen} className='w-full'>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full rounded-lg bg-white/20 ps-4 pe-2 h-10 flex justify-between items-center text-sm font-normal text-white/80 outline-0 mb-4"
        >
          {value
            ? frameworks.find((framework) => framework.value.trim() === value.trim())?.label
            : "Select a country (Required)"}
          <CaretSortIcon className="ml-2 h-6 w-6 shrink-0 opacity-50"/>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-[calc(100vw-32px)] sm:w-[620px] pt-9 px-0 pb-0 max-h-72 overflow-auto no-scrollbar rounded-lg transition-all bg-bg-secondary border border-solid border-white/25">
        <Command>
          <CommandInput placeholder="Search" className="h-9"/>
          <CommandEmpty className='text-tx-secondary text-center my-8'>No Country found.</CommandEmpty>
          <CommandGroup>
            {frameworks.map((framework) => (
              <CommandItem
                className='py-2 px-4 mx-1 transition-all hover:bg-white/10 rounded-lg cursor-pointer text-sm font-semibold text-tx-secondary'
                key={framework.value}
                value={framework.value}
                onSelect={(currentValue) => {
                  setValue(currentValue)
                  setOpen(false)
                }}
              >
                {framework.label}
                {/*<CheckIcon*/}
                {/*  className={cn(*/}
                {/*    "ml-auto h-4 w-4",*/}
                {/*    value === framework.value ? "opacity-100" : "opacity-0"*/}
                {/*  )}*/}
                {/*/>*/}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

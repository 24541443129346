import { GrNext, GrPrevious } from 'react-icons/gr';

const Pagination = ({ pageCount, currentPage, onPageChange }) => {
  const styles = {
    pagination: 'flex items-center justify-center gap-2 w-full',
    selected: 'flex items-center mb-0 text-sm font-base text-tx-secondary',
    buttons:
      'w-6 h-6 flex items-center justify-center border border-solid border-tx-secondary transition-all text-tx-secondary bg-transparent text-sm rounded-lg hover:text-btn-primary hover:border-btn-primary disabled:pointer-events-none disabled:opacity-50'
  };

  return (
    <div className={styles.pagination}>
      <button
        className={styles.buttons}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 0}
      >
        <GrPrevious />
      </button>
      <span className={styles.selected}>{currentPage + 1}</span>
      <button
        className={styles.buttons}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={
          (currentPage === 0 && pageCount === 0) ||
          currentPage === pageCount - 1
        }
      >
        <GrNext />
      </button>
    </div>
  );
};

export default Pagination;

import React, { useState } from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
// import SliderCaptcha from '@slider-captcha/react';
import HCaptcha from '@hcaptcha/react-hcaptcha';

window.Buffer = window.Buffer || require('buffer').Buffer;

const ContactForm = ({ formik, onCaptchaComplete, loading }) => {
  const [captchaSolution, setCaptchaSolution] = useState(null);
  const REACT_APP_HCAPTCHA_SITE_KEY = process.env.REACT_APP_HCAPTCHA_SITE_KEY;
  // const SLIDER_CAPTCHA_CREATE_URI = process.env.REACT_APP_SLIDER_CAPTCHA_CREATE_URI
  // const SLIDER_CAPTCHA_VERIFY_URI = process.env.REACT_APP_SLIDER_CAPTCHA_VERIFY_URI
  // const GOOGLE_CAPTCHA_SITE_KEY = process.env.REACT_APP_V3_SITE_KEY;

  const inputFieldClass =
    'inputFields w-full py-3 md:py-4 px-4 md:px-5 rounded-md text-sm md:text-base font-medium outline-0 text-slate-950 bg-white/20 placeholder-gray-600';

  function verifiedCallback(token) {
    setCaptchaSolution(token); // Store the captcha solution for validation
    onCaptchaComplete(); // Notify the parent component that captcha is completed
  }

  return (
    <>
      <form className='contact-form w-full md:w-[700px] flex flex-col items-center justify-center gap-6 md:gap-8'>
        <input
          type='text'
          name='name'
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder='Full Name'
          autoComplete='off'
          className={inputFieldClass + ' h-12'}
        />
        <input
          type='email'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder='Email Address'
          autoComplete='off'
          className={inputFieldClass + ' h-12'}
        />
        <input
          type='text'
          name='companyName'
          value={formik.values.companyName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder='Company Name (Optional)'
          autoComplete='off'
          className={inputFieldClass + ' h-12'}
        />
        <textarea
          name='message'
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder='Request'
          autoComplete='off'
          className={inputFieldClass + ' min-h-[48px] md:min-h-[58px]'}
          rows='10'
        />
        {/* <ReCAPTCHA
          sitekey={GOOGLE_CAPTCHA_SITE_KEY}
          onChange={(value) => formik.setFieldValue('recaptcha', value)}
        /> */}
        {/* <SliderCaptcha
          create={SLIDER_CAPTCHA_CREATE_URI}
          verify={SLIDER_CAPTCHA_VERIFY_URI}
          callback={verifiedCallback}
        /> */}
        <HCaptcha
          sitekey={REACT_APP_HCAPTCHA_SITE_KEY}
          onVerify={verifiedCallback}
        />
        <button
          disabled={
            !formik.isValid || !formik.dirty || !captchaSolution || loading
          }
          className='submit-btn py-1 md:py-2 px-7 md:px-5 rounded-lg border-solid border-2 border-white/60 hover:bg-white/10 text-sm md:text-lg font-bold cursor-pointer text-slate-50 transition ease-in-out  disabled:opacity-70 disabled:pointer-events-none'
          onClick={formik.handleSubmit}
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default ContactForm;

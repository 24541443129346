import React, { useEffect, useState } from 'react';
import { printToken, smallPrintToken } from '../../assets/images';
import PrintRoadmap from '../../components/printRoadMap/PrintRoadmap';
import Loader from '../../components/loader/Loader';

const PrintToken = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    const totalImages = images.length;

    let loadedImages = 0;

    const imageLoaded = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        setTimeout(() => {
          setIsLoading(false);
        }, 50);
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        imageLoaded();
      } else {
        image.addEventListener('load', imageLoaded);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', imageLoaded);
      });
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='print-token-container animate-toastFade flex justify-center bg-theme'>
          <div className='inner-print-token w-[90%] md:w-[1240px] mb-20 md:mb-40'>
            <div className='print-token flex flex-col justify-center items-center gap-5 mt-20 md:mt-32  mb-4 md:mb-20'>
              <img
                src={printToken}
                className='hidden md:block drop-shadow-xl print-token-img md:w-[360px] md:h-[360px]'
                alt='print token'
              />
              <img
                src={smallPrintToken}
                className='md:hidden drop-shadow-xl print-token-img w-64 h-64'
                alt='print token'
              />
              <div className='about-print-token flex flex-col items-center justify-center'>
                <h2 className='text-center mt-14 text-5xl md:text-7xl font-extrabold black'>
                  Print Token
                </h2>
                <p className='mt-6 text-xl md:text-2xl font-normal black'>
                  Create. Sell. Print.
                </p>
              </div>
            </div>
            <p className='md:text-center md:px-44 text-base md:text-xl font-normal black'>
              A Print Token is an NFT that integrated with the 3dHoudini
              3D-printing ecosystem. It's a vehicle to distribute and 3D print
              CAD designs. CAD files are streamed directly to a 3D printer,
              ensuring only one instance of a design is printed.
            </p>
            <PrintRoadmap />
          </div>
        </div>
      )}
    </>
  );
};

export default PrintToken;

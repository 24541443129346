import { Button, Label } from '../../../components/ui';
import { RadioGroup, RadioGroupItem } from '../../../components/ui/radio-group';
import { useState } from 'react';
import { updateOrderStatus } from '../../../services';
import config from '../../../config.json';

const UpdateStatusModal = ({
  selectedToken,
  setSelectedToken,
  authData,
  setIsLoading,
  loaderRef,
  getOrders
}) => {
  const [updateStatus, setUpdateStatus] = useState('');

  const statusValues = ['PRINTING', 'DISPATCHED', 'DELIVERED'];

  const onClose = () => {
    setSelectedToken();
    setUpdateStatus('');
  };

  const handleUpdateStatus = async () => {
    if (!authData) return;

    onClose();
    setIsLoading(true);
    loaderRef.current.continuousStart();
    try {
      const res = await updateOrderStatus(
        authData._id,
        selectedToken._id,
        config.status_codes[updateStatus]
      );
      getOrders();
    } catch (error) {
      console.log('Error:--', error);
      setIsLoading(false);
      loaderRef?.current?.complete();
    }
  };

  return (
    <>
      {selectedToken && (
        <div className='fixed inset-0 flex items-center justify-center z-50 bg-black/[0.5]'>
          <div className='bg-white p-8 max-w-sm w-full flex flex-col items-start rounded-md shadow-md animate-zoomIn border border-solid border-white/20'>
            <RadioGroup
              className='flex-col items-start gap-2 my-4'
              onValueChange={(value) => setUpdateStatus(value)}
              value={updateStatus}
            >
              {statusValues.map((size, i) => (
                <div key={i} className='flex items-center space-x-2'>
                  <RadioGroupItem value={size} id={size} />
                  <Label htmlFor={size}>{size}</Label>
                </div>
              ))}
            </RadioGroup>
            <div className='flex items-center gap-6 mt-6'>
              <Button
                disabled={updateStatus.length === 0}
                onClick={handleUpdateStatus}
              >
                Submit
              </Button>
              <Button
                variant='secondary'
                onClick={onClose}
                className='hover:bg-black/10'
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateStatusModal;

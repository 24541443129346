import { Button, Input } from '../../../components/ui';
import { useUserContext } from '../../../hooks';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { toast } from '../../../components/ui/use-toast';
import config from '../../../config.json';
import { studioToast } from '../../../components/toast';
import { MdDeleteOutline } from 'react-icons/md';

const Beasts = ({ setIsValidLink, isValidLink }) => {
  const styles = {
    section:
      'w-full flex flex-col justify-start items-start border-b border-solid border-slate-600/[0.15] py-6',
    sectionTitle:
      'text-2xl font-light pb-3 text-black/80 flex w-full justify-between',
    sectionText:
      'font-normal text-base pb-3 text-tx-secondary flex flex-col items-start gap-2',
    kidCalledBeast:
      'w-[48%] flex items-center justify-start pb-1 text-white font-semibold text-xl',
    fileInput:
      'flex items-center text-sm mr-4 h-10 px-6 rounded-lg cursor-pointer font-bold border-none bg-black hover:bg-black/80 text-white',
    filePreview:
      'flex-1 flex items-center sm:gap-8 gap-4 text-sm text-white/60 font-semibold',
    submitLink:
      'flex items-center rounded-lg bg-white/40 h-14 text-white font-light text-lg px-4 mr-4'
  };

  return (
    <div>
      <ModelFileSection
        styles={styles}
        isValidLink={isValidLink}
        setIsValidLink={setIsValidLink}
      />
    </div>
  );
};

export default Beasts;

const ModelFileSection = ({ styles, setIsValidLink, isValidLink }) => {
  const { setFileData, fileData, fileLink, setFileLink } = useUserContext();

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    const maxSize = config.maxFileSize * 1024 * 1024; // 99MB in bytes

    if (file && file.size > maxSize) {
      // toast({
      //   description: 'File size too large.'
      // });
      studioToast('File size too large.');
      e.target.value = null; // Clear the file input
    } else {
      const newFiles = [...fileData];
      newFiles[index] = file;
      setFileData(newFiles);
    }
  };

  const handleLinkChange = (e, index) => {
    const newTexts = [...fileLink];
    newTexts[index] = e.target.value;

    const disableInput = newTexts.some((link) => {
      return (
        link.length &&
        !link.trim().toLowerCase().endsWith('.zip') &&
        !link.trim().toLowerCase().endsWith('.jpeg') &&
        !link.trim().toLowerCase().endsWith('.jpg') &&
        !link.trim().toLowerCase().endsWith('.png') &&
        !link.trim().toLowerCase().endsWith('.ply') &&
        !link.trim().toLowerCase().endsWith('.obj') &&
        !link.trim().toLowerCase().endsWith('.glb') &&
        !link.trim().toLowerCase().endsWith('.gltf') &&
        !link.trim().toLowerCase().endsWith('.stl') &&
        !link.trim().toLowerCase().endsWith('.blend') &&
        !link.trim().toLowerCase().endsWith('.mtl')
      );
    });

    // console.log('disableInput-', disableInput);
    setIsValidLink(!disableInput);

    setFileLink(newTexts);
  };

  const handleAddMore = () => {
    if (fileData.length < 10) {
      setFileData([...fileData, null]);
    }
  };

  const handleAddMoreInputs = () => {
    if (fileLink.length < 10) {
      setFileLink([...fileLink, '']);
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...fileData];
    newFiles[index] = null;
    setFileData(newFiles);
    document.getElementById(`file-input-${index}`).value = null;
  };

  const handleRemove = (index) => {
    const newFiles = [...fileData];
    newFiles.splice(index, 1);
    setFileData(newFiles);
  };

  const handleRemoveInput = (index) => {
    const newTexts = [...fileLink];
    newTexts.splice(index, 1);
    setFileLink(newTexts);
  };

  const isTypeFileDisabled = fileLink.find((item) => item.length > 0);
  const isTypeTextDisabled = fileData.find((item) => item !== null);
  console.log('isValidLink-', isValidLink);
  console.log('isTypeFileDisabled-', isTypeFileDisabled);
  return (
    <div className={styles.section}>
      {/*<h2 className={styles.sectionTitle}>3D Model file</h2>*/}
      {(!isTypeFileDisabled || !isValidLink) && (
        <>
          <p className={styles.sectionText}>
            Supported file formats*:
            <div className='flex flex-wrap gap-2'>
              <Button variant='tag'>.zip</Button>
              <Button variant='tag'>.jpeg</Button>
              <Button variant='tag'>.jpg</Button>
              <Button variant='tag'>.png</Button>
              <Button variant='tag'>.ply</Button>
              <Button variant='tag'>.obj</Button>
              <Button variant='tag'>.glb</Button>
              <Button variant='tag'>.gltf</Button>
              <Button variant='tag'>.stl</Button>
              <Button variant='tag'>.blend</Button>
              <Button variant='tag'>.mtl</Button>
            </div>
            <span className='text-xs'>(max size: 99mb)*</span>
          </p>

          {fileData.map((inputFile, index) => (
            <div
              className={`mb-3 flex flex-row items-center w-full justify-between ${
                isTypeFileDisabled && 'opacity-70 pointer-events-none'
              }`}
            >
              <div>
                <label
                  htmlFor={`file-input-${index}`}
                  className={`${styles.fileInput}`}
                >
                  {inputFile ? 'Replace File' : 'Choose File'}
                </label>
                <input
                  id={`file-input-${index}`}
                  type='file'
                  accept='.zip,.jpeg,.jpg,.png,.ply,.obj,.glb,.gltf,.stl,.blend,.mtl'
                  onChange={(e) => handleFileChange(e, index)}
                  className='hidden'
                />
              </div>
              {inputFile && (
                <div className={styles.filePreview}>
                  {/*<span className='hidden sm:block'>{inputFile.name}</span>*/}
                  <span>
                    {inputFile.name.length > 16
                      ? `${inputFile.name.slice(0, 16)}...`
                      : inputFile.name}
                  </span>
                  <button onClick={() => handleRemoveFile(index)}>
                    <FaTimes />
                  </button>
                </div>
              )}
              {fileData.length > 1 && index !== 0 && (
                <button onClick={() => handleRemove(index)}>
                  <MdDeleteOutline size={24} />
                </button>
              )}
            </div>
          ))}

          {fileData.length < 10 && (
            <Button
              variant='secondary'
              disabled={isTypeFileDisabled}
              className='gap-2 h-7'
              onClick={handleAddMore}
            >
              <FaPlus /> Add More{' '}
            </Button>
          )}
        </>
      )}

      {!isTypeTextDisabled && (!isTypeFileDisabled || !isValidLink) && (
        <p className={styles.sectionText + ' py-1'}>or</p>
      )}

      {!isTypeTextDisabled && (
        <>
          {fileLink.map((inputLink, index) => (
            <div
              className={`w-full flex items-center justify-between mb-3 ${
                isTypeTextDisabled && 'opacity-70 pointer-events-none'
              }`}
            >
              <Input
                type='text'
                value={inputLink}
                onChange={(e) => handleLinkChange(e, index)}
                placeholder='Paste link here'
                className={`sm:w-1/2 w-2/3 text-md font-medium `}
              />
              {fileLink.length > 1 && index !== 0 && (
                <button onClick={() => handleRemoveInput(index)}>
                  <MdDeleteOutline size={24} />
                </button>
              )}
            </div>
          ))}
          {fileLink.length < 10 && (
            <Button
              disabled={isTypeTextDisabled}
              variant='secondary'
              className='gap-2 h-7'
              onClick={handleAddMoreInputs}
            >
              <FaPlus /> Add More{' '}
            </Button>
          )}
          <p className={styles.sectionText + ' pt-3'}>
            The url link must end with:
            <div className='flex flex-wrap gap-2'>
              <Button variant='tag'>.zip</Button>
              <Button variant='tag'>.jpeg</Button>
              <Button variant='tag'>.jpg</Button>
              <Button variant='tag'>.png</Button>
              <Button variant='tag'>.ply</Button>
              <Button variant='tag'>.obj</Button>
              <Button variant='tag'>.glb</Button>
              <Button variant='tag'>.gltf</Button>
              <Button variant='tag'>.stl</Button>
              <Button variant='tag'>.blend</Button>
              <Button variant='tag'>.mtl</Button>
            </div>
          </p>
        </>
      )}
    </div>
  );
};

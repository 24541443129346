import React, { useEffect, useRef, useState } from 'react';
import LocalLoader from '../../../components/LocalLoader';
import { formatDate } from '../../../helpers';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Button } from '../../../components/ui';
import { filterIcon } from '../../../assets/images';

const Table = ({
  currentItems,
  showLoader,
  setSelectedToken,
  setSelectedProofOrder,
  handleOrderStatusChange,
  isOrderStatusShow,
  setIsOrderStatusShow,
  setSelectedActivityOrder,
  timeSortOrder,
  setTimeSortOrder
}) => {
  const orderIdFilterRef = useRef(null);
  const [showButtons, setShowButtons] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const orderStatusOptions = [
    'PROOF PENDING',
    'PROOF UNAPPROVED',
    'PROOF APPROVED',
    'PAYMENT PENDING',
    'PAYMENT COMPLETE',
    'PRINTING',
    'DISPATCHED',
    'DELIVERED'
  ];

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        orderIdFilterRef.current &&
        !orderIdFilterRef.current.contains(event.target)
      ) {
        setIsOrderStatusShow(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleArrowClick = (id) => {
    if (!showLoader) {
      setShowButtons((prevSelectedRows) => {
        if (prevSelectedRows.includes(id)) {
          return prevSelectedRows.filter((orderId) => orderId !== id);
        } else {
          return [...prevSelectedRows, id];
        }
      });
    }
  };

  const handleOptionChange = (e) => {
    const { value, checked } = e.target;
    setSelectedOptions((prevSelectedOrderStatus) => {
      if (checked) {
        return [...prevSelectedOrderStatus, value];
      } else {
        return prevSelectedOrderStatus.filter(
          (orderStatus) => orderStatus !== value
        );
      }
    });
  };

  const styles = {
    thead: 'relative border-b border-solid border-black/25',
    th: 'h-6 py-2 px-3 text-tx-secondary font-medium text-xs first:rounded-tl-xl last:rounded-tr-xl',
    td: 'p-2 text-sm text-tx-secondary',
    double_row: 'flex flex-col items-start gap-1.5',
    tbody: 'relative rounded-xl z-[3]',
    bodyRow:
      'text-end py-1 border-b border-solid border-black/10 last:border-b-0',
    filterIcon_span:
      'h-5 w-5 bg-transparent border border-solid border-black/80 rounded flex items-center justify-center cursor-pointer relative',
    filterOptions:
      'absolute px-4 py-2 rounded-md transition-all top-5 z-[19] bg-white',
    radioLabel:
      'flex justify-start items-center gap-3 text-xs text-tx-secondary cursor-pointer',
    status_col: 'flex justify-between items-center pl-4 gap-3',
    sortIcon: 'cursor-pointer transition-all hover:text-black',
    updateButton:
      'text-white/80 text-xs font-bold border border-solid rounded-md border-white/20 bg-btn-primary transition-all py-1 px-2 hover:bg-btn-primary/80 disabled:pointer-events-none disabled:opacity-70'
  };

  const TableHeader = () => {
    return (
      <thead className={styles.thead}>
        <tr>
          <th className={styles.th + ' w-[3%]'}></th>
          <th className={styles.th + ' text-center w-[10%]'}>
            <div className='flex items-center justify-center gap-2'>
              CREATED AT
              <div className={`flex flex-col items-center justify-center`}>
                <IoIosArrowUp
                  className={`${styles.sortIcon} mb-[-2px] ${
                    timeSortOrder === 1 ? 'text-black' : 'text-black/50'
                  }`}
                  onClick={() => setTimeSortOrder(1)}
                  size={14}
                />
                <IoIosArrowDown
                  className={`${styles.sortIcon} mt-[-2px] ${
                    timeSortOrder === 0 ? 'text-black' : 'text-black/50'
                  }`}
                  onClick={() => setTimeSortOrder(0)}
                  size={14}
                />
              </div>
            </div>
          </th>
          <th className={styles.th + ' text-center w-[8%]'}>ID</th>
          <th className={styles.th + ' text-start w-[18%]'}>CUSTOMER</th>
          <th className={styles.th + ' text-start w-[25%]'}>ADDRESS</th>
          <th className={styles.th + ' text-center w-[12%]'}>
            <div className='flex justify-center items-center gap-2'>
              ORDER STATUS
              {orderStatusOptions.length > 1 && (
                <div className='relative' ref={orderIdFilterRef}>
                  <span
                    className={styles.filterIcon_span}
                    onClick={() => setIsOrderStatusShow(!isOrderStatusShow)}
                  >
                    <img src={filterIcon} className='h-2/3 w-2/3' />
                  </span>
                  {isOrderStatusShow && orderStatusOptions.length > 1 ? (
                    <div className={styles.filterOptions + '  min-w-48 left-0'}>
                      <form className='flex flex-col my-2 gap-2'>
                        {orderStatusOptions.map((orderStatus, i) => (
                          <label className={styles.radioLabel} key={i}>
                            <input
                              className='radioInput'
                              type='checkbox'
                              name='method'
                              value={orderStatus}
                              checked={selectedOptions.includes(orderStatus)}
                              onChange={handleOptionChange}
                            />
                            {orderStatus}
                          </label>
                        ))}
                      </form>
                      <div className='flex gap-4 mb-3 mt-5'>
                        <Button
                          className='h-7'
                          onClick={() =>
                            handleOrderStatusChange(selectedOptions)
                          }
                        >
                          Apply
                        </Button>
                        <Button
                          className='h-7'
                          onClick={() => setSelectedOptions([])}
                          variant='secondary'
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={styles.th + ' text-center w-[6%]'}>PRINTING</th>
          <th className={styles.th + ' text-center w-[6%]'}>SHIPPING</th>
          <th className={styles.th + ' text-center w-[12%]'}>PAYMENT METHOD</th>
        </tr>
      </thead>
    );
  };

  const TableDataRow = ({ item }) => {
    return (
      <tr
        className={
          showButtons.includes(item?._id)
            ? 'border-0'
            : 'border-b border-solid border-black/10 last:border-0'
        }
      >
        <td className={styles.td + ' text-center'}>
          <MdKeyboardArrowRight
            className={`cursor-pointer transition-all ${
              showButtons.includes(item?._id) && 'rotate-90'
            }`}
            onClick={() => handleArrowClick(item._id)}
            size={28}
          />
        </td>
        <td className={styles.td + ' text-center'}>
          {showLoader ? <LocalLoader /> : formatDate(item.created_at)}
        </td>
        <td className={styles.td + ' text-center'}>
          {showLoader ? <LocalLoader /> : item.id}
        </td>
        <td className={styles.td}>
          <div className='flex flex-col items-start gap-1'>
            {showLoader ? <LocalLoader /> : item.shipping.name}
            <span> {showLoader ? <LocalLoader /> : item.shipping.email}</span>
          </div>
        </td>
        <td className={styles.td}>
          {showLoader ? (
            <LocalLoader />
          ) : (
            <div className=''>
              {item.shipping.address.street1},{' '}
              {item.shipping.address.street2
                ? `${item.shipping.address.street2},`
                : ''}
              {item.shipping.address.city},{' '}
              {item.shipping.address.state
                ? `${item.shipping.address.state},`
                : ''}
              {item.shipping.address.country}-{item.shipping.address.zip}.
            </div>
          )}
        </td>
        <td className={styles.td + ' text-center font-semibold text-xs'}>
          {showLoader ? <LocalLoader /> : item.payment_status}
        </td>
        <td className={styles.td + ' text-center font-semibold text-xs'}>
          {showLoader ? (
            <LocalLoader />
          ) : item.cost !== null && item.cost?.print !== 0 ? (
            `$${item.cost.print.toFixed(2)}`
          ) : (
            '--'
          )}
        </td>
        <td className={styles.td + ' text-center font-semibold text-xs'}>
          {showLoader ? (
            <LocalLoader />
          ) : item.cost !== null && item.cost?.ship !== 0 ? (
            `$${item.cost.ship.toFixed(2)}`
          ) : (
            '--'
          )}
        </td>
        <td className={styles.td + ' text-center'}>
          {showLoader ? (
            <LocalLoader />
          ) : item.payment_method === 'COINBASE' ? (
            'CRYPTO'
          ) : item.payment_method === 'STRIPE' ? (
            'CREDIT CARD'
          ) : (
            '--'
          )}
        </td>
      </tr>
    );
  };

  const TableButtonsRow = ({ item }) => {
    return (
      <tr className={styles.bodyRow}>
        <td colSpan='9' className='p-0'>
          <div className=' flex items-center pb-2 justify-start gap-3 ps-4'>
            <button
              className={styles.updateButton}
              onClick={() => setSelectedActivityOrder(item)}
            >
              View Activity
            </button>
            {item.payment_status === 'PROOF PENDING' && (
              <button
                className={styles.updateButton}
                onClick={() => setSelectedProofOrder(item)}
              >
                Upload Proof
              </button>
            )}
            {item.payment_method !== 'PAYMENT NOT STARTED' &&
              item.payment_status !== 'PAYMENT PENDING' && (
                <button
                  className={styles.updateButton}
                  onClick={() => setSelectedToken(item)}
                >
                  Update Status
                </button>
              )}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div
        className={`rounded-xl bg-transparent w-full border border-solid border-black/25 ${
          currentItems.length === 0 && 'border-b-0 rounded-br-none rounded-bl-none'}`}
      >
        <table className='w-full'>
          <TableHeader />
          <tbody className={styles.tbody}>
            {currentItems.map((item, index) => (
              <React.Fragment key={index}>
                <TableDataRow item={item} />

                {!showLoader && showButtons.includes(item._id) && (
                  <TableButtonsRow item={item} />
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;

import React from 'react';

const RightImageCard = ({ cardData }) => {
  console.log('right');
  return (
    <>
      {cardData.image ? (
        <img
          className='drop-shadow-2xl card-img w-1/2 md:w-1/4 my-4 md:min-w-[25%]'
          src={cardData.image}
          alt=''
        />
      ) : (
        ''
      )}
      <div className='right-text relative z-1 content-center md:ml-8'>
        {cardData.title && (
          <>
            <h2 className=' text-4xl font-extrabold text-black'>
              {cardData.title}
            </h2>
            <h4 className='mb-4 text-xl font-extrabold text-black'>
              {cardData.heading}
            </h4>
          </>
        )}
        <p className='md:mr-4 md:text-lg text-base leading-snug text-black'>
          {cardData.description}
        </p>
      </div>
    </>
  );
};

export default RightImageCard;

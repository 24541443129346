import React from 'react';
import './printRoadmap.scss';
import {
  roundedDotBorder,
  roundedDots,
  step1IP,
  step2P,
  step3User,
  step4PC,
  smallWhiteCircle3dLogo
} from '../../assets/images';

const PrintRoadmap = () => {
  const screenWidth = window.innerWidth;

  return (
    <>
      <div className='print-roadMap flex flex-col relative w-[30%] md:w-[40%] gap-12 md:gap-20 items-center'>
        <img src={step1IP} alt='step1IP' className='step-img' />
        <div className='dotted-line'>
          <p className='step-details text-lg md:text-xl font-normal text-black'>
            Create token
          </p>
          <div className='dot'></div>
        </div>
        <img src={step2P} alt='step2P' className='step-img' />
        <div className='dotted-line middle-dotted-line'>
          <p className='step-details middle-step text-lg md:text-lg font-normal text-black'>
            Sell or give away token
          </p>
          <div className='dot middle-dot'></div>
        </div>
        <div className='user-img-parent relative'>
          <img src={step3User} alt='step3User' className='step-img' />
          <img
            src={screenWidth > 768 ? roundedDotBorder : roundedDots}
            alt='roundedDotBorder'
            className='roundedDotBorder'
          />
          <div className='side-line_with-dots'></div>
          <p className='step-details img-side-text text-lg md:text-xl  font-normal text-black'>
            Trade token
          </p>
        </div>
        <div className='dotted-line'>
          <p className='step-details last-step text-lg md:text-xl font-normal text-black'>
            Request print
          </p>
          <p className='step-details last-step-left text-lg md:text-xl font-normal text-black'>
            Print sent
          </p>
          <div className='dot'></div>
        </div>
        <img src={step4PC} alt='step4PC' className='step-img' />
      </div>
    </>
  );
};

export default PrintRoadmap;

import React, { useRef, useState } from 'react';
import { Button, Input } from '../../../components/ui';
import { RiLoader3Line } from 'react-icons/ri';
import { getOrderStatus, signIn, signUp } from '../../../services';
import { toast } from '../../../components/ui/use-toast';
import LoadingBar from 'react-top-loading-bar';
import config from '../../../config.json';
import { useUserContext } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { studioToast } from '../../../components/toast';
import { aboutStudio } from '../../../constants';

const Auth = ({ scrollRef }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOnEmail, setIsOnEmail] = useState(true);
  const [userEmail, setUserEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [userPassword, setUserPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const loaderRef = useRef(null);
  const { urlOrderId, setUrlOrderId } = useUserContext();
  const navigate = useNavigate();

  const handleNext = async () => {
    loaderRef.current.continuousStart();
    setIsLoading(true);
    try {
      const res = await signIn(userEmail);

      if (res.status === 200) {
        localStorage.setItem('authData', JSON.stringify(res.data));
        await redirectCheck(res.data._id);
      } else if (res.response.status === 404) {
        setIsOnEmail(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log('Error:--', error);
      setIsLoading(false);
    }
    loaderRef?.current?.complete();
  };

  const handleConnect = async () => {
    if (userPassword !== confirmPassword) {
      // toast({ description: 'Passwords do not match' });
      studioToast('Passwords do not match');
      return;
    }
    loaderRef.current.continuousStart();
    setIsLoading(true);
    try {
      const res = await signUp(userEmail, userPassword);
      if (res.status === 200) {
        localStorage.setItem('authData', JSON.stringify(res.data));
        navigate('/studio/create-order');
      }
    } catch (error) {
      console.log('Error:--', error);
      setIsLoading(false);
    }
    loaderRef?.current?.complete();
  };

  const redirectCheck = async (id) => {
    try {
      const res = await getOrderStatus(id);
      if (res.status === 200 && res.data !== null) {
        if (
          urlOrderId.length &&
          res.data.find((order) => order._id === urlOrderId)
        ) {
          navigate(`/studio/view-orders/${urlOrderId}`);
        } else {
          navigate('/studio/view-orders');
        }
      } else {
        navigate('/studio/create-order');
      }
      setUrlOrderId('');
    } catch (error) {
      navigate('/studio/create-order');
    }
  };

  const onEmailChange = (e) => {
    const emailValue = e.target.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setUserEmail(emailValue);
    if (!emailRegex.test(emailValue)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  };

  const handleScroll = () => {
    const body = scrollRef.current;
    if (body) {
      body.scrollTo({
        top: body.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const styles = {
    title: 'text-black/80 text-2xl font-bold mb-2',
    signBox:
      'flex flex-col items-center gap-4 border border-solid border-black/20 rounded-lg py-4 px-6 sm:w-3/5'
  };

  return (
    <>
      <LoadingBar
        color={config.loaderBar}
        height={3}
        containerStyle={{ zIndex: 101 }}
        transitionTime={200}
        ref={loaderRef}
      />
      <div className='md:max-w-[850px] max-w-[90%]'>
        <h1
          className='sticky bg-bg-primary z-10 top-0 sm:text-5xl text-2xl
          text-black font-bold sm:py-8 flex items-center justify-between py-4 w-full'
        >
          3D-Print and Design Studio
          <Button onClick={handleScroll}>
            {isOnEmail ? 'Login' : 'Register'}
          </Button>
        </h1>
        {aboutStudio.map((data, i) => (
          <React.Fragment key={i}>
            {data?.title ? (
              <h3 className='text-2xl  font-light pb-2 pt-4 text-black/80'>
                {data.title}
              </h3>
            ) : null}
            {data.description.map((desc, index) => (
              <React.Fragment key={index}>
                {desc.type === 'points' ? (
                  <div className='font-normal text-base pb-3 text-tx-secondary'>
                    {desc.values.map((point, id) => (
                      <p
                        className='news-desc-points font-normal text-base pb-1 text-tx-secondary'
                        key={id}
                      >
                        {point}
                      </p>
                    ))}
                  </div>
                ) : (
                  <p className='font-normal text-base pb-3 text-tx-secondary'>
                    {desc.value}
                  </p>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </div>
      <div
        id='end'
        className='md:max-w-[850px] max-w-[90%] flex flex-col items-center justify-start py-12 md:py-20 gap-8 '
      >
        <h1 className='sm:text-4xl text-xl text-black font-bold w-full'>
          Let's get started!
        </h1>
        <p className='font-normal text-base pb-3 w-full text-tx-secondary'>
          Log in, upload your files, tell us what you're looking to create, and
          we’ll get back to you with the next steps and pricing in 1-3 business
          days.
        </p>
        <div className={styles.signBox}>
          {isOnEmail ? (
            <>
              <p className={styles.title}>Log in</p>
              <Input
                placeholder='Email'
                value={userEmail}
                onChange={onEmailChange}
                type='email'
                className='w-full sm:w-3/4'
              />
              <Button
                disabled={isLoading || !isEmailValid}
                onClick={handleNext}
                className={isLoading && 'disabled:opacity-100'}
              >
                {isLoading ? (
                  <RiLoader3Line className='animate-spin' size={20} />
                ) : (
                  'Continue'
                )}
              </Button>
            </>
          ) : (
            <>
              <p className={styles.title}>Register</p>
              <p className='text-tx-secondary text-sm font-medium'>
                {userEmail}
              </p>
              <Input
                placeholder='Password'
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                type='password'
                className='w-full sm:w-3/4'
              />
              <Input
                placeholder='Confirm password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type='password'
                className='w-full sm:w-3/4'
              />
              <div className='flex items-center justify-center gap-2'>
                <Button
                  disabled={isLoading || !userPassword.length}
                  onClick={handleConnect}
                  className={isLoading && 'disabled:opacity-100'}
                >
                  {isLoading ? (
                    <RiLoader3Line className='animate-spin' size={20} />
                  ) : (
                    'Confirm'
                  )}
                </Button>
                <Button variant='secondary' onClick={() => setIsOnEmail(true)}>
                  Back
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Auth;

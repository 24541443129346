import React, { useEffect, useState } from 'react';
import Leadership from './Leadership';
import Developers from './Developers';
import Board from './Board';
import Loader from '../../components/loader/Loader';
import Partners from './Partners';

const Team = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    const totalImages = images.length;

    let loadedImages = 0;

    const imageLoaded = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        setTimeout(() => {
          setIsLoading(false);
        }, 50);
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        imageLoaded();
      } else {
        image.addEventListener('load', imageLoaded);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', imageLoaded);
      });
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='team-container animate-toastFade'>
          <Leadership />
          <Partners />
          <Board />
        </div>
      )}
    </>
  );
};

export default Team;

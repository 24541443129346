import { emperorMelekTaus, qrCode3dh } from '../assets/images';
import firstPrint from '../assets/videos/first-print.mp4';

export const projects = [
  {
    video: firstPrint,
    imageAlignLeft: true,
    name: 'Print Token',
    mobileDesc:
      'This launch is extra special because it commemorates the debut of 3dHoudini’s Print Token technology. The 3D-printable NFT has been a dream of ours since 2021. It’s wonderful to finally see it out in the world, and we couldn’t have a better partner than WAGMI Games to launch it with!',
    description:
      'This launch is extra special because it commemorates the debut of 3dHoudini’s Print Token technology. The 3D-printable NFT has been a dream of ours since 2021. It’s wonderful to finally see it out in the world, and we couldn’t have a better partner than WAGMI Games to launch it with!'
  },
  {
    image: emperorMelekTaus,
    imageAlignLeft: false,
    name: '3D Print',
    mobileDesc:
      'The quality of the 3D prints produced by the Mimaki 3DUJ-553 is unprecedented! We are very excited to have this mind-blowing 3D-printing magic at our disposal and have gotten nothing but rave reviews from everyone who has seen it.',
    description:
      'The quality of the 3D prints produced by the Mimaki 3DUJ-553 is unprecedented! We are very excited to have this mind-blowing 3D-printing magic at our disposal and have gotten nothing but rave reviews from everyone who has seen it.'
  }
  // {
  //   image: qrCode3dh,
  //   imgClass: 'qrCode',
  //   imageAlignLeft: true,
  //   name: 'Provenance and Authentication',
  //   description:
  //     '3dHoudini Print Tokens are imprinted with a unique QR code. These codes take you to the blockchain ledger or wherever the client decides to send their customers, perhaps to a playable character’s leaderboard. The QR code can also be used to verify possession of the physical collectible. It can be scanned to confirm the physical asset’s provenance.'
  // }
];

import config from '../config.json';
import axios from 'axios';

export const getAllOrders = async (
  address,
  currentPage,
  searchQuery,
  pageSize,
  sortBy,
  selectedOrderStatus,
  sort_type
) => {
  const payload = {
    account: {
      address: address
    },
    status: selectedOrderStatus
  };

  try {
    const { data } = await axios.post(
      config.serverUrl + '/admin/orders/by-order-status',
      payload,
      {
        params: {
          page: currentPage,
          pageSize: pageSize,
          name: searchQuery,
          sort_by:
            sortBy === 'createdAt'
              ? 'created_at'
              : sortBy === 'token'
                ? 'tokenId'
                : sortBy,
          sort_type: sort_type
        }
      }
    );
    return data;
  } catch (error) {
    console.log('error in fetching orders:--', error);
    return [];
  }
};

export const getAllOrdersWithoutExpression = async (address) => {
  const payload = {
    account: {
      address: address
    }
  };

  try {
    const { data } = await axios.post(
      config.serverUrl + '/admin/orders/by-order-status',
      payload,
      {
        params: {}
      }
    );
    return data;
  } catch (error) {
    console.log('error in fetching orders:--', error);
    return [];
  }
};

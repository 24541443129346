import React from 'react';
import './loader.scss';
import { akcbLoader, loader } from '../../assets/images';

const Loader = () => {
  return (
    <>
      <div className='loader-container bg-theme w-screen flex justify-center items-center'>
        <img src={akcbLoader} alt='loader' />
      </div>
    </>
  );
};

export default Loader;

// export const printerBenefits = {
//   part1: [
//     "Prints over 10 million colors with ICC color management",
//     "8 piezo-electric industrial inkjet heads",
//     "Works with CMYK, white, and clear inks",
//     "Build area of 20” x 20” x 12” for effective prototyping"
//   ],
//   part2: [
//     "Dual UV-LED curing of pigmented liquid photopolymer resins",
//     "Prints in 22 micron layers per sweep for extra fine details",
//     "3DLink model layout software",
//     "Water soluble support material for part durability"
//   ]
// }

export const printerBenefits = [
  'Prints over 10 million colors with ICC color management.',
  'Works with CMYK, white, and clear inks.',
  'Largest build area of any full-color 3D printer.',
  'Dual UV-LED curing of pigmented liquid photopolymer resins.',
  'Prints in 22-micron layers per sweep for extra-fine details.'
];

import axios from 'axios';
import config from '../config.json';

export const getOrderStatus = async (address) => {
  try {
    const { data } = await axios.post(config.serverUrl + '/order/get-history', {
      account: { address }
    });
    return data;
  } catch (error) {
    console.log('error in get orders:--', error);
  }
};

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const UserContext = createContext();

export function UserContextProvider({ children }) {
  const [fileData, setFileData] = useState([null]);
  const [fileLink, setFileLink] = useState(['']);
  const [isMobile, setIsMobile] = useState(false);
  const [submittedId, setSubmittedId] = useState();
  const [urlOrderId, setUrlOrderId] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const userAgent = window.navigator.userAgent;
      setIsMobile(/Mobi|Android/i.test(userAgent));
    }
  }, []);

  useEffect(() => {
    setFileData([null]);
    setFileLink(['']);
  }, [pathname]);

  return (
    <UserContext.Provider
      value={{
        isMobile,
        fileData,
        setFileData,
        fileLink,
        setFileLink,
        submittedId,
        setSubmittedId,
        urlOrderId,
        setUrlOrderId
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => useContext(UserContext);

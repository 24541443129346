import React, { useRef, useState } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import './footer.scss';
import {
  telegram,
  youtube,
  discord,
  twitter,
  linkedIn,
  black3dHoudini
} from '../../assets/images';
import { footerNews } from '../../constants';
import config from '../../config.json';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { showToast } from '../toast';
import { Link, useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import Modal from 'react-modal';
import './modal.css';
import LoadingBar from 'react-top-loading-bar';
// import SliderCaptcha from '@slider-captcha/react';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captchaModalOpen, setCaptchaModalOpen] = useState(false);
  const ref = useRef(null);
  const { pathname } = useLocation();

  // const SLIDER_CAPTCHA_CREATE_URI = process.env.REACT_APP_SLIDER_CAPTCHA_CREATE_URI
  // const SLIDER_CAPTCHA_VERIFY_URI = process.env.REACT_APP_SLIDER_CAPTCHA_VERIFY_URI
  const REACT_APP_HCAPTCHA_SITE_KEY = process.env.REACT_APP_HCAPTCHA_SITE_KEY;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const scrollToNewsItem = (index) => {
    scroll.scrollTo(`#news-${index}`, {
      duration: 800,
      smooth: 'easeInOutQuint',
      offset: -50 // Adjust the offset as needed based on your layout
    });
  };

  const handleSignUp = async () => {
    if (!email.length) {
      showToast('warn', 'Please provide an email address.');
      return;
    }
    try {
      ref.current.continuousStart();
      setLoading(true); // Set loading to true when starting the API request
      const { data } = await axios.post(
        config.server_url + 'newsletter-signup',
        {
          email: email
        }
      );
      console.log(data.message);
      showToast('success', data.message);
      setEmail('');
      // setSuccessModalOpen(true);
    } catch (e) {
      console.log('error:--', e);
      if (e.response?.data?.message) {
        showToast('warn', e.response.data.message);
      } else {
        showToast('error', 'You are already signed up with us!');
      }
    } finally {
      setLoading(false); // Set loading to false when the API request is completed (whether it's successful or not)
      ref.current.complete();
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 0,
      smooth: 'easeInOutQuint'
    });
  };

  const handleModalOkayClick = () => {
    setSuccessModalOpen(false);
  };

  function verifiedCallback(token) {
    console.log(token, 'token');
    handleSignUp();
  }

  return (
    <>
      <LoadingBar
        color='#FF5733'
        height={3}
        containerStyle={{ zIndex: 101 }}
        transitionTime={300}
        ref={ref}
      />
      {/*{loading && (*/}
      {/*  <div className='loader-overlay'>*/}
      {/*    <div className='loader'>Wait...</div>*/}
      {/*  </div>*/}
      {/*)}*/}
      <ToastContainer />
      <Modal
        isOpen={successModalOpen}
        onRequestClose={() => setSuccessModalOpen(false)}
        className='modal'
        overlayClassName='overlay'
      >
        <h2>Success!</h2>
        <p>You have successfully signed up for the newsletter!</p>
        <button onClick={handleModalOkayClick}>Okay</button>
      </Modal>

      <div
        className={`bg-[#FEFEFE] md:bg-[#f2f2f4] flex flex-col justify-start items-center pb-16 ${
          pathname.includes('/studio') && 'hidden'
        }`}
      >
        <div
          className='newsletters  w-[90%] lg:w-[1020px] xl:w-[1040px] h-auto px-6 md:px-8 py-4 md:py-8 z-10 rounded-[30px] mt-[-50px]
            md:mt-[-80px] shadow-lg border-2 border-solid border-black/10'
        >
          <h6 className='text-3xl md:text-5xl text-black font-bold tracking-wide pt-2'>
            Newsletter
          </h6>
          <p className='desc text-sm md:text-lg font-bold md:font-normal text-black'>
            Digitize. Decentralize. Democratize.
          </p>

          <div className='browse-more h-12 rounded-lg my-6 p-2 flex justify-between items-center bg-white/20 text-black'>
            <input
              placeholder='Email address'
              type='email'
              name='email'
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              className='mx-2 md:mx-4 outline-0 border-0 text-sm md:text-base font-medium md:font-normal bg-transparent placeholder:text-black/50'
            />

            <Modal
              isOpen={captchaModalOpen}
              onRequestClose={() => setCaptchaModalOpen(false)}
              className='captcha-modal' // Add the new class here
              overlayClassName='overlay' // Add your overlay styles here
            >
              <h2>Captcha Verification</h2>
              {/* <SliderCaptcha
                create={SLIDER_CAPTCHA_CREATE_URI}
                verify={SLIDER_CAPTCHA_VERIFY_URI}
                callback={(token) => {
                  verifiedCallback(token);
                  setCaptchaModalOpen(false); // Close the modal on successful captcha verification
                }}
              /> */}

              <HCaptcha
                sitekey={REACT_APP_HCAPTCHA_SITE_KEY}
                onVerify={(token) => {
                  verifiedCallback(token);
                  setCaptchaModalOpen(false); // Close the modal on successful captcha verification
                }}
              />
            </Modal>
          </div>
          <button
            disabled={loading || email.trim() === '' || !emailRegex.test(email)}
            onClick={() => setCaptchaModalOpen(true)}
            className='flex items-center bg-black/70 hover:bg-black/60 px-5 py-2 rounded-lg border-0 border-solid border-black/40 justify-center gap-2 cursor-pointer h-[34px] md:h-full font-bold text-sm md:text-base text-white disabled:opacity-70 disabled:pointer-events-none'
          >
            {/*<img
              src={telegram}
              alt='telegram'
              className='h-3 md:h-4 w-4 md:w-5'
            />*/}
            Sign Up
          </button>
        </div>

        <div className='w-[90%] md:w-[85%] flex justify-between items-start mt-28 flex-wrap'>
          <div className='w-[100%] md:w-1/3 mb-8 md:mb-8'>
            <img className='w-28' src={black3dHoudini} alt='black3dHoudini' />
            <p className='text-sm text-[#14161b] mt-8 leading-6'>
              3dHoudini is a 3D-print ecosystem built on the tokenization of CAD
              designs. Our Print Token and CAD Token technologies bring designs
              to life across all media. We aim to digitize, decentralize, and
              democratize the future of manufacturing.
            </p>
            <div className='flex gap-3 mt-3'>
              <a
                rel='noreferrer'
                href='https://twitter.com/3DHoudini'
                target='_blank'
              >
                <img src={twitter} alt='twitter' />
              </a>
              <a
                rel='noreferrer'
                href='https://discord.gg/QKQzY3ZM3f'
                target='_blank'
              >
                <img src={discord} alt='facebook' />
              </a>
              <a
                rel='noreferrer'
                href='https://www.linkedin.com/company/3dhoudini'
                target='_blank'
              >
                <img src={linkedIn} alt='linkedIn' />
              </a>
              <a
                rel='noreferrer'
                href='https://www.youtube.com/channel/UCmLHtB0xiHS8AHVwhoPLhSg'
                target='_blank'
              >
                <img src={youtube} alt='youtube' />
              </a>
            </div>
          </div>
          <div className='w-[100%] md:w-auto items-end mb-8 md:mb-0'>
            <h6 className='text-lg font-bold text-[#14161b] h-8 md:h-8 flex justify-start mb-6'>
              Resources
            </h6>
            <div className='flex flex-col gap-3'>
              <Link
                className='w-fit transition ease-in-out delay-100 hover:text-sky-500 text-sm text-[#14161b]'
                onClick={scrollToTop}
                to='/contact'
              >
                Contact
              </Link>
              <Link
                className='w-fit transition ease-in-out delay-100 hover:text-sky-500 text-sm text-[#14161b]'
                to='/terms-and-conditions'
                onClick={scrollToTop}
              >
                Terms and Conditions
              </Link>
              <Link
                className='w-fit transition ease-in-out delay-100 hover:text-sky-500 text-sm text-[#14161b]'
                to='/privacy-policy'
                onClick={scrollToTop}
              >
                Privacy Policy
              </Link>
              <Link
                className='w-fit transition ease-in-out delay-100 hover:text-sky-500 text-sm text-[#14161b]'
                to='/shipping-information'
                onClick={scrollToTop}
              >
                Shipping Information
              </Link>
              <Link
                className='w-fit transition ease-in-out delay-100 hover:text-sky-500 text-sm text-[#14161b]'
                to='/return-policy'
                onClick={scrollToTop}
              >
                Return Policy
              </Link>
            </div>
          </div>
          <div className='flex md:block w-[100%] md:w-1/3 justify-center items-end'>
            <div>
              <h6 className='text-lg font-bold text-[#14161b] h-8 md:h-8 flex justify-start mb-6'>
                News
              </h6>
              {footerNews.map((news, i) => (
                <Link
                  to={`/news/#news-${i}`}
                  key={i}
                  className='article-link'
                  onClick={() => scrollToNewsItem(i)}
                >
                  <div className='flex mb-4 gap-4' key={i}>
                    <img
                      className='rounded-lg h-[70px] w-[70px]'
                      src={news.image}
                      alt='news'
                    />
                    <div>
                      <p className='font-semibold text-base text-[#14161b]'>
                        {news.headline}
                      </p>
                      <span className='text-sm text-[#14161b]'>
                        {news.date}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>

        <span className='mt-6 text-sm text-black'>
          © 2024 3DHoudini. All Rights Reserved{' '}
        </span>
      </div>
    </>
  );
};

export default Footer;

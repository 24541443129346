import React, { useEffect, useRef, useState } from 'react';
import { closeIcon, logo3dCircle } from '../../assets/images';
import { Link, useLocation } from 'react-router-dom';
import StudioHeader from './StudioHeader';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const modalRef = useRef(null);
  const buttonRef = useRef(null);
  const location = useLocation();

  const handleCloseNav = () => {
    setIsNavOpen(false);
  };

  const handleDropDownClose = () => {
    setIsDropDownOpen(false);
    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target) &&
        window.innerWidth >= 768
      ) {
        setIsDropDownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const styles = {
    link: 'font-medium text-md text-black hover:bg-black/10 px-2 py-1 rounded-md',
    li: 'h-[64px] flex items-center justify-center border-solid'
  };

  return (
    <>
      <div className='w-full h-10 md:h-[64px] flex justify-end md:justify-between items-center relative bg-theme sticky top-0 z-50 border-b border-solid border-white/20'>
        <nav className='w-full bg-transparent md:bg-transparent flex justify-end md:block z-10 md:z-auto'>
          <div className='w-full flex items-center justify-between ps-4 md:ps-16 md:pe-12'>
            <Link
              to='/'
              className='hidden md:flex items-center space-x-3 rtl:space-x-reverse'
            >
              <img height={48} width={48} src={logo3dCircle} alt='3dh Logo' />
            </Link>
            <img
              src={logo3dCircle}
              className='md:hidden'
              height={24}
              width={24}
              alt='3dh Logo'
            />
            <button
              onClick={() => setIsNavOpen(true)}
              className='inline-flex items-center me-4 w-10 h-10 justify-center md:hidden'
            >
              <svg
                className='w-5 h-5'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 17 14'
              >
                <path
                  stroke='black'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M1 1h15M1 7h15M1 13h15'
                />
              </svg>
            </button>

            <div
              className='hidden w-full md:flex items-center gap-8 md:w-auto'
              id='navbar-default'
            >
              <ul className='flex gap-4'>
                <li
                  className={`${styles.li} ${
                    location.pathname === '/about' &&
                    'border-b-2 border-solid border-black'
                  }`}
                >
                  <Link
                    to='about'
                    className={styles.link}
                    onMouseOver={() => setIsDropDownOpen(false)}
                  >
                    Solutions
                  </Link>
                </li>
                <li
                  className={`${styles.li} ${
                    location.pathname === '/team' &&
                    'border-b-2 border-solid border-black'
                  }`}
                >
                  <Link
                    to='/team'
                    className={styles.link}
                    onMouseOver={() => setIsDropDownOpen(false)}
                  >
                    Team
                  </Link>
                </li>
                <li
                  className={`${styles.li} ${
                    location.pathname === '/news' && 'border-b-2 border-black'
                  }`}
                >
                  <Link
                    to='/news'
                    className={styles.link}
                    onMouseOver={() => setIsDropDownOpen(false)}
                  >
                    News
                  </Link>
                </li>
                <li className={styles.li + ' relative'} ref={buttonRef}>
                  <button
                    onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                    onMouseOver={() => setIsDropDownOpen(true)}
                    className='font-medium text-md text-black flex items-center justify-between rounded  px-2 py-1 rounded-md hover:bg-black/10'
                  >
                    Projects
                  </button>
                  <div
                    ref={modalRef}
                    onMouseLeave={() => setIsDropDownOpen(false)}
                    className={`z-10 ${
                      isDropDownOpen ? 'block' : 'hidden'
                    } absolute top-14 bg-white divide-y divide-gray-100 rounded-lg shadow right-0`}
                  >
                    <ul className='py-2 text-md text-black w-60 bg-theme border border-solid border-white/20 rounded-md'>
                      <li>
                        <Link
                          to='/projects/wagmi'
                          onClick={handleDropDownClose}
                          className='block px-6 py-1 mx-2 rounded-md font-medium hover:bg-black/20 '
                        >
                          Wagmi Games
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/projects/monuments'
                          onClick={handleDropDownClose}
                          className='block px-6 py-1 mx-2 rounded-md font-medium hover:bg-black/20 '
                        >
                          Temples & Monuments
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  className={`${styles.li} ${
                    location.pathname.includes('/studio') &&
                    'border-b-2 border-black'
                  }`}
                >
                  <Link
                    to='/studio'
                    className={styles.link}
                    onMouseOver={() => setIsDropDownOpen(false)}
                  >
                    Studio
                  </Link>
                </li>
                <li
                  className={`${styles.li} ${
                    location.pathname === '/contact' &&
                    'border-b-2 border-black'
                  }`}
                >
                  <Link
                    to='/contact'
                    className={styles.link}
                    onMouseOver={() => setIsDropDownOpen(false)}
                  >
                    Contact
                  </Link>
                </li>
                <li className='flex items-center font-medium text-md text-slate-600 hover:text-indigo-900 '>
                  <Link
                    to='/print-your-nft'
                    className='font-semibold text-md text-white px-5 py-1 bg-btn-primary hover:bg-btn-primary/80 rounded-lg'
                    onMouseOver={() => setIsDropDownOpen(false)}
                  >
                    Print your NFT
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {isNavOpen && (
          <div className='mobile-navbar bg-[#1F1F21] w-screen h-screen absolute top-0 left-0 md:hidden flex flex-col p-8 z-50'>
            <span className='close-icon flex justify-end w-100 mb-12'>
              <img onClick={handleCloseNav} src={closeIcon} alt='close' />
            </span>
            <div className='links flex flex-col gap-4'>
              <Link
                to='/'
                onClick={handleCloseNav}
                className='text-[#FFFDFD] text-xl w-fit'
              >
                Home
              </Link>
              <Link
                to='/about'
                onClick={handleCloseNav}
                className='text-[#FFFDFD] text-xl w-fit'
              >
                Solutions
              </Link>
              <Link
                to='/team'
                onClick={handleCloseNav}
                className='text-[#FFFDFD] text-xl w-fit'
              >
                Team
              </Link>
              <Link
                to='/news'
                onClick={handleCloseNav}
                className='text-[#FFFDFD] text-xl w-fit'
              >
                News
              </Link>
              <div>
                <button
                  onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                  className='text-[#FFFDFD] text-xl flex items-center gap-2 bg-transparent border-0 p-0'
                >
                  Projects{' '}
                  <svg
                    className='w-2.5 h-2.5 ms-2.5'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 10 6'
                  >
                    <path
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='m1 1 4 4 4-4'
                    />
                  </svg>
                </button>

                <div
                  className={`z-10 ${
                    isDropDownOpen ? 'block' : 'hidden'
                  }  bg-transparent divide-y`}
                >
                  <div className='py-1 text-lg text-[#FFFDFD]'>
                    <Link
                      to='/projects/wagmi'
                      onClick={handleDropDownClose}
                      className='block px-4 py-1'
                    >
                      Wagmi Games
                    </Link>
                    <Link
                      to='/projects/monuments'
                      onClick={handleDropDownClose}
                      className='block px-4 py-1'
                    >
                      Temples & Monuments
                    </Link>
                  </div>
                </div>
              </div>
              <Link
                to='/studio'
                onClick={handleCloseNav}
                className='text-[#FFFDFD] text-xl w-fit'
              >
                Studio
              </Link>
              <Link
                to='/contact'
                onClick={handleCloseNav}
                className='text-[#FFFDFD] text-xl w-fit'
              >
                Contact
              </Link>
            </div>
            {location.pathname.includes('/studio') ? (
              <StudioHeader fromHeader={true} setIsNavOpen={setIsNavOpen} />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default Header;

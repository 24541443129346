import React, { useRef, useState } from 'react';
import Beasts from './Beasts';
import Shipping from './Shipping';
import { useUserContext } from '../../../hooks';
import { placeOrder } from '../../../services';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useToast } from '../../../components/ui/use-toast';
import LoadingBar from 'react-top-loading-bar';
import config from '../../../config.json';
import { countryWithCode } from '../../../constants/countryOptions';
import { useNavigate } from 'react-router-dom';
import { studioToast } from '../../../components/toast';

const NewOrder = () => {
  const [isValidLink, setIsValidLink] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fileData, fileLink, setFileData, setFileLink } = useUserContext();
  const { toast } = useToast();
  const navigate = useNavigate();
  const loaderRef = useRef(null);

  const handlePlaceOrder = async (values) => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (!authData) {
      return;
    }

    const printing_attributes = {
      size: '2.5'
    };

    const { firstName, lastName, comment, email, country, ...shippingAddress } =
      values;
    const countryAndCode = countryWithCode.find(
      (item) => item.value.trim() === country.trim()
    );

    const shipping = {
      name: firstName + ' ' + lastName,
      email,
      address: { ...shippingAddress, country: countryAndCode.code }
    };

    loaderRef.current.continuousStart();
    setIsSubmitting(true);
    const formData = new FormData();

    const filteredFileData = fileData.filter((item) => item !== null);
    const filteredLinkData = fileLink.filter((item) => item.length > 0);
    if (filteredFileData.length === 0 && filteredLinkData.length === 0) {
      studioToast('Please select modal file or paste link.');
      loaderRef?.current?.complete();
      setIsSubmitting(false);
      return;
    }

    if (filteredFileData.length) {
      filteredFileData.forEach((file) => {
        formData.append('attachment', file); // 'files' is the key to which all files will be appended
      });
    } else if (filteredLinkData.length) {
      filteredLinkData.map((link) => {
        formData.append('attachment_url', link);
      });
    }

    formData.append('account', authData._id);
    formData.append('printing_attributes', JSON.stringify(printing_attributes));
    formData.append('shipping', JSON.stringify(shipping));
    formData.append('comment', comment);

    try {
      const res = await placeOrder(formData);

      if (res.status === 200) {
        formik.resetForm();
        setFileData(null);
        setFileLink('');
        // toast({
        //   description:
        //     'Thanks for the order. We\'ll get back to you with a proof shortly.'
        // });

        studioToast(
          "Thanks for the order. We'll get back to you with a proof shortly."
        );

        navigate(`/studio/view-orders/${res.data._id}`);
      } else {
        // toast({
        //   description: res?.response?.data?.data
        //     ? res.response.data.data
        //     : 'Something went wrong.'
        // });
        studioToast(
          res?.response?.data?.data
            ? res.response.data.data
            : 'Something went wrong.'
        );
      }
    } catch (error) {
      console.log('Error:--', error);
    }
    setIsSubmitting(false);
    loaderRef?.current?.complete();
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    comment: ''
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    street1: Yup.string().required('Required'),
    street2: Yup.string(),
    city: Yup.string().required('Required'),
    state: Yup.string(),
    zip: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    comment: Yup.string()
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handlePlaceOrder
  });

  return (
    <>
      <LoadingBar
        color={config.loaderBar}
        height={3}
        containerStyle={{ zIndex: 101 }}
        transitionTime={200}
        ref={loaderRef}
      />
      <div className='sm:w-[780px] w-full sm:px-20 px-4'>
        <h1 className='sticky bg-bg-primary z-10 top-0 sm:text-7xl text-4xl text-black font-light sm:font-extralight py-6 sm:py-8 w-full'>
          Let's get started!
        </h1>
        <p className='text-base font-normal text-tx-secondary'>
          Upload files and give us a description of what you would like to
          create. We will get back to you with the next steps or a proof and
          pricing in 1-3 business days.
        </p>
        <Beasts setIsValidLink={setIsValidLink} isValidLink={isValidLink} />
        <Shipping
          isValidLink={isValidLink}
          isSubmitting={isSubmitting}
          formik={formik}
        />
      </div>
    </>
  );
};
export default NewOrder;

import React, { useEffect, useState } from 'react';
import LeftCol from '../../../components/LeftCol';
import NewOrder from './NewOrder';
import { useNavigate } from 'react-router-dom';

export default function Body() {
  const [isScreenShow, setIsScreenShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (!authData) {
      navigate('/studio/connect');
    } else {
      setIsScreenShow(true);
    }
  }, []);

  return (
    <>
      <div className='flex md:h-[calc(100vh-65px)] h-[calc(100vh-41px)] w-full my-0 sm:flex-row flex-col justify-center items-center bg-bg-primary'>
        {isScreenShow ? (
          <>
            <LeftCol />
            <div className='md:flex-1 md:h-[calc(100vh-65px)] h-[calc(100vh-77px)] w-full overflow-y-auto bg-bg-primary'>
              <NewOrder />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

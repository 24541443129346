const LearnMore = () => {
  const pStyle = 'text-base md:text-lg text-[#e17146]';
  const h2Style = 'text-3xl md:text-4xl font-bold text-[#e17146] mb-3 mt-6';

  return (
    <>
      <div className='w-full bg-black flex justify-center'>
        <div className='w-[85%] md:w-[620px] flex flex-col justify-start mb-48 mt-16 md:mt-20 animate-toastFade'>
          <h1 className='text-3xl md:text-7xl font-extrabold text-[#e17146] mb-12'>
            Ayodhya Ram Mandir
          </h1>
          <p className={pStyle + ' mb-8'}>
            The Ayodhya Ram Mandir holds immense historical and cultural
            importance for the people of India. The temple’s inauguration marks
            a significant milestone in the nation's history, as it symbolizes
            the fulfillment of a longstanding aspiration. Led by Prime Minister
            Modi, India’s government is close to fulfilling a decade-old
            election promise months out from nationwide polls with the
            announcement that a new temple will open on disputed land in
            January.
          </p>
          <h2 className={h2Style}>Historical Background</h2>
          <p className={pStyle + ' mb-6'}>
            The roots of the Ayodhya Ram Mandir can be traced back to ancient
            times, with the city of Ayodhya being synonymous with the birthplace
            of Lord Rama according to Hindu scriptures. The historical
            significance of Ayodhya is deeply embedded in the religious and
            cultural heritage of the region.
          </p>
          <h2 className={h2Style}>Archaeological Evidence</h2>
          <p className={pStyle + ' mb-6'}>
            The archaeological findings at the site played a crucial role in
            determining the historical authenticity of the temple. The
            excavation conducted by the Archaeological Survey of India (ASI)
            unearthed remnants that provided evidence of a structure dating back
            to ancient times, supporting the belief that the temple stands on
            the site where Lord Rama was born.
          </p>
          <h2 className={h2Style}>Legal Proceedings</h2>
          <p className={pStyle + ' mb-6'}>
            The legal journey surrounding the Ayodhya Ram Mandir was complex and
            multifaceted. The resolution of the dispute involved a legal process
            spanning several decades, with the Supreme Court of India delivering
            a landmark judgment in 2019, facilitating the temple’s construction.
          </p>
          <h2 className={h2Style}>Architectural Marvel</h2>
          <p className={pStyle + ' mb-4'}>
            The architecture of the Ayodhya Ram Mandir is a blend of traditional
            and contemporary design, reflecting the region’s cultural richness.
            Skilled artisans and craftsmen were involved in the construction,
            ensuring the temple stands as a testament to India's artistic
            heritage.
          </p>
          <p className={pStyle + ' mb-4'}>
            The first detailed descriptions of the Ram Janmabhoomi Mandir were
            released recently, showing the lavishly decorated structure being
            built on the site where once a controversial site stood for over 500
            years.
          </p>
          <p className={pStyle + ' mb-4'}>
            Located in the holy city of Ayodhya in the electorally significant
            state of Uttar Pradesh, the temple’s interiors will be adorned with
            gold bars and artwork celebrating India’s diversity, according to
            Nripendra Misra, chairman of the temple’s construction committee.
          </p>
          <p className={pStyle + ' mb-4'}>
            In a recent briefing, Misra gave detailed descriptions of the design
            of the Ram Janmabhoomi Mandir, from the idols that will be placed
            inside the building to the source of materials used to build the
            shrine.
          </p>
          <p className={pStyle + ' mb-4'}>
            Indian engineering group Larsen and Toubro is constructing the
            temple on a 2.67-acre (1.08 hectares) site within a 70-acre (28
            hectares) complex, Misra said, adding that artists from across the
            country have been selected to create artwork and friezes showcasing
            the country’s rich history.
          </p>
          <p className={pStyle + ' mb-4'}>
            Three sculptors have been entrusted with carving friezes of Lord
            Ram, one of which will reside inside the sanctum sanctorum. The
            temple will also be adorned with gold bars created by well-known
            Indian jewelers, according to Misra.
          </p>
          <p className={pStyle + ' mb-4'}>
            About 100,000 devotees are expected to visit the temple every day,
            meaning an individual might only be allowed into the sanctum
            sanctorum for about 20 seconds due to demand.
          </p>
          <p className={pStyle + ' mb-4'}>
            The temple’s construction is expected to cost about 15 billion
            rupees ($180 million) according to Misra.
          </p>
          <p className={pStyle + ' mb-4'}>
            The government has not provided funds for the temple’s
            establishment, he added, saying donations of about 30 billion rupees
            ($361 million) have been collected for the complex.
          </p>
          <p className={pStyle + ' mb-4'}>
            Ayodhya, an ancient city in Uttar Pradesh of about 76,000 people, is
            an important Hindu pilgrimage site and sees millions of visitors
            each year.
          </p>
          <p className={pStyle + ' mb-6'}>
            Ayodhya has recently undergone a large infrastructural makeover,
            including the construction of a new international airport set to
            open in November, according to Misra. Local media reports indicate
            that some of the city’s historic and religious sites have also been
            restored, while its roads and railways are expected to get a
            facelift.
          </p>
          <h2 className={h2Style}>Cultural Unity</h2>
          <p className={pStyle + ' mb-6'}>
            The inauguration of the Ayodhya Ram Mandir is seen as an opportunity
            to foster cultural unity. The event witnessed participation from
            people of diverse backgrounds, emphasizing the inclusive nature of
            the cultural and religious tapestry of India.
          </p>
          <h2 className={h2Style}>Symbolism</h2>
          <p className={pStyle}>
            The Ayodhya Ram Mandir is not just a religious edifice; it
            symbolizes the cultural synthesis that has defined India for
            centuries. The temple stands as testimony to the shared heritage of
            the Indian subcontinent, transcending religious boundaries.
          </p>
        </div>
      </div>
    </>
  );
};

export default LearnMore;

import {
  printTokenLarge,
  pc,
  ssap,
  whiteCircle3dLogo,
  smallSsap,
  smallPrintToken,
  smallWhiteCircle3dLogo,
  smallPc,
  locationTag,
  newChain
} from '../assets/images';

export const aboutDetails = [
  {
    imageAlignLeft: false,
    image: whiteCircle3dLogo,
    mobileImg: smallWhiteCircle3dLogo,
    title: '',
    description:
      'The 3dHoudini platform, built on the 3dini Network, will transform our approach to design and manufacturing. Using tokenized designs, 3dHoudini creates a seamless, secure, and efficient ecosystem for creators, manufacturers, and consumers.',
    className: 'whiteCircleLogoData'
  },
  /*
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Key Features',
    description: '',
    className: 'whiteCircleLogoData'
  },
  */
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Tokenization of Designs',
    description:
      'The 3dHoudini platform, built on the 3dini Network, will transform our approach to design and manufacturing. Using tokenized designs, 3dHoudini creates a seamless, secure, and efficient ecosystem for creators, manufacturers, and consumers.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Seamless Integration with the 3dini Network',
    description:
      '3dHoudini is part of the 3dini Network. The open-source network will provide 3dHoudini with robust infrastructure and incentive mechanisms that reward computational and manufacturing contributions.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Decentralized Marketplace',
    description:
      '3dHoudini is part of the 3dini Network. The open-source network will provide 3dHoudini with robust infrastructure and incentive mechanisms that reward computational and manufacturing contributions.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Enhanced Security and Efficiency',
    description:
      'Blockchain technology makes transactions on the platform more secure, while Smart Contracts automate and streamline transactions, reducing the need for intermediaries and increasing efficiency.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Incentivized Participation',
    description:
      'Designers and manufacturers are incentivized to participate in the 3dHoudini ecosystem through the 3dini Network. The network rewards participants with DINI, the network’s native cryptocurrency. This fosters a vibrant community of creators and producers, driving continuous growth and development.',
    className: 'whiteCircleLogoData'
  }
];

export const printTokenCenter = [
  {
    imageAlignLeft: false,
    image: pc,
    mobileImg: smallPc,
    title: '',
    heading: 'Your Local Hub on the 3dini Network',
    description:
      'A Print Center is a state-of-the-art 3D-print location integrated into the 3dini Network. Print Centers serve as local hubs for decentralized manufacturing and may be structured as decentralized autonomous organizations (DAOs). These centers are designed to provide high-quality 3D printing services, foster innovation, and support the community',
    className: 'whiteCircleLogoData'
  },
  /*
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Key Features',
    description: '',
    className: ''
  },
  */
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Advanced 3D Printing Facilities ',
    description:
      'Print Centers are equipped with advanced 3D-printing equipment and can produce a wide range of high-quality products ranging from prototyping to production parts.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: locationTag,
    mobileImg: locationTag,
    title: 'Seamless Network Integration',
    description:
      'Print Centers are seamlessly connected to 3dHoudini through the 3dini Network, allowing access to all the resources of the marketplace and ecosystem. This integration ensures IP security and product quality. Each print job is verified and authorized through the network, ensuring authenticity.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Community and Collaboration',
    description:
      'Print Centers serve as community hubs where designers, manufacturers, and consumers can collaborate and share ideas. Workshops, training sessions, and events are regularly held to promote knowledge sharing and innovation.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Incentivized Participation',
    description:
      'By participating in the 3dini Network, Print Centers earn DINI coins for their contributions. Participation includes both the computational power used in the design verification process and the manufacturing power used in producing the designs',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Sustainable Practices',
    description:
      'Print Centers are committed to sustainable manufacturing practices, minimizing waste, and reducing the environmental impact of 3D printing.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Enhanced Security and Privacy',
    description:
      'All transactions and interactions at the Print Center are secured through blockchain technology. This guarantees the privacy of customer data and the integrity of the manufacturing process.',
    className: 'whiteCircleLogoData'
  }
];
export const PrintTokens = [
  {
    imageAlignLeft: false,
    image: printTokenLarge,
    mobileImg: printTokenLarge,
    title: '',
    heading: '',
    description: '',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Print Token: Create. Sell. Print. ',
    description:
      'The Print Token is a novel innovation of 3dHoudini and the 3dini Network. It seamlessly connects IP holders, consumers, and 3D printers (manufacturers). This 3D-printable, tokenized design represents the future of digital fabrication and plays a major role in facilitating truly decentralized manufacturing.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: '3D Printable Design ',
    description:
      'The Print Token is a specially designed digital file that can be easily printed on any compatible 3D printer.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Tokenized and Secure ',
    description:
      'A Print Token is tokenized on the blockchain, providing a unique and immutable record of its creation and ownership. This ensures the security and authenticity of the token, preventing unauthorized use and duplication.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Seamless Network Integration ',
    description:
      'A Print Token is tokenized on the blockchain, providing a unique and immutable record of its creation and ownership. This ensures the security and authenticity of the token, preventing unauthorized use and duplication.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Enhanced Functionality',
    description:
      'The Print Token is embedded with a Smart Contract that automatically manages and executes tasks such as design verification, print authorization, and reward distribution, streamlining the manufacturing process and reducing the need for manual intervention.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Incentivized Participation',
    description:
      'Manufacturers that participate in the 3dini Network are rewarded in DINI for their contribution to the network’s manufacturing power in addition to payment for manufacturing products, fostering a robust and dynamic decentralized manufacturing community.',
    className: 'whiteCircleLogoData'
  },
  {
    imageAlignLeft: true,
    image: '',
    mobileImg: '',
    title: 'Interoperability',
    description:
      'The Print Token is designed to be compatible with a wide range of 3D printers and other types of manufacturing equipment and processes. This promotes accessibility and allows more users to benefit from and participate in the 3dini Network.',
    className: 'whiteCircleLogoData'
  }
];

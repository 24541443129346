import {
  teamLeader1,
  teamLeader2,
  teamLeader3,
  teamLeader4,
  SreenathAV,
  JordyMeeder
} from '../assets/images';

export const teamLeaders = [
  {
    image: teamLeader1,
    name: 'Chris White',
    role: 'Founder & CEO',
    description:
      'Founded manufacturing, wholesale distribution, and nanotech companies. Chris led the full-scale prototype build of Blue Origin’s Blue Moon Lander.'
  },
  {
    image: teamLeader2,
    name: 'Yannick White',
    role: 'Founder & President',
    description:
      'Founder of CAD Parlor, Inc. 3dHoudini acquired CAD Parlor in 2021. Yannick is the key inventor of the 3dHoudini technology.'
  },
  {
    image: teamLeader3,
    name: 'Shrivathsa Ballal',
    role: 'Founder & CTO',
    description:
      'Former CTO at WatchBox, Managing Director at Electronic Arts, Senior Technology Architect at Accenture, and Associate General Manager at Mphasis.'
  },
  {
    image: JordyMeeder,
    name: 'Jordy Meeder',
    role: 'Head of Network Marketing',
    description:
      'Jordy is a serial entrepreneur specializing in manufacturing. He is an advisor to many Web3 Projects with expertise in DAO governance, tokenomics, and NFT technology.'
  },
  {
    image: SreenathAV,
    name: 'Sreenath AV',
    role: 'Chief Growth Officer',
    description:
      'Former VP & GM at DXC, Sreenath has been driving strong business outcomes. Prior to DXC he was Senior VP of India and SAARC Business Unit for Wipro.'
  }
];

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logo3dCircle } from '../../assets/images';
import { Button } from '../ui';

const StudioHeader = ({ fromHeader, setIsNavOpen }) => {
  const [userAuthData, setUserAuthData] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes('/studio')) {
      const data = JSON.parse(localStorage.getItem('authData'));
      if (data) {
        setUserAuthData(data);
      } else {
        setUserAuthData();
      }
    }
  }, [location]);

  const handleLogout = () => {
    localStorage.removeItem('authData');
    setUserAuthData();
    navigate('/studio/connect');
    if (fromHeader) {
      setIsNavOpen(false);
    }
  };

  const styles={
    tabs:'cursor-pointer transition-all text-base font-medium hidden md:flex items-center px-2 py-1.5 transition-all w-full rounded-lg hover:bg-black/5'
  }

  return (
    <>
      {userAuthData && (
        <>
          {fromHeader ? (
            <Button
              onClick={handleLogout}
              className='mt-10 w-fit'
            >
              Logout
            </Button>
          ) : (
            <>
              {userAuthData.admin && (
                <Link
                  to='/studio/admin'
                  className={`${styles.tabs} gap-4 ${
                    location.pathname.includes('/studio/admin')
                      ? 'text-btn-primary'
                      : 'text-tx-secondary'
                  }`}
                > Admin</Link>
              )}
              <Button
                onClick={handleLogout}
                className='hidden md:block w-full mt-4'
              >
                Logout
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default StudioHeader;

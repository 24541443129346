import axios from 'axios';
import config from '../config.json';

export const signUp = async (email, password) => {
  try {
    const { data } = await axios.post(config.serverUrl + '/user/auth/sign-up', {
      email,
      password
    });
    return data;
  } catch (error) {
    console.log('error in sign up:--', error);
    return error;
  }
};

export const signIn = async (email) => {
  try {
    const { data } = await axios.post(config.serverUrl + '/user/auth/sign-in', {
      email
    });
    return data;
  } catch (error) {
    console.log('error in sign in:--', error);
    return error;
  }
};

import Body from './Body';

const OrderDetails = () => {
  return (
    <main className=' flex flex-col justify-between items-center bg-theme'>
      <Body />
    </main>
  );
};

export default OrderDetails;

import React, { useEffect, useState } from 'react';
import './index.scss';
import { specialEdition, wagmiGames } from '../../assets/images';
import { projects } from '../../constants';
import Loader from '../../components/loader/Loader';

const WagmiProject = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    const videos = document.querySelectorAll('video');
    const totalResources = images.length + videos.length;

    let loadedResources = 0;

    const resourceLoaded = () => {
      loadedResources++;
      if (loadedResources === totalResources) {
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        resourceLoaded();
      } else {
        image.addEventListener('load', resourceLoaded);
      }
    });

    videos.forEach((video) => {
      if (video.readyState >= 3) {
        resourceLoaded();
      } else {
        video.addEventListener('canplaythrough', resourceLoaded);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', resourceLoaded);
      });
      videos.forEach((video) => {
        video.removeEventListener('canplaythrough', resourceLoaded);
      });
    };
  }, []);

  return (
    <>
      <div className='overflow-hidden w-full bg-[#020109] flex justify-center'>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='w-[90%] md:w-[1240px] animate-toastFade flex flex-col justify-start items-center mb-48'>
            <img
              src={wagmiGames}
              alt='wagmiGames'
              className='my-10 md:my-16 w-44 md:w-auto'
            />
            <p className='mb-16 md:mb-28 md:w-1/2 text-start md:text-center text-base md:text-lg font-normal text-slate-200'>
              3dHoudini has partnered with WAGMI GAMES to create The Emperor
              Melek Tous Special Edition Print Token to promote the soft launch
              of their first game WAGMI Defence.
            </p>
            <div className='specialEditionImg relative mb-12 flex justify-center rounded'>
              <img
                src={specialEdition}
                className='w-[90%] rounded-3xl border-solid border-2 border-slate-600/50'
                alt='specialEdition'
              />
            </div>
            {projects.map((project, i) => (
              <div
                className='project-info w-full flex flex-col items-center
              md:flex-row gap-2 md:gap-12 my-8 md:my-16 md:max-w-[850px]'
                key={i}
              >
                {project.imageAlignLeft ? (
                  <>
                    <h2 className='md:hidden text-center text-3xl md:text-4xl font-bold text-slate-200 mb-4'>
                      {project.name}
                    </h2>
                    {project.video ? (
                      <video
                        autoPlay
                        src={project.video}
                        playsInline
                        muted
                        controlsList='nodownload'
                        preload='metadata'
                        loop
                        className='home-video'
                      />
                    ) : (
                      <img
                        className='w-1/4 rounded-xl border-solid border-2 border-slate-600/50'
                        src={project.image}
                        alt={project.name}
                      />
                    )}
                    <div className='w-full flex md:block items-start'>
                      <h2 className='hidden md:block text-3xl md:text-4xl font-bold text-slate-200 mb-4 leading-tight'>
                        {project.name}
                      </h2>
                      <p
                        className='project-desc desc-right text-base md:text-lg md:leading-7
                    font-normal text-slate-200 relative bg-black md:px-0 pt-4'
                      >
                        {project.description}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <h2 className='md:hidden text-3xl md:text-4xl font-bold text-slate-200 mb-4'>
                      {project.name}
                    </h2>
                    <img
                      src={project.image}
                      alt={project.name}
                      className='md:hidden border-solid border-2 border-slate-600/50'
                    />
                    <div className='w-full flex md:block items-start'>
                      <h2 className='hidden md:block text-3xl md:text-4xl font-bold text-slate-200 leading-tight mb-4'>
                        {project.name}
                      </h2>
                      <p
                        className='project-desc desc-left text-base md:text-lg md:leading-7
                    font-normal text-slate-200 relative bg-black pt-4 md:px-0'
                      >
                        {project.description}
                      </p>
                    </div>
                    <img
                      src={project.image}
                      alt={project.name}
                      className='hidden md:block rounded-xl border-solid border-2 border-slate-600/50'
                    />
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default WagmiProject;

import React, { useEffect, useRef, useState } from 'react';
import { getOrderStatus } from '../../../services';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import AkcbLoader from '../../../components/loader';
import config from '../../../config.json';
import LoadingBar from 'react-top-loading-bar';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../helpers';

const Orders = () => {
  const [userOrders, setUserOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const loaderRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (!authData) {
      setLoading(false);
      return;
    }
    loaderRef.current.continuousStart();
    try {
      setLoading(true);
      const res = await getOrderStatus(authData._id);

      if (res.status === 200 && res.data !== null) {
        setUserOrders(res.data);
      } else {
        setUserOrders([]);
      }
    } catch (error) {
      console.log('Error:--', error);
    }
    setLoading(false);
    loaderRef?.current?.complete();
  };

  const handleOrderSelect = (id) => {
    navigate(`/studio/view-orders/${id}`);
  };

  const styles = {
    orderRow:
      'py-4 text-tx-secondary cursor-pointer w-full flex justify-between items-center border-b border-solid border-white/10 transition-all hover:bg-white/5 sm:ps-3 '
  };

  const OrderRow = ({ order }) => {
    return (
      <div
        className={styles.orderRow}
        onClick={() => handleOrderSelect(order._id)}
      >
        <div className='flex items-center sm:gap-4 gap-2'>
          <h2 className='sm:text-md text-base text-tx-secondary font-normal sm:min-w-28 min-w-24'>
            #{order.id}
          </h2>
          <p className='text-xs sm:text-sm text-black/60 font-normal min-w-28'>
            {formatDate(order.activity[order.activity.length - 1].timestamp)}
          </p>
        </div>
        <div className='flex items-center sm:gap-4 gap-2'>
          <p className='sm:text-xs text-[10px] text-font-theme font-bold sm:mr-6 bg-white/80 border-0 border-white/20 rounded-md py-1 px-2'>
            {order.status}
          </p>
          <MdOutlineKeyboardArrowRight
            size={30}
            className='opacity-30 hidden sm:block'
          />
          <MdOutlineKeyboardArrowRight
            size={20}
            className='opacity-30 sm:hidden'
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <LoadingBar
        color={config.loaderBar}
        height={3}
        containerStyle={{ zIndex: 101 }}
        transitionTime={200}
        ref={loaderRef}
      />
      {loading ? (
        <AkcbLoader />
      ) : (
        <div className='flex flex-col sm:w-[840px] w-full pb-28 sm:px-20 px-4'>
          <h1 className='sticky bg-bg-primary z-10 top-0 sm:text-7xl text-4xl text-black font-light sm:font-extralight py-6 sm:py-8 w-full'>
            Your orders
          </h1>
          {userOrders.length ? (
            userOrders.map((order, i) => (
              <React.Fragment key={i}>
                <OrderRow order={order} />
              </React.Fragment>
            ))
          ) : (
            <div className='mt-10 w-full flex justify-start items-center'>
              <p className='text-black/80 text-2xl font-light'>
                No orders found. Please create new order.
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Orders;

import React, { useRef } from 'react';
import './index.scss';
import { ShippingInformation as data } from '../../constants';

const ShippingInformation = () => {
  const scrollRef = useRef(null);
  return (
    <>
      <div className='animate-toastFade news-container w-full flex items-center justify-center bg-indigo-900'>
        <div
          className='inner-news relative md:w-[1200px] grid justify-items-center mb-44 mt-16 md:mt-0'
          ref={scrollRef}
        >
          <h1 className='text-center md:mb-16 mb-8 md:my-20 text-5xl md:text-7xl text-slate-100 font-extrabold'>
            Shipping Information
          </h1>
          {data?.map((news, i) => (
            <div
              id={`news-${i}`} // Add this line to set a unique ID for each news item
              key={i}
              className='news-card justify-center py-6 border-solid border-b-2 border-slate-200/75 
              md:pb-12 md:w-1/2 w-[90%] mb-12 '
            >
              <h6 className='mb-6 text-2xl md:text-3xl font-bold text-slate-200'>
                {news.headline}
              </h6>
              {news.description.map((desc, index) => (
                <React.Fragment key={index}>
                  {desc.type === 'points' ? (
                    <div className='pb-3 text-sm md:text-lg text-slate-300'>
                      {desc.values.map((point, id) => (
                        <p
                          dangerouslySetInnerHTML={{ __html: point }}
                          className='news-desc news-desc-points break-words text-base md:text-lg text-slate-300'
                          key={id}
                        ></p>
                      ))}
                    </div>
                  ) : desc.type === 'sub_head' ? (
                    <p
                      className={
                        'news-desc pb-3 text-2xl md:text-2xl font-bold text-slate-200'
                      }
                      dangerouslySetInnerHTML={{ __html: desc.value }}
                    ></p>
                  ) : (
                    <p
                      className={
                        'news-desc pb-3 text-sm md:text-lg text-slate-300'
                      }
                      dangerouslySetInnerHTML={{ __html: desc.value }}
                    ></p>
                  )}
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ShippingInformation;

import React from 'react';
import { teamLeaders } from '../../constants';

const Leadership = () => {
  return (
    <>
      <div className='w-full h-auto flex flex-col items-center justify-start bg-theme border-b border-black/20'>
        <div className='max-w-[90%] md:max-w-[1240px]'>
          <h1 className='my-16 md:my-20 text-center text-5xl md:text-7xl text-black font-extrabold'>
            Leadership
          </h1>
          <div className='members md:mb-24 md:flex flex-wrap justify-center'>
            {teamLeaders.map((leader, i) => (
              <div
                key={i}
                className='flex flex-col items-center justify-start mb-12 md:mx-3 md:gap-4 md:py-5 py-4 md:px-0 px-4
      rounded-[18px] md:max-w-[350px] flex-shrink-0 bg-white/10'
                style={{ }}
              >
                <div className='md:hidden flex gap-4 items-center ml-8'>
                  <img
                    className='opacity-100 h-1/5 w-1/5'
                    src={leader.image}
                    alt={leader.name}
                  />
                  <div className='flex flex-col items-start'>
                    <p className='text-2xl md:text-4xl text-black font-bold'>
                      {leader.name}
                    </p>
                    <p className='text-sm text-black font-bold md:pb-4 uppercase'>
                      {leader.role}
                    </p>
                  </div>
                </div>
                <img
                  className='hidden md:block opacity-100 md:h-44 md:w-44 drop-shadow-md shadow-xl rounded-[105px]'
                  src={leader.image}
                  alt={leader.name}
                />
                <div className='md:px-6 px-2 flex flex-col justify-start'>
                  <div className='hidden md:flex flex-col md:flex-col items-center justify-between'>
                    <p className='text-2xl md:text-2xl text-black font-bold'>
                      {leader.name}
                    </p>
                    <p className='text-sm text-black font-extrabold uppercase tracking-wider'>
                      {leader.role}
                    </p>
                  </div>
                  <p className='text-base md:text-lg text-black md:font-normal md:pt-4 pt-4'>
                    {leader.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Leadership;

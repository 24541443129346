// components/Partners.js
import React from 'react';

import { Posspole, logo0xytocin } from '../../assets/images';
const Partners = () => {
  return (
    <div className=' text-black py-16 flex justify-center bg-theme-sec backdrop-blur-lg '>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <h2 className='text-5xl md:text-7xl font-bold mb-12 text-center text-black'>
          Partners
        </h2>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
          <div
            className='p-8 bg-white/10 rounded-xl justify-center'
            style={{  }}
          >
            <img
              alt='Posspole Logo'
              className='mb-4 md:h-10 md:w-100 flex '
              src={Posspole}
            />
            <p>
              Posspole is the culmination of visionary minds with diverse
              expertise. Its members have excelled in Indian scientific
              research, their prowess in policymaking has enabled Indian
              companies to expand internationally, while attracting foreign
              collaborators to invest in India.
            </p>
          </div>
          <div
            className='p-8 bg-white/10 rounded-xl justify-center'
            style={{ }}
          >
            <img
              src={logo0xytocin}
              alt='Oxytocin Labs Logo'
              className='mb-4 md:h-12 md:w-100'
            />
            <p>
              The Oxytocin team consists of 20+ Web3 engineers with strong
              academic groundings in blockchain and crypto. They have worked
              with over 30 brands, including Godjira, Skyverse, YetiTown, and
              PXN; these projects have realized over $15M in volume processed.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;

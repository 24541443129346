import React, { useEffect, useState } from 'react';
import { countryCodeList } from '../../../constants/countryOptions';
import { useUserContext } from '../../../hooks';
import { Button, Input } from '../../../components/ui';
import { RiLoader3Line } from 'react-icons/ri';
import { ComboboxDemo } from '../../../components/ui/Combobox';

const Shipping = ({ isValidLink, formik, isSubmitting }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const { fileLink, fileData } = useUserContext();

  useEffect(() => {
    let isMounted = true;
    fetch(process.env.REACT_APP_IPINFO_CREDS)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((jsonResponse) => {
        if (isMounted) {
          console.log('jsonResponse.country--', jsonResponse.country);
          setSelectedCountry(
            countryCodeList[jsonResponse.country].toLowerCase()
          );
        }
      })
      .catch((error) => {
        console.error('Error fetching user region:', error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    formik.setFieldValue('country', selectedCountry);
  }, [selectedCountry]);

  const styles = {
    section:
      'w-full flex flex-col justify-start items-start border-b border-solid border-white/10 py-6',
    sectionTitle: 'text-2xl font-light pb-1 text-black/80',
    thankYou: 'text-base font-normal text-tx-secondary',
    textArea:
      'text-tx-secondary rounded-lg bg-transparent text-md font-medium min-h-11 max-h-40 w-full px-4 py-2 outline-0 border-[1.5px] border-black/20 placeholder:text-sm placeholder:font-normal placeholder:text-tx-secondary/50'
  };

  const isValid = fileData.find((item) => item !== null);

  return (
    <>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle + ' mb-3'}>Shipping</h2>
        <PersonalInfo formik={formik} />
        <ComboboxDemo setValue={setSelectedCountry} value={selectedCountry} />
        <ShippingAddress formik={formik} />
        <p className='text-tx-secondary/80 text-xs mt-4'>* Required</p>
      </div>
      <CommentSection formik={formik} styles={styles} />
      <p className={styles.thankYou}>
        We will get back to you in 1-3 business days. Thank you!
      </p>
      <Button
        disabled={isSubmitting || !formik.isValid || (!isValid && !isValidLink)}
        onClick={formik.handleSubmit}
        className={`mt-8 mb-16 ${isSubmitting && 'disabled:opacity-100'}`}
      >
        {isSubmitting ? (
          <RiLoader3Line className='animate-spin' size={20} />
        ) : (
          'Submit'
        )}
      </Button>
    </>
  );
};

export default Shipping;

const PersonalInfo = ({ formik }) => {
  return (
    <>
      <div className='w-full flex justify-between items-center mb-4'>
        <Input
          type='text'
          name='firstName'
          placeholder='First name*'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          className='w-[48%]'
        />
        <Input
          type='text'
          name='lastName'
          className=' w-[48%]'
          placeholder='Last name*'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
        />
      </div>
      <Input
        type='email'
        name='email'
        className='mb-4 w-full'
        placeholder='Email*'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
      />
    </>
  );
};

const ShippingAddress = ({ formik }) => {
  return (
    <div className='w-full rounded-lg border border-solid border-black/20 overflow-hidden'>
      <Input
        type='text'
        name='street1'
        className='w-full rounded-none border-0 border-b'
        placeholder='Street 1*'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.street1}
      />
      <Input
        type='text'
        name='street2'
        className='w-full rounded-none border-0 border-b'
        placeholder='Street 2'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.street2}
      />
      <div className='w-full flex justify-between items-center border-b border-solid border-black/20'>
        <Input
          type='text'
          name='city'
          className='sm:w-[70%] w-[60%] rounded-none border-0 border-r'
          placeholder='City*'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.city}
        />
        <Input
          type='text'
          name='zip'
          className='sm:w-[30%] w-[40%] rounded-none border-0'
          placeholder='ZIP*'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.zip}
        />
      </div>
      <Input
        type='text'
        name='state'
        className='w-full rounded-none border-0'
        placeholder='State'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.state}
      />
    </div>
  );
};

const CommentSection = ({ styles, formik }) => {
  return (
    <div className={styles.section + ' border-b-0'}>
      <h2 className={styles.sectionTitle + ' mb-3'}>Comment</h2>
      <textarea
        placeholder='(max. 2000 chars)'
        rows='4'
        maxLength={2000}
        name='comment'
        className={styles.textArea}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.comment}
      />
    </div>
  );
};

import { directors } from '../../constants';

const Board = () => {
  return (
    <>
      <div className='w-full h-auto flex flex-col items-center justify-start bg-theme'>
        <div className='max-w-[90%] md:max-w-[1240px] mb-44'>
          <h1
            className='text-center mt-10  text-5xl md:text-7xl font-extrabold
            text-black'
          >
            Board
          </h1>
          {/*
          <p className='sub-heading-info md:mt-2 text-base md:text-lg text-center text-black font-normal'>
            Chris (CEO), Yannick (President), Shrivathsa (CTO),
          </p>
          */}
          <p className='sub-heading-info text-base md:text-lg mb-16 md:mb-12 mt-4 text-center text-black font-normal'>
            Chris (CEO), Yannick (President), Shrivathsa (CTO) are also directors.
          </p>
          <div className='members md:mb-24 flex-wrap justify-start grid grid-cols-1 md:grid-cols-2 gap-0'>
            {directors.map((director, i) => (
              <div
                key={i}
                className='flex flex-col items-center justify-start mb-12 md:mx-5 md:gap-4 md:py-5 py-4 md:px-0 px-4
 rounded-[28px] md:max-w-[350px] flex-shrink-0 bg-white/10'
                style={{}}
              >
                <div className='md:hidden flex gap-4 items-center justify-start w-full ml-8'>
                  <img
                    className='opacity-100 w-1/5'
                    src={director.image}
                    alt={director.name}
                  />
                  <div className='flex flex-col items-start'>
                    <p className='text-2xl md:text-4xl text-black font-bold'>
                      {director.name}
                    </p>
                    <p className='text-sm text-black font-bold md:pb-4 uppercase'>
                      {director.role}
                    </p>
                  </div>
                </div>
                <img
                  className='hidden md:block opacity-100 md:h-44 md:w-44 drop-shadow-md shadow-xl rounded-[105px]'
                  src={director.image}
                  alt={director.name}
                />
                <div className='md:px-6 px-2 flex flex-col justify-center'>
                  <div className='hidden md:flex flex-col md:flex-col items-center justify-between'>
                    <p className='text-2xl md:text-2xl text-black font-bold'>
                      {director.name}
                    </p>
                    <p className='text-base text-black font-extrabold uppercase tracking-wider'>
                      {director.role}
                    </p>
                  </div>
                  <p className='text-base md:text-lg text-black md:font-normal md:pt-4 pt-4'>
                    {director.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Board;

import { Button } from '../../../../components/ui';
import { FaPlus, FaTimes } from 'react-icons/fa';
import config from '../../../../config.json';
import { toast } from '../../../../components/ui/use-toast';
import { studioToast } from '../../../../components/toast';
import { MdDeleteOutline } from 'react-icons/md';

const EditModal = ({
  isModalOpen,
  comment,
  setComment,
  onClose,
  editFileData,
  setEditFileData,
  handleApproveOrder
}) => {
  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    const maxSize = config.maxFileSize * 1024 * 1024; // 99MB in bytes

    if (file && file.size > maxSize) {
      // toast({
      //   description: 'File size too large.'
      // });
      studioToast('File size too large.');
      e.target.value = null; // Clear the file input
    } else {
      const newFiles = [...editFileData];
      newFiles[index] = file;
      setEditFileData(newFiles);
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...editFileData];
    newFiles[index] = null;
    setEditFileData(newFiles);
    document.getElementById(`edit-file-input-${index}`).value = null;
  };

  const handleAddMore = () => {
    if (editFileData.length < 10) {
      setEditFileData([...editFileData, null]);
    }
  };

  const handleRemove = (index) => {
    const newFiles = [...editFileData];
    newFiles.splice(index, 1);
    setEditFileData(newFiles);
  };

  const styles = {
    sectionText:
      'font-normal text-base pb-3 text-tx-secondary flex flex-col items-start gap-2',
    fileInput:
      'flex items-center text-sm mr-4 h-8 px-6 rounded-lg cursor-pointer font-bold border-none bg-black hover:bg-black/80 text-white min-w-32 w-fit',
    filePreview:
      'flex-1 flex items-center gap-8 text-sm text-tx-secondary font-medium',
    textArea:
      'my-6 text-tx-secondary rounded-lg bg-white/20 text-base font-medium min-h-11 max-h-40 w-full px-4 py-2 outline-0 border-[1.5px] border-solid border-black/20 placeholder:text-sm placeholder:font-normal placeholder:text-tx-secondary/50'
  };

  return (
    <>
      {isModalOpen && (
        <div className='fixed inset-0 flex items-center justify-center z-50 bg-black/[0.5]'>
          <div className='bg-white p-8 sm:max-w-lg max-w-[92vw] overflow-auto w-full flex flex-col items-start rounded-md shadow-md animate-zoomIn'>
            <p className={styles.sectionText}>
              Supported file formats*:
              <div className='flex-1 flex flex-wrap gap-2'>
                <Button className='bg-black/20 text-black' variant='tag'>
                  .zip
                </Button>
                <Button className='bg-black/20 text-black' variant='tag'>
                  .jpeg
                </Button>
                <Button className='bg-black/20 text-black' variant='tag'>
                  .jpg
                </Button>
                <Button className='bg-black/20 text-black' variant='tag'>
                  .png
                </Button>
                <Button className='bg-black/20 text-black' variant='tag'>
                  .ply
                </Button>
                <Button className='bg-black/20 text-black' variant='tag'>
                  .obj
                </Button>
                <Button className='bg-black/20 text-black' variant='tag'>
                  .glb
                </Button>
                <Button className='bg-black/20 text-black' variant='tag'>
                  .gltf
                </Button>
                <Button className='bg-black/20 text-black' variant='tag'>
                  .stl
                </Button>
                <Button className='bg-black/20 text-black' variant='tag'>
                  .blend
                </Button>
                <Button className='bg-black/20 text-black' variant='tag'>
                  .mtl
                </Button>
              </div>
              <span className='text-xs'>(max size: 99mb)*</span>
            </p>
            <div className='max-h-[30vh]  overflow-auto w-full'>
              {editFileData.map((editFile, index) => (
                <div className='mb-3 flex sm:flex-row flex-col sm:items-center w-full sm:justify-between gap-2 sm:gap-0'>
                  <div>
                    <label
                      htmlFor={`edit-file-input-${index}`}
                      className={`${styles.fileInput}`}
                    >
                      {editFile ? 'Replace File' : 'Choose File'}
                    </label>
                    <input
                      id={`edit-file-input-${index}`}
                      type='file'
                      accept='.zip,.jpeg,.jpg,.png,.ply,.obj,.glb,.gltf,.stl,.blend,.mtl'
                      onChange={(e) => handleFileChange(e, index)}
                      className='hidden'
                    />
                  </div>
                  {editFile && (
                    <div className={styles.filePreview}>
                      <span>
                        {editFile.name.length > 16
                          ? `${editFile.name.slice(0, 16)}...`
                          : editFile.name}
                      </span>
                      <button onClick={() => handleRemoveFile(index)}>
                        <FaTimes />
                      </button>
                    </div>
                  )}
                  {editFileData.length > 1 && index !== 0 && (
                    <button onClick={() => handleRemove(index)}>
                      <MdDeleteOutline size={24} />
                    </button>
                  )}
                </div>
              ))}
            </div>

            {editFileData.length < 10 && (
              <Button
                variant='secondary'
                className='gap-2 h-7'
                onClick={handleAddMore}
              >
                <FaPlus /> Add More{' '}
              </Button>
            )}
            <textarea
              placeholder='Comments'
              rows='4'
              className={styles.textArea}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <div className='w-full flex items-center gap-3'>
              <Button
                disabled={comment.length === 0}
                onClick={() => handleApproveOrder(false)}
              >
                Send
              </Button>
              <Button
                variant='secondary'
                onClick={onClose}
                className='hover:bg-black/10'
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditModal;

import { IoClose } from 'react-icons/io5';
import React from 'react';

const ImagesModal = ({ selectedIndex, setSelectedIndex, imageURLs }) => {
  const styles = {
    closeSpan:
      'absolute text-tx-secondary right-2 top-3 transition-all hover:bg-black/10 rounded-md cursor-pointer',
    imageDiv:
      'w-14 h-14 cursor-pointer rounded-lg bg-transparent border border-solid flex items-center justify-center overflow-hidden p-1'
  };

  const onClose = () => {
    setSelectedIndex(null);
  };

  return (
    <>
      {selectedIndex !== null && (
        <div className='fixed inset-0 flex items-center justify-center z-50 bg-black/[0.5]'>
          <div className='bg-white p-8 sm:max-w-3xl max-w-[92vw] overflow-auto w-full flex flex-col sm:flex-row items-start rounded-md shadow-md animate-zoomIn relative'>
            <span onClick={onClose} className={styles.closeSpan}>
              <IoClose size={22} />
            </span>
            <div className='relative sm:w-2/3 w-full bg-transparent flex items-center justify-center before:content-[""] before:block before:pt-[100%]'>
              <img
                src={imageURLs[selectedIndex]}
                className='block w-full h-auto object-contain rounded-lg aspect-square'
                alt=''
              />
            </div>
            <div className='sm:w-1/3  sm:ps-4 mt-6 sm:mt-2 flex flex-wrap gap-4'>
              {imageURLs.map((url, index) => (
                <div
                  className={`${styles.imageDiv} ${
                    index === selectedIndex
                      ? ' border-btn-primary'
                      : 'border-transparent'
                  }`}
                  onClick={() => setSelectedIndex(index)}
                >
                  <img
                    src={url}
                    className='h-full w-full object-contain rounded-md'
                    alt=''
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImagesModal;

import React, { useEffect, useRef, useState } from 'react';
import Pagination from './Pagination';
import UpdateStatusModal from './UpdateStatusModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllOrders, getAllOrdersWithoutExpression } from '../../../services';
import config from '../../../config.json';
import { useUserContext } from '../../../hooks';
import SmallScreen from '../../../components/SmallScreen';
import Table from './Table';
import ProofModal from './ProofModal';
import ActivityModal from './ActivityModal';
import LoadingBar from 'react-top-loading-bar';
import AdminHeader from './AdminHeader';
import Papa from 'papaparse';
import { formatDate } from '../../../helpers';
import { toast } from '../../../components/ui/use-toast';
import { studioToast } from '../../../components/toast';
import LeftCol from '../../../components/LeftCol';

const Admin = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [authData, setAuthData] = useState();

  const [orderDetails, setOrderDetails] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);

  const [timeSortOrder, setTimeSortOrder] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    searchParams.get('page') === null ? 0 : Number(searchParams.get('page') - 1)
  );
  const [searchQuery, setSearchQuery] = useState(
    searchParams.get('name') === null ? '' : searchParams.get('name')
  );
  const [selectedOrderStatus, setSelectedOrderStatus] = useState([]);

  const [selectedToken, setSelectedToken] = useState();
  const [selectedProofOrder, setSelectedProofOrder] = useState();
  const [selectedActivityOrder, setSelectedActivityOrder] = useState();

  const [isOrderStatusShow, setIsOrderStatusShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const loaderRef = useRef(null);
  const { isMobile } = useUserContext();

  useEffect(() => {
    const authDetails = JSON.parse(localStorage.getItem('authData'));
    if (authDetails && !authDetails.admin) {
      navigate('/studio/view-orders');
    } else if (authDetails) {
      setAuthData(authDetails);
    } else {
      navigate('/studio/connect');
    }
  }, []);

  useEffect(() => {
    if (authData) {
      setIsLoading(true);
      loaderRef.current.continuousStart();
      getOrders();
    }
  }, [authData, currentPage, searchQuery, selectedOrderStatus, timeSortOrder]);

  const getOrders = async () => {
    try {
      const href = `/studio/admin?page=${currentPage + 1}${
        searchQuery ? `&name=${searchQuery}` : ''
      }`;
      navigate(href);
      const res = await getAllOrders(
        authData._id,
        currentPage + 1,
        searchQuery,
        config.itemsPerPage,
        'createdAt',
        selectedOrderStatus,
        timeSortOrder
      );
      if (res.status === 200 && typeof res.data === 'object') {
        setOrderDetails(res.data.Orders);
        setTotalOrders(res.data.page.totalDocuments);
      } else if (res.status === 200 && typeof res.data === 'string') {
        setOrderDetails([]);
        setTotalOrders(0);
      }
    } catch (error) {
      console.log('Error:--', error);
    }
    setIsLoading(false);
    loaderRef?.current?.complete();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0);
  };

  const handlePageClick = (selectedPage) => {
    if (
      selectedPage >= 0 &&
      selectedPage < Math.ceil(totalOrders / config.itemsPerPage)
    ) {
      setCurrentPage(selectedPage);
    }
  };

  const handleOrderStatusChange = (selectedOptions) => {
    setSelectedOrderStatus(selectedOptions);
    setIsOrderStatusShow(false);
    setCurrentPage(0);
  };

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAllOrdersWithoutExpression(authData._id);

      if (data?.Orders === null) {
        // toast({ description: 'No orders found.' });
        studioToast('No orders found.');
        return;
      }

      const itemsToExport = data.Orders.map((item) => ({
        'CREATED AT': formatDate(item.created_at),
        ID: item.id,
        'CUSTOMER NAME': item.shipping.name,
        'CUSTOMER EMAIL': item.shipping.email,
        ADDRESS: item.shipping.address
          ? item.shipping.address.street1 +
          ' ' +
          item.shipping.address.street2 +
          ', ' +
          item.shipping.address.city +
          ', ' +
          item.shipping.address.country +
          '-' +
          item.shipping.address.zip
          : '',
        'ORDER STATUS': item.payment_status,
        PRINTING:
          item.cost !== null &&
          item.cost?.print !== 0 &&
          item.cost?.print !== null
            ? `$${item.cost.print.toFixed(2)}`
            : '--',
        SHIPPING:
          item.cost !== null &&
          item.cost?.ship !== 0 &&
          item.cost?.ship !== null
            ? `$${item.cost.ship.toFixed(2)}`
            : '--',
        'PAYMENT METHOD':
          item.payment_method === 'COINBASE'
            ? 'CRYPTO'
            : item.payment_method === 'STRIPE'
            ? 'CREDIT CARD'
            : '--'
      }));

      const csvData = Papa.unparse(itemsToExport);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Orders${new Date().getTime()}.csv`; // Change the file extension to .csv
      a.click();

      setIsLoading(false);
    } catch (error) {
      console.error('Error exporting data:', error);
      setIsLoading(false);
      // toast({ description: 'Failed to export data' });
      studioToast('Failed to export data');
    }
  };

  const styles = {
    empty:
      'relative w-full h-72 flex items-center justify-center rounded-br-xl rounded-bl-xl border border-solid border-black/25 border-t-0'
  };

  const showLoader = isLoading;
  const currentItems = showLoader
    ? [...Array(config.itemsPerPage)]
    : orderDetails;

  return (
    <>
      <LoadingBar
        color={config.loaderBar}
        height={3}
        containerStyle={{ zIndex: 101 }}
        transitionTime={200}
        ref={loaderRef}
      />
      <main className='md:h-[calc(100vh-65px)] h-[calc(100vh-41px)] flex justify-start items-start bg-bg-secondary'>
        {!authData ? null : isMobile ? (
          <SmallScreen />
        ) : (
          <>
            <LeftCol />
            <div className='flex-1 h-[calc(100vh-65px)] flex flex-col overflow-y-auto'>
              <AdminHeader
                searchQuery={searchQuery}
                totalOrders={totalOrders}
                handleSearchChange={handleSearchChange}
                handleExport={handleExport}
              />
              <div className='bg-bg-secondary w-full h-auto overflow-auto'>
                <div className='relative flex flex-col w-[116%] justify-start px-8 py-4'>
                  <Table
                    currentItems={currentItems}
                    showLoader={showLoader}
                    setSelectedToken={setSelectedToken}
                    setSelectedProofOrder={setSelectedProofOrder}
                    isOrderStatusShow={isOrderStatusShow}
                    setIsOrderStatusShow={setIsOrderStatusShow}
                    handleOrderStatusChange={handleOrderStatusChange}
                    setSelectedActivityOrder={setSelectedActivityOrder}
                    timeSortOrder={timeSortOrder}
                    setTimeSortOrder={setTimeSortOrder}
                  />
                  {orderDetails.length === 0 && !isLoading && (
                    <div className={styles.empty}>
                      <p className='text-tx-secondary text-base'>No orders.</p>
                    </div>
                  )}
                </div>
              </div>
              {orderDetails.length !== 0 && (
                <div className='py-4'>
                  <Pagination
                    pageCount={Math.ceil(totalOrders / config.itemsPerPage)}
                    onPageChange={handlePageClick}
                    currentPage={currentPage}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </main>
      <ActivityModal
        selectedActivityOrder={selectedActivityOrder}
        onClose={() => setSelectedActivityOrder()}
      />

      <UpdateStatusModal
        selectedToken={selectedToken}
        setSelectedToken={setSelectedToken}
        setIsLoading={setIsLoading}
        loaderRef={loaderRef}
        getOrders={getOrders}
        authData={authData}
      />

      <ProofModal
        selectedProofOrder={selectedProofOrder}
        setSelectedProofOrder={setSelectedProofOrder}
        setIsLoading={setIsLoading}
        loaderRef={loaderRef}
        getOrders={getOrders}
        authData={authData}
      />
    </>
  );
};

export default Admin;

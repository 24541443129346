import React, { useEffect, useState } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import {
  wagmiGamesForButton,
  aKidForButton,
  goonsClubForButton
} from '../../assets/images';
import Loader from '../../components/loader/Loader';

const PrintYourNFT = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    const totalImages = images.length;

    let loadedImages = 0;

    const imageLoaded = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        setTimeout(() => {
          setIsLoading(false);
        }, 50);
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        imageLoaded();
      } else {
        image.addEventListener('load', imageLoaded);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', imageLoaded);
      });
    };
  }, []);

  const redirectToAKCB = () => {
    window.location.href = 'https://akcb.3dhoudini.com/';
  };
  const redirectToWagmi = () => {
    window.location.href = 'https://3dhoudini.wagmigamesapp.com/';
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='news-container animate-toastFade w-full flex items-center justify-center bg-theme'>
          <div className='inner-news relative xl:w-[1200px] w-full md:mx-4 xl:mx-0  grid justify-items-center mb-44 mt-16 md:mt-0'>
            <div className='flex md:flex-row flex-col justify-center md:space-x-6 md:pt-16 gap-8 md:gap-0 w-3/4 md:w-auto'>
              <div className='flex-1 rounded-lg overflow-hidden shadow-md bg-theme-sec border-2 border-solid border-white/20'>
                <Link
                  onClick={redirectToWagmi}
                  className='michroma-regular block w-full px-12 pt-12 pb-12 text-center text-xl font-medium text-indigo-900 hover:bg-white/10'
                >
                  <img
                    src={wagmiGamesForButton}
                    alt=''
                    className=' mb-16 rounded-3xl'
                  />
                  WAGMI Games
                </Link>
              </div>
              <div className='flex-1 rounded-lg overflow-hidden shadow-md bg-theme-sec border-2 border-solid border-white/20'>
                <Link
                  onClick={redirectToAKCB}
                  className='block w-full px-12 pt-12 pb-12 text-center text-xl text-indigo-900 hover:bg-white/10'
                >
                  <img
                    src={aKidForButton}
                    alt=''
                    className='mb-16 rounded-3xl'
                  />
                  a <span className='font-bold'>KID</span> called{' '}
                  <span className='font-bold'>BEAST</span>
                </Link>
              </div>
              <div className='relative flex-1 rounded-lg overflow-hidden shadow-md bg-theme-sec border-2 border-solid border-white/20'>
                <Link
                  onClick={redirectToAKCB}
                  className='block w-full px-12 pt-12 pb-12 font-bold text-center text-xl text-indigo-900 hover:bg-white/10'
                >
                  <img
                    src={goonsClubForButton}
                    alt=''
                    className='mb-16 rounded-3xl'
                  />
                  Goons: 3D
                </Link>
                <div className='absolute h-full w-full bg-black/10 top-0 left-0 z-10 text-xl md:text-2xl pb-12 text-black font-bold flex items-center justify-center'>
                  Coming soon
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrintYourNFT;

import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { person, date } from '../../assets/images';
import { mainNews } from '../../constants';
import Loader from '../../components/loader/Loader';

const News = () => {
  const scrollRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    const totalImages = images.length;

    let loadedImages = 0;

    const imageLoaded = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        setTimeout(() => {
          setIsLoading(false);
        }, 50);
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        imageLoaded();
      } else {
        image.addEventListener('load', imageLoaded);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', imageLoaded);
      });
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='news-container animate-toastFade w-full flex items-center justify-center bg-theme'>
          <div
            className='inner-news relative md:w-[1200px] grid justify-items-center mb-44 mt-16 md:mt-0'
            ref={scrollRef}
          >
            <h1 className='text-center md:mb-16 mb-8 md:my-20 text-5xl md:text-7xl text-black font-extrabold'>
              News
            </h1>
            {mainNews.map((news, i) => (
              <div
                id={`news-${i}`} // Add this line to set a unique ID for each news item
                key={i}
                className='news-card justify-center py-6 border-solid border-b-2 border-black/20
              md:pb-12 md:w-1/2 w-[90%] mb-12 '
              >
                <h6 className='mb-6 text-2xl md:text-3xl font-bold text-black'>
                  {news.headline}
                </h6>
                <div className='flex justify-center w-full mb-12 relative'>
                  <img
                    src={news.image}
                    alt='news-logo'
                    className='new-logo rounded-2xl border-solid border border-black/20 shadow-2xl md:w-full w-full'
                  />
                  <div
                    className='about-news absolute bottom-[-25px] left-0 md:left-[55px] w-2/3
                md:w-72 h-10 flex justify-around items-center rounded-md bg-slate-400'
                  >
                    <span className='about-news-details flex items-center justify-center gap-2 text-sm font-bold text-black/80'>
                      <img
                        src={person}
                        alt='person'
                        className='w-4 h-5 object-scale-down'
                      />{' '}
                      {news.reporter}
                    </span>
                    <span className='side-line-span h-4 w-0.5 bg-[#6345ed] opacity-10'></span>
                    <span className='about-news-details flex items-center justify-center gap-2  text-sm font-bold text-slate-600'>
                      <img
                        src={date}
                        alt='date'
                        className='w-4 h-5 object-scale-down'
                      />{' '}
                      {news.date}
                    </span>
                  </div>
                </div>
                {news.description.map((desc, index) => (
                  <React.Fragment key={index}>
                    {desc.type === 'points' ? (
                      <div className='pb-3 text-base md:text-lg text-black/80'>
                        {desc.values.map((point, id) => (
                          <p
                            dangerouslySetInnerHTML={{ __html: point }}
                            className='news-desc news-desc-points break-words text-base md:text-lg text-black/80'
                            key={id}
                          ></p>
                        ))}
                      </div>
                    ) : (
                      <p
                        className={
                          'news-desc pb-3 text-base md:text-lg text-black/80'
                        }
                        dangerouslySetInnerHTML={{ __html: desc.value }}
                      ></p>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default News;

import axios from 'axios';
import config from '../config.json';

export const createPaymentSession = async (
  address,
  order_id,
  payment_method
) => {
  try {
    const { data } = await axios.post(config.serverUrl + '/order/checkout', {
      order_id,
      account: address,
      payment_method
    });
    return data;
  } catch (error) {
    console.log('error in create payment session:--', error);
    return error;
  }
};

import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { allRoutes } from './constants';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/studio' || location.pathname === '/studio/') {
      navigate('/studio/connect');
    }

    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, [location]);

  return (
    <>
      <Header />
      <Routes>
        {allRoutes.map(({ Component, path }, i) => (
          <Route exact={true} path={path} key={i} element={<Component />} />
        ))}
        <Route path='*' exact={true} element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

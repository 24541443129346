import { Slide, toast } from 'react-toastify';

export const showToast = (type, message) => {
  toast[type](message, {
    icon: false,
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    closeButton: false,
    pauseOnHover: true,
    draggable: true,
    transition: Slide,
    theme: 'light',
    style: {
      background: 'rgb(49 46 129)',
      fontFamily: 'Rethink Sans',
      fontWeight: 400,
      fontSize: '16px',
      color: 'rgb(226 232 240)',
      paddingLeft: '24px',
      border: '1px solid rgb(49 46 1)'
    }
  });
};

export const studioToast = (message) => {
  toast(message, {
    icon: false,
    position: 'top-center',
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: false,
    closeButton: true,
    pauseOnHover: true,
    draggable: false,
    transition: Slide,
    theme: 'light',
    bodyClassName: 'studioToast',
    className: 'studioToastMain',
    style: {
      background: 'white',
      fontFamily: 'Rethink Sans',
      fontWeight: 400,
      fontSize: '16px',
      color: '#0e0e0e',
      borderRadius: '8px'
    }
  });
};

import React, { useEffect, useState } from 'react';
import LeftCol from '../../../../components/LeftCol';
import Checkout from './Checkout';
import { useUserContext } from '../../../../hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { getOrderId } from '../../../../helpers';

export default function Body() {
  const [isScreenShow, setIsScreenShow] = useState(false);
  const { setUrlOrderId } = useUserContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const orderId = getOrderId(pathname);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (!authData) {
      if (orderId) {
        setUrlOrderId(orderId);
      }
      navigate('/studio/connect');
    } else {
      setIsScreenShow(true);
    }
  }, []);

  return (
    <>
      <div className='flex md:h-[calc(100vh-65px)] h-[calc(100vh-41px)] w-full my-0 sm:flex-row flex-col justify-center items-center bg-bg-primary'>
        {isScreenShow ? (
          <>
            <LeftCol />
            <div className='md:flex-1 md:h-[calc(100vh-65px)] h-[calc(100vh-77px)] w-full overflow-y-auto bg-bg-primary'>
              <Checkout />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

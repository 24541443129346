import { aKidForButton } from '../assets/images';

const SmallScreen = () => {
  return (
    <>
      <div className='h-[calc(100vh-41px)] flex flex-col gap-8 w-full items-center justify-evenly bg-bg-secondary'>
        {/*<img src={aKidForButton} alt='' className='w-3/4 rounded-lg h-auto' />*/}
        <p className='text-center text-tx-secondary text-base mx-4'>
          Mobile browsers are not supported yet. Please use a desktop browser.
        </p>
      </div>
    </>
  );
};

export default SmallScreen;

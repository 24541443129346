import React, { useEffect, useState } from 'react';
import LeftCol from '../../../components/LeftCol';
import { useNavigate } from 'react-router-dom';
import { aboutStudio } from '../../../constants';

export default function Body() {
  const [isScreenShow, setIsScreenShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (!authData) {
      navigate('/studio/connect');
    } else {
      setIsScreenShow(true);
    }
  }, []);

  return (
    <>
      <div
        className='flex md:h-[calc(100vh-65px)] h-[calc(100vh-41px)] w-full my-0 sm:flex-row flex-col justify-center items-center bg-bg-primary'>
        {isScreenShow ? (
          <>
            <LeftCol />
            <div
              className='md:flex-1 md:h-[calc(100vh-65px)] h-[calc(100vh-77px)] w-full overflow-y-auto bg-bg-primary'>
              <div className='sm:w-[780px] w-full sm:ps-20 px-4 pb-16'>
                <h1 className='sticky bg-bg-primary z-10 top-0 sm:text-5xl text-2xl text-black font-bold py-6 sm:py-8 w-full'>
                  3D-Print and Design Studio
                </h1>
                {aboutStudio.map((data, i) => (
                  <React.Fragment key={i}>
                    {data?.title ? (
                      <h3 className='text-2xl  font-light pb-2 pt-4 text-black/80'>
                        {data.title}
                      </h3>
                    ) : null}
                    {data.description.map((desc, index) => (
                      <React.Fragment key={index}>
                        {desc.type === 'points' ? (
                          <div className='font-normal text-base pb-3 text-tx-secondary'>
                            {desc.values.map((point, id) => (
                              <p
                                className='news-desc-points font-normal text-base pb-1 text-tx-secondary'
                                key={id}
                              >
                                {point}
                              </p>
                            ))}
                          </div>
                        ) : (
                          <p className='font-normal text-base pb-3 text-tx-secondary'>
                            {desc.value}
                          </p>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

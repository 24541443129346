import { director1, director2, director3, director4, teamLeader4 } from '../assets/images';

export const directors = [
  {
    image: director1,
    name: 'Andy Billings',
    role: 'Director',
    description:
      'Former head of profitable creativity at Electronic Arts. Andy advises on game industry partnering and organizational readiness for growth.'
  },
  {
    image: director2,
    name: 'Tejwansh Anand',
    role: 'Director',
    description:
      'Clinical professor of practice and academic director for the MS in Information Systems programs at the Robert H. Smith School of Business.'
  },
  {
    image: teamLeader4,
    name: 'Rod Peterson',
    role: 'Director',
    description:
      'Rod has a deep understanding and years of experience working with all tranches of the balance sheet. Rod also serves as 3dHoudini’s fractional CFO'
  },
  {
    image: director4,
    name: 'Andy Keimach',
    role: 'Director',
    description:
      'Andy has been a global leader in the toy industry. He was President of Vtech N.A. and recently served as Interim President of the US Toy Association.'
  }
];

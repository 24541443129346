const NotFoundPage = () => {
  return (
    <>
      <div
        className='md:h-[calc(100vh-64px)] h-[calc(100vh-40px)] bg-theme relative w-full flex flex-col gap-1 justify-center items-center px-6 text-base text-center'>
        <p>We're sorry, but the page you are looking for cannot be found. It may
          have been moved, renamed, or deleted.</p>
        <p>Please check the URL for any typos, or return to the homepage.</p>
      </div>
    </>
  );
};

export default NotFoundPage;

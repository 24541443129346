import React from 'react';
import './index.css';

const LocalLoader = () => {
  return (
    <>
      <span className='line w60 m-0'></span>
    </>
  );
};

export default LocalLoader;

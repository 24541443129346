import config from '../config.json';
import axios from 'axios';

export const updateOrderStatus = async (address, order_id, shipping_status) => {
  try {
    const { data } = await axios.post(
      config.serverUrl + '/admin/orders/update',
      {
        account: {
          address: address
        },
        order_id: order_id,
        shipping_status: shipping_status
      }
    );
    return data;
  } catch (error) {
    console.log('error in updating order status:--', error);
  }
};

export const aboutStudio = [
  {
    description: [
      {
        type: 'text',
        value:
          'Let 3dHoudini bring your ideas to life! Our 3D-Print and Design Studio allows you to print and tokenize digital content. Whether you are starting with a polished CAD design, a 2D image, or a simple description, we can help you make it a reality.'
      }
    ]
  },
  {
    title: 'Printing',
    description: [
      {
        type: 'text',
        value:
          "We print high-quality parts in full color, including clear and skeleton clear (ask about it, it's very cool). With a 20″ x 20″ x 12″ print bed, nobody can print full-color bigger!"
      }
    ]
  },
  {
    title: 'Design',
    description: [
      {
        type: 'text',
        value:
          '3dHoudini has full CAD design capabilities. We can help you turn 2D ideas into 3D-printable files. We can also help you integrate your project seamlessly with the 3dHoudini ecosystem.'
      }
    ]
  },
  {
    title: 'Tokenization',
    description: [
      {
        type: 'text',
        value:
          'All 3dHoudini prints start as an NFT, which we call a Print Token. Then, after the physical product is printed, another NFT is created and linked to the physical product. This NFT is called a digital twin. The digital twin allows the physical prints to be traded on peer-to-peer marketplaces and contains a blockchain ledger which serves as a permanent record of its linked physical product’s provenance. The digital twin has the following value-adding features:'
      },
      {
        type: 'points',
        values: [
          '360° virtual rendering of the physical product.',
          'Smart contract-enabled escrow system that makes trading physical products more secure.',
          'Smart contract-enabled escrow system that makes trading physical products more secure.',
          'Smart contract-enabled tokenomics (i.e., only the first three owners of an NFT are allowed to purchase a physical print).',
          'Something that we call Child Tokens that enable IP owners to sell multiple, customizable copies of an NFT.'
        ]
      }
    ]
  },
  {
    title: 'Commerce and Marketing',
    description: [
      {
        type: 'text',
        value:
          'We can create a white-labeled landing page for your project that includes a payment gateway with crypto payment options.'
      }
    ]
  },
  {
    title: 'Additional Features',
    description: [
      {
        type: 'points',
        values: [
          'Sequential numbering to go on bases for limited editions (i.e., 0001/1000).',
          'QR codes (usually on the underside of a base) that link to any URL that can be used to verify the authenticity of collectibles on the blockchain ledger.'
        ]
      }
    ]
  }
];

export const formatDate = (timestamp) => {
  let date = new Date(timestamp);
  const monthShort = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  return `${monthShort} ${day}, ${hours}:${minutes}`;
};

import {
  news27Dec2023Small,
  news16Nov2023Small,
  news03Aug2023,
  news27Dec2023,
  news16Nov2023,
  news07Oct2023,
  news10Oct2023
} from '../assets/images';

export const footerNews = [
  {
    id: 'news-1', // Unique identifier for the first news item
    image: news27Dec2023Small,
    headline: '3dHoudini Powers Up With Funding,...',
    date: '27 Dec 2023'
  },
  {
    id: 'news-2', // Unique identifier for the first news item
    image: news16Nov2023Small,
    headline:
      "3dHoudini's Shrivathsa Ballal Inspires Next Generation of Technologists...",
    date: '16 Nov 2023'
  }
];

export const mainNews = [
  {
    id: 'news-1', // Unique identifier for the first news item
    image: news27Dec2023,
    reporter: 'Chris White',
    date: '27 Dec 2023',
    comments: 5,
    headline:
      '3dHoudini Powers Up With Funding, Acquires Mimaki 553 Printer, Gets Busy With WAGMI Defense!',
    description: [
      {
        type: 'text',
        value:
          "Buckle up, gamers! 3dHoudini has just hit the gas with a major funding boost and the acquisition of a Mimaki 3DUJ-553 full-color 3D printer. This ain't your grandpa's hobby printer – the 553 is the industry king, and it's now humming away in the heart of the Black Hills, South Dakota, ready to bring your digital dreams to life."
      },
      {
        type: 'text',
        value:
          "Now that we've closed our first round of financing, we have the fuel to ignite our ambitious vision. This means bigger things are coming fast. But this isn't just about firepower; it's about bringing digital dreams to physical reality. "
      },
      {
        type: 'text',
        value:
          "And how are we doing it? With Emperor Melek Taus, a pivotal figure in the WAGMI Games universe. We're proud to collaborate with WAGMI Games on the soft launch of its debut title, WAGMI Defense, by bringing the iconic Emperor Melek Taus to life! We’ve minted 1,000 limited-edition print tokens of the Emperor. These 3D-printable NFTs enable some lucky holders of WAGMI Games Founders Packs to hold a piece of gaming history in their hands!"
      },
      {
        type: 'text',
        value:
          "This is just the beginning, folks. 3dHoudini is paving the way for a future where the boundaries between the digital and physical worlds dissolve. We're bringing your favorite characters, games, and experiences to your doorstep, one pixel at a time."
      },
      {
        type: 'text',
        value: 'Stay tuned for more updates, and join us on this wild ride!'
      },
      {
        type: 'points',
        values: [
          "LinkedIn: <a target='_blank' href='https://www.linkedin.com/company/3dhoudini'> https://www.linkedin.com/company/3dhoudini</a>",
          "Twitter: <a target='_blank' href='https://twitter.com/3dhoudini'> https://twitter.com/3dhoudini</a>",
          "WAGMI Games: <a target='_blank' href='https://www.wagmigame.io'> https://www.wagmigame.io</a>"
        ]
      },
      {
        type: 'text',
        value: 'Let the games commence!'
      }
    ]
  },
  {
    id: 'news-2', // Unique identifier for the first news item
    image: news16Nov2023,
    reporter: 'Chris White',
    date: '16 Nov 2023',
    comments: 5,
    headline:
      "3dHoudini's Shrivathsa Ballal Inspires Next Generation of Technologists at UMD Smith School of Business",
    description: [
      {
        type: 'text',
        value:
          'We are thrilled to share that our co-founder and CTO, Shrivathsa Ballal, recently led a seminar for Master of Science in Information Systems (MSIS) students at the Robert H. Smith School of Business at the University of Maryland (UMD). This program, designed to facilitate direct interactions between students pursuing careers in Information Sciences and industry leaders, also featured Nestle, Wayfair, ServiceNow, and Marriott, business leaders.'
      },
      {
        type: 'text',
        value: `Shri's "Forward Momentum" seminar delved into his 25-year journey in the tech industry, providing insights into how his experiences have shaped his entry into emerging technology trends. He emphasized the importance of continuous learning, hard work, and intelligent work in uncovering one's X factor and building diverse, resilient, high-performing teams to solve complex problems.`
      },
      {
        type: 'text',
        value:
          "The student community's response to Shri's seminar has been overwhelmingly positive. They have asked him to return to the school for a follow-up question-answer session, and his presentation has left a lasting impression on their mindset."
      },
      {
        type: 'text',
        value:
          'Shri currently leads innovation and platform development at 3dHoudini. His entrepreneurial spirit and technological understanding are propelling 3dHoudini to the forefront of our industry. His commitment to innovation and expertise as CTO have resulted in significant technological breakthroughs.'
      },
      {
        type: 'text',
        value:
          "As we continue to harness excellence through our platform, we strive to deliver world-class, high-quality products using the world's best 3D printers in a marriage between blockchain technology and additive manufacturing. Through interactions with students and faculty at UMD Smith School of Business, we extend our mission to help students gain insights from leaders and entrepreneurs like Shri Ballal."
      },
      {
        type: 'text',
        highlight: true,
        value:
          '3dHoudini congratulates Shri for his contribution to the tech and business communities. It expresses gratitude to the UMD Smith School of Business for inviting our co-founder to share his valuable experiences and journey with MSIS students.'
      }
    ]
  },
  {
    id: 'news3', // Unique identifier for the first news item
    image: news10Oct2023,
    reporter: 'Chris White',
    date: '10 Oct 2023',
    comments: 5,
    headline:
      '3dHoudini Partners With WAGMI Games to Bring Emperor Melek Taus to Life 🎮',
    description: [
      {
        type: 'text',
        value:
          "We're excited to announce our new partnership with WAGMI (We're All Gonna Make It) Games. This pioneering Web3 transmedia entertainment franchise aims to achieve mass adoption by seamlessly integrating mobile gaming and immersive storytelling."
      },
      {
        type: 'text',
        value:
          "In this partnership, we'll be helping promote the soft launch of WAGMI Defense, WAGMI Games' debut title that's currently live on Google Play Beta and soon to launch publicly on all major app stores in select countries. WAGMI Defense is in the final beta phase before its worldwide launch."
      },
      {
        type: 'text',
        value:
          "Our role in the launch involves utilizing cutting-edge technologies to bring Emperor Melek Taus, an essential character in the WAGMI games universe, into the physical world. We'll be 3D printing limited-edition collectibles of the Emperor, which will be made available to lucky winners selected from purchasers of WAGMI Games Founders Packs, available on the OpenSea marketplace. Each Founder Pack contains 32 cards, and each card is a character used in the WAGMI Defense game."
      },
      {
        type: 'text',
        value:
          'The WAGMI Defense game is set in the year 3022 in a futuristic world filled with action and adventure. Whether you fight for or against Emperor Melek Taus in this epic power struggle, our 3D-printed collectibles will excite you and become valuable collectibles marking the first WAGMI Games reveal.'
      },
      {
        type: 'text',
        value:
          'WAGMI Defense is just the beginning for WAGMI Games. Its team is building a fully interoperable universe and integrating the Founders Cards into future WAGMI Games titles, as well as titles from different franchises. WAGMI Games is a shining example of ownership, utility, and interoperability. We are very excited to integrate Print Tokens in creative ways throughout the WAGMI Games universe.'
      },
      {
        type: 'text',
        value:
          "We're thrilled to be working with WAGMI Games to bring their vision to life and help them achieve their goal of mass adoption. For more information and to join the WAGMI Games community, please visit <a target='_blank' href='http://WAGMIGame.io'>WAGMIGame.io</a>, where you can learn more about the game, its characters, and the exciting world of WAGMI Games."
      },
      {
        type: 'text',
        value: 'WAGMI, friends! 🙂'
      }
    ]
  },
  {
    id: 'news4', // Unique identifier for the first news item
    image: news07Oct2023,
    reporter: 'Chris White',
    date: '07 Oct 2023',
    comments: 5,
    headline:
      '3dHoudini and 0xytocin Labs Partner Up to Bring 3D Printing to Web3 🎮 🎲',
    description: [
      {
        type: 'text',
        value:
          "We're excited to announce our new partnership with 0xytocin Labs, a Web3 studio that's building the future of decentralized technology. We’re both on a mission to bring Web3 to the entertainment and video gaming industries, making it easier than ever for fans to connect with their favorite brands and characters."
      },
      {
        type: 'text',
        value:
          "3dHoudini is a platform that democratizes the on-demand creation of collectibles worldwide. We're building on the Polygon Network to bring entertainment industry platforms, particularly video gaming platforms, to interface and integrate with our full-color 3D-printing ecosystem."
      },
      {
        type: 'text',
        value:
          '0xytocin Labs specializes in blockchain research and product development, creating secure and visually stunning solutions across the Web3 and DeFi landscapes. Its team has a proven track record of success, having helped well-known NFT brands such as Project Godjira and PXN with their dApp deployments.'
      },
      {
        type: 'text',
        value:
          "Together, we're already deeply engaged in product ideation, road mapping, POCs, and pilots to help architect the foundation layer of our platform. We plan to simultaneously launch multiple gaming and entertainment platform integrations over the next eight to 12 months."
      },
      {
        type: 'text',
        value:
          'This partnership will also hasten the evolution of 3dHoudini’s network of print centers to cater to the demands of platforms and players worldwide. Players will soon be able to physically get their hands on their favorite characters that have, until now, been confined to virtual environments.'
      },
      {
        type: 'text',
        value:
          "We're thrilled to be working with 0xytocin Labs, and we can't wait to see what the future holds. Stay tuned for more updates as we progress!"
      },
      {
        type: 'text',
        value:
          "To learn more about 0xytocin Labs, please visit <a target='_blank' href='https://lnkd.in/ghV-StQG'>https://lnkd.in/ghV-StQG</a>."
      }
    ]
  },
  {
    id: 'news5', // Unique identifier for the first news item
    image: news03Aug2023,
    reporter: 'Chris White',
    date: '03 Aug 2023',
    comments: 5,
    headline:
      'Things are heating up for 3dHoudini. We are pleased to announce two board members have joined us recently.',
    description: [
      {
        type: 'text',
        value:
          'Andy Billings has joined as an advisor and board member of 3dHoudini. Andy’s experience profile is a strong fit to 3dHoudini’s needs at this point in our development.'
      },
      {
        type: 'text',
        value: 'Andy’s rich experience includes:'
      },
      {
        type: 'points',
        values: [
          'Advising over 50 emergent companies as an independent advisor and as a member of the Singularity University Incubator program for multiple cycles.',
          'Serving as a mentor with Founders Institute and StartX.',
          'Gaming industry experience with 25 years as an Electronic Arts exec. Gaming will be our first market segment.',
          'Executive team development, including effective Management Operating Systems.',
          'Consulting experience with AI and high-tech companies, including 3D printing companies.'
        ]
      },
      {
        type: 'text',
        value:
          'Another member of our board who joined us recently is professor Tej Anand.'
      },
      {
        type: 'text',
        value: 'Tej Anand’s experience includes:'
      },
      {
        type: 'points',
        values: [
          'Serving as a clinical professor at the Robert H. Smith School of Business, University of Maryland College Park, with a summer teaching appointment at Columbia University.',
          'Decades of industry experience, including in AI and data science research and product development for the likes of Philips Research Laboratories, A. C. Nielsen, and NCR/Teradata, and as a senior business-technology executive for companies such as Golden Books, CA Technologies, Medco Health Solutions and CareCentrix.',
          'Working on blockchain since 2016. His current interests include blockchain governance, firm-wide analytics maturity, technology ethics, and traits of business leaders for successful technology adoption.'
        ]
      },
      {
        type: 'text',
        value:
          'Welcome to the team, Andy and Tej! We’re looking forward to exciting times ahead.'
      }
    ]
  }
];

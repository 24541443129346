import { Button, Input } from '../../../components/ui';
import { IoClose } from 'react-icons/io5';
import { FaPlus, FaTimes } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { approveOrderByAdmin } from '../../../services';
import { toast } from '../../../components/ui/use-toast';
import { studioToast } from '../../../components/toast';
import config from '../../../config.json';
import { MdDeleteOutline } from 'react-icons/md';

const ProofModal = ({
  selectedProofOrder,
  setSelectedProofOrder,
  authData,
  setIsLoading,
  loaderRef,
  getOrders
}) => {
  const [fileData, setFileData] = useState([null]);
  const [comment, setComment] = useState('');
  const [printPrice, setPrintPrice] = useState('');
  const [shippingPrice, setShippingPrice] = useState('');

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    const newFiles = [...fileData];
    newFiles[index] = file;
    setFileData(newFiles);
  };

  const handleAddMore = () => {
    if (fileData.length < 10) {
      setFileData([...fileData, null]);
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...fileData];
    newFiles[index] = null;
    setFileData(newFiles);
    document.getElementById(`admin-file-input-${index}`).value = null;
  };

  const handleRemove = (index) => {
    const newFiles = [...fileData];
    newFiles.splice(index, 1);
    setFileData(newFiles);
  };

  useEffect(() => {
    if (selectedProofOrder && selectedProofOrder.cost !== null) {
      setPrintPrice(
        String(
          selectedProofOrder.cost.print === 0
            ? ''
            : selectedProofOrder.cost.print
        )
      );
      setShippingPrice(
        String(
          selectedProofOrder.cost.ship === 0 ? '' : selectedProofOrder.cost.ship
        )
      );
    }
  }, [selectedProofOrder]);

  const handleUploadProof = async (order) => {
    if (!authData) return;

    const filteredFileData = fileData.filter((item) => item !== null);

    if (
      filteredFileData.length &&
      (shippingPrice.length === 0 || printPrice.length === 0)
    ) {
      studioToast('Please fill in the price fields.');
      return;
    }

    setSelectedProofOrder();
    loaderRef.current.continuousStart();
    setIsLoading(true);
    const formData = new FormData();

    const cost = {
      price: Number(printPrice),
      shipping: Number(shippingPrice)
    };

    if (filteredFileData.length) {
      filteredFileData.forEach((file) => {
        formData.append('attachment', file); // 'files' is the key to which all files will be appended
      });
    }

    formData.append('account', authData._id);
    formData.append('order_id', order._id);
    formData.append('remark', comment);
    formData.append('checkout', 'false');
    formData.append('cost', JSON.stringify(cost));
    formData.append(
      'timestamp',
      String(order.activity[order.activity.length - 1].timestamp)
    );

    try {
      const res = await approveOrderByAdmin(formData);
      if (res.status === 200) {
        // toast({ description: 'Proof uploaded and sent.' });
        studioToast('Proof uploaded and sent.');
      } else {
        // toast({ description: 'Something went wrong.' });
        studioToast('Something went wrong.');
      }
      getOrders();
    } catch (error) {
      console.log('Error:--', error);
      // toast({ description: "Something went wrong." });
      studioToast('Something went wrong.');
      setIsLoading(false);
      loaderRef?.current?.complete();
    }
    handleClose();
  };

  const handleClose = () => {
    setFileData([null]);
    setPrintPrice('');
    setComment('');
    setShippingPrice('');
    setSelectedProofOrder();
  };

  const styles = {
    attachmentText: 'font-normal text-sm text-tx-secondary mb-3',
    closeSpan:
      'absolute text-tx-secondary right-2 top-3 transition-all hover:bg-black/10 rounded-md cursor-pointer',
    fileInput:
      'flex items-center text-sm mr-4 h-8 px-6 rounded-lg cursor-pointer border-none bg-black hover:bg-black/80 text-white',
    filePreview: 'flex-1 flex items-center gap-8 text-tx-secondary',
    fileName: 'text-tx-secondary text-sm',
    textArea:
      'my-6 text-tx-secondary rounded-lg bg-white/20 text-base font-medium min-h-11 max-h-40 w-full px-4 py-2 outline-0 border-[1.5px] border-solid border-black/20 placeholder:text-sm placeholder:font-normal placeholder:text-tx-secondary/50'
  };

  return (
    <>
      {selectedProofOrder && (
        <div className='fixed inset-0 flex items-center justify-center z-50 bg-black/[0.5]'>
          <div className='bg-white p-8 max-w-xl w-full flex flex-col items-start rounded-md shadow-md animate-zoomIn relative'>
            <span onClick={handleClose} className={styles.closeSpan}>
              <IoClose size={22} />
            </span>
            <div className='flex flex-col items-start w-full gap-1'>
              <p className={styles.attachmentText}>Attachment</p>
              <div className='max-h-[30vh]  overflow-auto w-full'>
                {fileData.map((inputFile, index) => (
                  <div className='flex w-full justify-between  items-start items-center mb-3'>
                    <div>
                      <label
                        htmlFor={`admin-file-input-${index}`}
                        className={styles.fileInput}
                      >
                        {inputFile ? 'Replace File' : 'Choose File'}
                      </label>
                      <input
                        id={`admin-file-input-${index}`}
                        type='file'
                        accept='.jpg,.jpeg,.png'
                        onChange={(e) => handleFileChange(e, index)}
                        className='hidden'
                      />
                    </div>
                    {inputFile && (
                      <div className={styles.filePreview}>
                        <span className={styles.fileName}>
                          {inputFile.name.length > 16
                            ? `${inputFile.name.slice(0, 16)}...`
                            : inputFile.name}
                        </span>
                        <button onClick={() => handleRemoveFile(index)}>
                          <FaTimes size={12} />
                        </button>
                      </div>
                    )}
                    {fileData.length > 1 && index !== 0 && (
                      <button onClick={() => handleRemove(index)}>
                        <MdDeleteOutline size={24} />
                      </button>
                    )}
                  </div>
                ))}
              </div>
              {fileData.length < 10 && (
                <Button
                  variant='secondary'
                  className='gap-2 h-7'
                  onClick={handleAddMore}
                >
                  <FaPlus /> Add More{' '}
                </Button>
              )}
            </div>

            <div className='flex justify-between items-center mt-4 w-full'>
              <Input
                value={printPrice}
                className='w-[48%]'
                placeholder='Price (USD)'
                onChange={(e) => setPrintPrice(e.target.value)}
              />
              <Input
                value={shippingPrice}
                className='w-[48%]'
                placeholder='Shipping (USD)'
                onChange={(e) => setShippingPrice(e.target.value)}
              />
            </div>
            <textarea
              placeholder='Comments (max. 2000 chars)'
              rows='4'
              className={styles.textArea}
              maxLength={2000}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button
              disabled={!comment.length}
              onClick={() => handleUploadProof(selectedProofOrder)}
            >
              Upload
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ProofModal;

import React from 'react';
import './index.scss';
import {
  mimakiLarge,
  printDemo1,
  printDemo2,
  printDemo3
} from '../../assets/images';
import { printerBenefits } from '../../constants';

const demoImages = [printDemo1, printDemo2, printDemo3];

const Printer = () => {
  return (
    <>
      <div className='printer-container w-full bg-[#030303] flex justify-start flex-col items-center'>
        <div className='mimaki-main-div pb-4 mt-16 md:mt-28 flex justify-center items-start'>
          <img
            src={mimakiLarge}
            alt='mimaki'
            className='mimaki w-56 md:w-auto'
          />
        </div>
        <div className='printer-div flex flex-col justify-start items-center w-full relative bg-center bg-no-repeat	bg-cover'>
          <h6 className='text-3xl md:text-5xl font-bold text-slate-200'>
            3DUJ-553
          </h6>
          <p className='mt-2 md:mt-16 text-center text-xl md:text-2xl font-normal text-slate-200'>
            The Pinnacle of Full-Color 3D Printing
          </p>
        </div>
        <div className='inner-printer w-[90%] md:w-[1280px] z-10 mt-[-100px] md:mt-[-220px]'>
          <h2 className='text-center mb-8 md:mb-12 text-3xl md:text-5xl font-bold text-slate-200'>
            Built for Productivity{' '}
          </h2>
          <p
            className='md:text-center mb-8 md:mx-16 md:px-44 md:leading-normal 
          text-base md:text-xl font-normal text-slate-200 text-balance'
          >
            The first of its kind, the 3DUJ-553 color 3D printer has over 10
            million colors and saves time and money by eliminating finishing and
            producing flawless small-detailed parts.
          </p>
          <p className='md:text-center mb-16 md:mx-16 md:px-44 md:leading-normal text-base md:text-xl font-normal text-slate-200'>
            High print speeds enable lower-cost prototyping completed within
            hours. This multi-color 3D printer yields fine details, smooth
            surfaces, and more efficient material use. It also improves workflow
            for designers in industrial and other professional segments.
          </p>
          <div className='printer-benefits flex justify-center mb-4 md:mb-32 md:px-28'>
            {
              <div className='benefits-part'>
                {printerBenefits.map((benefit, i) => (
                  <p
                    className='benefit-points text-base md:text-xl font-normal text-slate-200 mb-4'
                    key={i}
                  >
                    {benefit}
                  </p>
                ))}
              </div>
            }
          </div>
          <div className='flex justify-center mt-12 md:mt-32 mb-32 md:mb-56 gap-12'>
            {demoImages.map((image, i) => (
              <img
                src={image}
                key={i}
                alt='print-demo'
                className='print-demo w-24 md:w-1/5'
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Printer;

export const countryCodeList = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas ',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia, Plurinational State of',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands ',
  CF: 'Central African Republic ',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands ',
  CO: 'Colombia',
  KM: 'Comoros ',
  CD: 'Congo, The Democratic Republic',
  CG: 'Congo ',
  CK: 'Cook Islands ',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic ',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands, Malvinas',
  FO: 'Faroe Islands ',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories ',
  GA: 'Gabon',
  GM: 'Gambia ',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See ',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea, The Democratic People's Republic of)",
  KR: 'Korea, The Republic of',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic ",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands ',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands ',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger ',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands ',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines ',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation ',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan ',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands ',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates ',
  GB: 'United Kingdom of Great Britain and Northern Ireland ',
  UM: 'United States Minor Outlying Islands ',
  US: 'United States of America ',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viet Nam',
  VG: 'British Virgin Islands',
  VI: 'United States Virgin Islands',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands'
};

export const countries = [
  {
    label: 'Afghanistan',
    value: 'afghanistan'
  },
  {
    label: 'Albania',
    value: 'albania'
  },
  {
    label: 'Algeria',
    value: 'algeria'
  },
  {
    label: 'American Samoa',
    value: 'american samoa'
  },
  {
    label: 'Andorra',
    value: 'andorra'
  },
  {
    label: 'Angola',
    value: 'angola'
  },
  {
    label: 'Anguilla',
    value: 'anguilla'
  },
  {
    label: 'Antarctica',
    value: 'antarctica'
  },
  {
    label: 'Antigua and Barbuda',
    value: 'antigua and barbuda'
  },
  {
    label: 'Argentina',
    value: 'argentina'
  },
  {
    label: 'Armenia',
    value: 'armenia'
  },
  {
    label: 'Aruba',
    value: 'aruba'
  },
  {
    label: 'Australia',
    value: 'australia'
  },
  {
    label: 'Austria',
    value: 'austria'
  },
  {
    label: 'Azerbaijan',
    value: 'azerbaijan'
  },
  {
    label: 'Bahamas ',
    value: 'bahamas '
  },
  {
    label: 'Bahrain',
    value: 'bahrain'
  },
  {
    label: 'Bangladesh',
    value: 'bangladesh'
  },
  {
    label: 'Barbados',
    value: 'barbados'
  },
  {
    label: 'Belarus',
    value: 'belarus'
  },
  {
    label: 'Belgium',
    value: 'belgium'
  },
  {
    label: 'Belize',
    value: 'belize'
  },
  {
    label: 'Benin',
    value: 'benin'
  },
  {
    label: 'Bermuda',
    value: 'bermuda'
  },
  {
    label: 'Bhutan',
    value: 'bhutan'
  },
  {
    label: 'Bolivia, Plurinational State of',
    value: 'bolivia, plurinational state of'
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'bonaire, sint eustatius and saba'
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'bosnia and herzegovina'
  },
  {
    label: 'Botswana',
    value: 'botswana'
  },
  {
    label: 'Bouvet Island',
    value: 'bouvet island'
  },
  {
    label: 'Brazil',
    value: 'brazil'
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'british indian ocean territory'
  },
  {
    label: 'Brunei Darussalam',
    value: 'brunei darussalam'
  },
  {
    label: 'Bulgaria',
    value: 'bulgaria'
  },
  {
    label: 'Burkina Faso',
    value: 'burkina faso'
  },
  {
    label: 'Burundi',
    value: 'burundi'
  },
  {
    label: 'Cabo Verde',
    value: 'cabo verde'
  },
  {
    label: 'Cambodia',
    value: 'cambodia'
  },
  {
    label: 'Cameroon',
    value: 'cameroon'
  },
  {
    label: 'Canada',
    value: 'canada'
  },
  {
    label: 'Cayman Islands ',
    value: 'cayman islands '
  },
  {
    label: 'Central African Republic ',
    value: 'central african republic '
  },
  {
    label: 'Chad',
    value: 'chad'
  },
  {
    label: 'Chile',
    value: 'chile'
  },
  {
    label: 'China',
    value: 'china'
  },
  {
    label: 'Christmas Island',
    value: 'christmas island'
  },
  {
    label: 'Cocos (Keeling) Islands ',
    value: 'cocos (keeling) islands '
  },
  {
    label: 'Colombia',
    value: 'colombia'
  },
  {
    label: 'Comoros ',
    value: 'comoros '
  },
  {
    label: 'Congo, The Democratic Republic',
    value: 'congo, the democratic republic'
  },
  {
    label: 'Congo ',
    value: 'congo '
  },
  {
    label: 'Cook Islands ',
    value: 'cook islands '
  },
  {
    label: 'Costa Rica',
    value: 'costa rica'
  },
  {
    label: 'Croatia',
    value: 'croatia'
  },
  {
    label: 'Cuba',
    value: 'cuba'
  },
  {
    label: 'Curaçao',
    value: 'curaçao'
  },
  {
    label: 'Cyprus',
    value: 'cyprus'
  },
  {
    label: 'Czechia',
    value: 'czechia'
  },
  {
    label: "Côte d'Ivoire",
    value: "côte d'ivoire"
  },
  {
    label: 'Denmark',
    value: 'denmark'
  },
  {
    label: 'Djibouti',
    value: 'djibouti'
  },
  {
    label: 'Dominica',
    value: 'dominica'
  },
  {
    label: 'Dominican Republic ',
    value: 'dominican republic '
  },
  {
    label: 'Ecuador',
    value: 'ecuador'
  },
  {
    label: 'Egypt',
    value: 'egypt'
  },
  {
    label: 'El Salvador',
    value: 'el salvador'
  },
  {
    label: 'Equatorial Guinea',
    value: 'equatorial guinea'
  },
  {
    label: 'Eritrea',
    value: 'eritrea'
  },
  {
    label: 'Estonia',
    value: 'estonia'
  },
  {
    label: 'Eswatini',
    value: 'eswatini'
  },
  {
    label: 'Ethiopia',
    value: 'ethiopia'
  },
  {
    label: 'Falkland Islands, Malvinas',
    value: 'falkland islands, malvinas'
  },
  {
    label: 'Faroe Islands ',
    value: 'faroe islands '
  },
  {
    label: 'Fiji',
    value: 'fiji'
  },
  {
    label: 'Finland',
    value: 'finland'
  },
  {
    label: 'France',
    value: 'france'
  },
  {
    label: 'French Guiana',
    value: 'french guiana'
  },
  {
    label: 'French Polynesia',
    value: 'french polynesia'
  },
  {
    label: 'French Southern Territories ',
    value: 'french southern territories '
  },
  {
    label: 'Gabon',
    value: 'gabon'
  },
  {
    label: 'Gambia ',
    value: 'gambia '
  },
  {
    label: 'Georgia',
    value: 'georgia'
  },
  {
    label: 'Germany',
    value: 'germany'
  },
  {
    label: 'Ghana',
    value: 'ghana'
  },
  {
    label: 'Gibraltar',
    value: 'gibraltar'
  },
  {
    label: 'Greece',
    value: 'greece'
  },
  {
    label: 'Greenland',
    value: 'greenland'
  },
  {
    label: 'Grenada',
    value: 'grenada'
  },
  {
    label: 'Guadeloupe',
    value: 'guadeloupe'
  },
  {
    label: 'Guam',
    value: 'guam'
  },
  {
    label: 'Guatemala',
    value: 'guatemala'
  },
  {
    label: 'Guernsey',
    value: 'guernsey'
  },
  {
    label: 'Guinea',
    value: 'guinea'
  },
  {
    label: 'Guinea-Bissau',
    value: 'guinea-bissau'
  },
  {
    label: 'Guyana',
    value: 'guyana'
  },
  {
    label: 'Haiti',
    value: 'haiti'
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'heard island and mcdonald islands'
  },
  {
    label: 'Holy See ',
    value: 'holy see '
  },
  {
    label: 'Honduras',
    value: 'honduras'
  },
  {
    label: 'Hong Kong',
    value: 'hong kong'
  },
  {
    label: 'Hungary',
    value: 'hungary'
  },
  {
    label: 'Iceland',
    value: 'iceland'
  },
  {
    label: 'India',
    value: 'india'
  },
  {
    label: 'Indonesia',
    value: 'indonesia'
  },
  {
    label: 'Iran',
    value: 'iran'
  },
  {
    label: 'Iraq',
    value: 'iraq'
  },
  {
    label: 'Ireland',
    value: 'ireland'
  },
  {
    label: 'Isle of Man',
    value: 'isle of man'
  },
  {
    label: 'Israel',
    value: 'israel'
  },
  {
    label: 'Italy',
    value: 'italy'
  },
  {
    label: 'Jamaica',
    value: 'jamaica'
  },
  {
    label: 'Japan',
    value: 'japan'
  },
  {
    label: 'Jersey',
    value: 'jersey'
  },
  {
    label: 'Jordan',
    value: 'jordan'
  },
  {
    label: 'Kazakhstan',
    value: 'kazakhstan'
  },
  {
    label: 'Kenya',
    value: 'kenya'
  },
  {
    label: 'Kiribati',
    value: 'kiribati'
  },
  {
    label: "Korea, The Democratic People's Republic of)",
    value: "korea, the democratic people's republic of)"
  },
  {
    label: 'Korea, The Republic of',
    value: 'korea, the republic of'
  },
  {
    label: 'Kuwait',
    value: 'kuwait'
  },
  {
    label: 'Kyrgyzstan',
    value: 'kyrgyzstan'
  },
  {
    label: "Lao People's Democratic Republic ",
    value: "lao people's democratic republic "
  },
  {
    label: 'Latvia',
    value: 'latvia'
  },
  {
    label: 'Lebanon',
    value: 'lebanon'
  },
  {
    label: 'Lesotho',
    value: 'lesotho'
  },
  {
    label: 'Liberia',
    value: 'liberia'
  },
  {
    label: 'Libya',
    value: 'libya'
  },
  {
    label: 'Liechtenstein',
    value: 'liechtenstein'
  },
  {
    label: 'Lithuania',
    value: 'lithuania'
  },
  {
    label: 'Luxembourg',
    value: 'luxembourg'
  },
  {
    label: 'Macao',
    value: 'macao'
  },
  {
    label: 'Madagascar',
    value: 'madagascar'
  },
  {
    label: 'Malawi',
    value: 'malawi'
  },
  {
    label: 'Malaysia',
    value: 'malaysia'
  },
  {
    label: 'Maldives',
    value: 'maldives'
  },
  {
    label: 'Mali',
    value: 'mali'
  },
  {
    label: 'Malta',
    value: 'malta'
  },
  {
    label: 'Marshall Islands ',
    value: 'marshall islands '
  },
  {
    label: 'Martinique',
    value: 'martinique'
  },
  {
    label: 'Mauritania',
    value: 'mauritania'
  },
  {
    label: 'Mauritius',
    value: 'mauritius'
  },
  {
    label: 'Mayotte',
    value: 'mayotte'
  },
  {
    label: 'Mexico',
    value: 'mexico'
  },
  {
    label: 'Micronesia',
    value: 'micronesia'
  },
  {
    label: 'Moldova',
    value: 'moldova'
  },
  {
    label: 'Monaco',
    value: 'monaco'
  },
  {
    label: 'Mongolia',
    value: 'mongolia'
  },
  {
    label: 'Montenegro',
    value: 'montenegro'
  },
  {
    label: 'Montserrat',
    value: 'montserrat'
  },
  {
    label: 'Morocco',
    value: 'morocco'
  },
  {
    label: 'Mozambique',
    value: 'mozambique'
  },
  {
    label: 'Myanmar',
    value: 'myanmar'
  },
  {
    label: 'Namibia',
    value: 'namibia'
  },
  {
    label: 'Nauru',
    value: 'nauru'
  },
  {
    label: 'Nepal',
    value: 'nepal'
  },
  {
    label: 'Netherlands ',
    value: 'netherlands '
  },
  {
    label: 'New Caledonia',
    value: 'new caledonia'
  },
  {
    label: 'New Zealand',
    value: 'new zealand'
  },
  {
    label: 'Nicaragua',
    value: 'nicaragua'
  },
  {
    label: 'Niger ',
    value: 'niger '
  },
  {
    label: 'Nigeria',
    value: 'nigeria'
  },
  {
    label: 'Niue',
    value: 'niue'
  },
  {
    label: 'Norfolk Island',
    value: 'norfolk island'
  },
  {
    label: 'Northern Mariana Islands ',
    value: 'northern mariana islands '
  },
  {
    label: 'Norway',
    value: 'norway'
  },
  {
    label: 'Oman',
    value: 'oman'
  },
  {
    label: 'Pakistan',
    value: 'pakistan'
  },
  {
    label: 'Palau',
    value: 'palau'
  },
  {
    label: 'Palestine, State of',
    value: 'palestine, state of'
  },
  {
    label: 'Panama',
    value: 'panama'
  },
  {
    label: 'Papua New Guinea',
    value: 'papua new guinea'
  },
  {
    label: 'Paraguay',
    value: 'paraguay'
  },
  {
    label: 'Peru',
    value: 'peru'
  },
  {
    label: 'Philippines ',
    value: 'philippines '
  },
  {
    label: 'Pitcairn',
    value: 'pitcairn'
  },
  {
    label: 'Poland',
    value: 'poland'
  },
  {
    label: 'Portugal',
    value: 'portugal'
  },
  {
    label: 'Puerto Rico',
    value: 'puerto rico'
  },
  {
    label: 'Qatar',
    value: 'qatar'
  },
  {
    label: 'Republic of North Macedonia',
    value: 'republic of north macedonia'
  },
  {
    label: 'Romania',
    value: 'romania'
  },
  {
    label: 'Russian Federation ',
    value: 'russian federation '
  },
  {
    label: 'Rwanda',
    value: 'rwanda'
  },
  {
    label: 'Réunion',
    value: 'réunion'
  },
  {
    label: 'Saint Barthélemy',
    value: 'saint barthélemy'
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'saint helena, ascension and tristan da cunha'
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'saint kitts and nevis'
  },
  {
    label: 'Saint Lucia',
    value: 'saint lucia'
  },
  {
    label: 'Saint Martin',
    value: 'saint martin'
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'saint pierre and miquelon'
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'saint vincent and the grenadines'
  },
  {
    label: 'Samoa',
    value: 'samoa'
  },
  {
    label: 'San Marino',
    value: 'san marino'
  },
  {
    label: 'Sao Tome and Principe',
    value: 'sao tome and principe'
  },
  {
    label: 'Saudi Arabia',
    value: 'saudi arabia'
  },
  {
    label: 'Senegal',
    value: 'senegal'
  },
  {
    label: 'Serbia',
    value: 'serbia'
  },
  {
    label: 'Seychelles',
    value: 'seychelles'
  },
  {
    label: 'Sierra Leone',
    value: 'sierra leone'
  },
  {
    label: 'Singapore',
    value: 'singapore'
  },
  {
    label: 'Sint Maarten',
    value: 'sint maarten'
  },
  {
    label: 'Slovakia',
    value: 'slovakia'
  },
  {
    label: 'Slovenia',
    value: 'slovenia'
  },
  {
    label: 'Solomon Islands',
    value: 'solomon islands'
  },
  {
    label: 'Somalia',
    value: 'somalia'
  },
  {
    label: 'South Africa',
    value: 'south africa'
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'south georgia and the south sandwich islands'
  },
  {
    label: 'South Sudan',
    value: 'south sudan'
  },
  {
    label: 'Spain',
    value: 'spain'
  },
  {
    label: 'Sri Lanka',
    value: 'sri lanka'
  },
  {
    label: 'Sudan ',
    value: 'sudan '
  },
  {
    label: 'Suriname',
    value: 'suriname'
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'svalbard and jan mayen'
  },
  {
    label: 'Sweden',
    value: 'sweden'
  },
  {
    label: 'Switzerland',
    value: 'switzerland'
  },
  {
    label: 'Syrian Arab Republic',
    value: 'syrian arab republic'
  },
  {
    label: 'Taiwan',
    value: 'taiwan'
  },
  {
    label: 'Tajikistan',
    value: 'tajikistan'
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'tanzania, united republic of'
  },
  {
    label: 'Thailand',
    value: 'thailand'
  },
  {
    label: 'Timor-Leste',
    value: 'timor-leste'
  },
  {
    label: 'Togo',
    value: 'togo'
  },
  {
    label: 'Tokelau',
    value: 'tokelau'
  },
  {
    label: 'Tonga',
    value: 'tonga'
  },
  {
    label: 'Trinidad and Tobago',
    value: 'trinidad and tobago'
  },
  {
    label: 'Tunisia',
    value: 'tunisia'
  },
  {
    label: 'Turkey',
    value: 'turkey'
  },
  {
    label: 'Turkmenistan',
    value: 'turkmenistan'
  },
  {
    label: 'Turks and Caicos Islands ',
    value: 'turks and caicos islands '
  },
  {
    label: 'Tuvalu',
    value: 'tuvalu'
  },
  {
    label: 'Uganda',
    value: 'uganda'
  },
  {
    label: 'Ukraine',
    value: 'ukraine'
  },
  {
    label: 'United Arab Emirates ',
    value: 'united arab emirates '
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland ',
    value: 'united kingdom of great britain and northern ireland '
  },
  {
    label: 'United States Minor Outlying Islands ',
    value: 'united states minor outlying islands '
  },
  {
    label: 'United States of America ',
    value: 'united states of america '
  },
  {
    label: 'Uruguay',
    value: 'uruguay'
  },
  {
    label: 'Uzbekistan',
    value: 'uzbekistan'
  },
  {
    label: 'Vanuatu',
    value: 'vanuatu'
  },
  {
    label: 'Venezuela',
    value: 'venezuela'
  },
  {
    label: 'Viet Nam',
    value: 'viet nam'
  },
  {
    label: 'British Virgin Islands',
    value: 'british virgin islands'
  },
  {
    label: 'United States Virgin Islands',
    value: 'united states virgin islands'
  },
  {
    label: 'Wallis and Futuna',
    value: 'wallis and futuna'
  },
  {
    label: 'Western Sahara',
    value: 'western sahara'
  },
  {
    label: 'Yemen',
    value: 'yemen'
  },
  {
    label: 'Zambia',
    value: 'zambia'
  },
  {
    label: 'Zimbabwe',
    value: 'zimbabwe'
  },
  {
    label: 'Åland Islands',
    value: 'åland islands'
  }
];

export const countryWithCode = [
  {
    code: 'AF',
    value: 'afghanistan'
  },
  {
    code: 'AL',
    value: 'albania'
  },
  {
    code: 'DZ',
    value: 'algeria'
  },
  {
    code: 'AS',
    value: 'american samoa'
  },
  {
    code: 'AD',
    value: 'andorra'
  },
  {
    code: 'AO',
    value: 'angola'
  },
  {
    code: 'AI',
    value: 'anguilla'
  },
  {
    code: 'AQ',
    value: 'antarctica'
  },
  {
    code: 'AG',
    value: 'antigua and barbuda'
  },
  {
    code: 'AR',
    value: 'argentina'
  },
  {
    code: 'AM',
    value: 'armenia'
  },
  {
    code: 'AW',
    value: 'aruba'
  },
  {
    code: 'AU',
    value: 'australia'
  },
  {
    code: 'AT',
    value: 'austria'
  },
  {
    code: 'AZ',
    value: 'azerbaijan'
  },
  {
    code: 'BS',
    value: 'bahamas '
  },
  {
    code: 'BH',
    value: 'bahrain'
  },
  {
    code: 'BD',
    value: 'bangladesh'
  },
  {
    code: 'BB',
    value: 'barbados'
  },
  {
    code: 'BY',
    value: 'belarus'
  },
  {
    code: 'BE',
    value: 'belgium'
  },
  {
    code: 'BZ',
    value: 'belize'
  },
  {
    code: 'BJ',
    value: 'benin'
  },
  {
    code: 'BM',
    value: 'bermuda'
  },
  {
    code: 'BT',
    value: 'bhutan'
  },
  {
    code: 'BO',
    value: 'bolivia, plurinational state of'
  },
  {
    code: 'BQ',
    value: 'bonaire, sint eustatius and saba'
  },
  {
    code: 'BA',
    value: 'bosnia and herzegovina'
  },
  {
    code: 'BW',
    value: 'botswana'
  },
  {
    code: 'BV',
    value: 'bouvet island'
  },
  {
    code: 'BR',
    value: 'brazil'
  },
  {
    code: 'IO',
    value: 'british indian ocean territory'
  },
  {
    code: 'BN',
    value: 'brunei darussalam'
  },
  {
    code: 'BG',
    value: 'bulgaria'
  },
  {
    code: 'BF',
    value: 'burkina faso'
  },
  {
    code: 'BI',
    value: 'burundi'
  },
  {
    code: 'CV',
    value: 'cabo verde'
  },
  {
    code: 'KH',
    value: 'cambodia'
  },
  {
    code: 'CM',
    value: 'cameroon'
  },
  {
    code: 'CA',
    value: 'canada'
  },
  {
    code: 'KY',
    value: 'cayman islands '
  },
  {
    code: 'CF',
    value: 'central african republic '
  },
  {
    code: 'TD',
    value: 'chad'
  },
  {
    code: 'CL',
    value: 'chile'
  },
  {
    code: 'CN',
    value: 'china'
  },
  {
    code: 'CX',
    value: 'christmas island'
  },
  {
    code: 'CC',
    value: 'cocos (keeling) islands '
  },
  {
    code: 'CO',
    value: 'colombia'
  },
  {
    code: 'KM',
    value: 'comoros '
  },
  {
    code: 'CD',
    value: 'congo, the democratic republic'
  },
  {
    code: 'CG',
    value: 'congo '
  },
  {
    code: 'CK',
    value: 'cook islands '
  },
  {
    code: 'CR',
    value: 'costa rica'
  },
  {
    code: 'HR',
    value: 'croatia'
  },
  {
    code: 'CU',
    value: 'cuba'
  },
  {
    code: 'CW',
    value: 'curaçao'
  },
  {
    code: 'CY',
    value: 'cyprus'
  },
  {
    code: 'CZ',
    value: 'czechia'
  },
  {
    code: 'CI',
    value: "côte d'ivoire"
  },
  {
    code: 'DK',
    value: 'denmark'
  },
  {
    code: 'DJ',
    value: 'djibouti'
  },
  {
    code: 'DM',
    value: 'dominica'
  },
  {
    code: 'DO',
    value: 'dominican republic '
  },
  {
    code: 'EC',
    value: 'ecuador'
  },
  {
    code: 'EG',
    value: 'egypt'
  },
  {
    code: 'SV',
    value: 'el salvador'
  },
  {
    code: 'GQ',
    value: 'equatorial guinea'
  },
  {
    code: 'ER',
    value: 'eritrea'
  },
  {
    code: 'EE',
    value: 'estonia'
  },
  {
    code: 'SZ',
    value: 'eswatini'
  },
  {
    code: 'ET',
    value: 'ethiopia'
  },
  {
    code: 'FK',
    value: 'falkland islands, malvinas'
  },
  {
    code: 'FO',
    value: 'faroe islands '
  },
  {
    code: 'FJ',
    value: 'fiji'
  },
  {
    code: 'FI',
    value: 'finland'
  },
  {
    code: 'FR',
    value: 'france'
  },
  {
    code: 'GF',
    value: 'french guiana'
  },
  {
    code: 'PF',
    value: 'french polynesia'
  },
  {
    code: 'TF',
    value: 'french southern territories '
  },
  {
    code: 'GA',
    value: 'gabon'
  },
  {
    code: 'GM',
    value: 'gambia '
  },
  {
    code: 'GE',
    value: 'georgia'
  },
  {
    code: 'DE',
    value: 'germany'
  },
  {
    code: 'GH',
    value: 'ghana'
  },
  {
    code: 'GI',
    value: 'gibraltar'
  },
  {
    code: 'GR',
    value: 'greece'
  },
  {
    code: 'GL',
    value: 'greenland'
  },
  {
    code: 'GD',
    value: 'grenada'
  },
  {
    code: 'GP',
    value: 'guadeloupe'
  },
  {
    code: 'GU',
    value: 'guam'
  },
  {
    code: 'GT',
    value: 'guatemala'
  },
  {
    code: 'GG',
    value: 'guernsey'
  },
  {
    code: 'GN',
    value: 'guinea'
  },
  {
    code: 'GW',
    value: 'guinea-bissau'
  },
  {
    code: 'GY',
    value: 'guyana'
  },
  {
    code: 'HT',
    value: 'haiti'
  },
  {
    code: 'HM',
    value: 'heard island and mcdonald islands'
  },
  {
    code: 'VA',
    value: 'holy see '
  },
  {
    code: 'HN',
    value: 'honduras'
  },
  {
    code: 'HK',
    value: 'hong kong'
  },
  {
    code: 'HU',
    value: 'hungary'
  },
  {
    code: 'IS',
    value: 'iceland'
  },
  {
    code: 'IN',
    value: 'india'
  },
  {
    code: 'ID',
    value: 'indonesia'
  },
  {
    code: 'IR',
    value: 'iran'
  },
  {
    code: 'IQ',
    value: 'iraq'
  },
  {
    code: 'IE',
    value: 'ireland'
  },
  {
    code: 'IM',
    value: 'isle of man'
  },
  {
    code: 'IL',
    value: 'israel'
  },
  {
    code: 'IT',
    value: 'italy'
  },
  {
    code: 'JM',
    value: 'jamaica'
  },
  {
    code: 'JP',
    value: 'japan'
  },
  {
    code: 'JE',
    value: 'jersey'
  },
  {
    code: 'JO',
    value: 'jordan'
  },
  {
    code: 'KZ',
    value: 'kazakhstan'
  },
  {
    code: 'KE',
    value: 'kenya'
  },
  {
    code: 'KI',
    value: 'kiribati'
  },
  {
    code: 'KP',
    value: "korea, the democratic people's republic of)"
  },
  {
    code: 'KR',
    value: 'korea, the republic of'
  },
  {
    code: 'KW',
    value: 'kuwait'
  },
  {
    code: 'KG',
    value: 'kyrgyzstan'
  },
  {
    code: 'LA',
    value: "lao people's democratic republic "
  },
  {
    code: 'LV',
    value: 'latvia'
  },
  {
    code: 'LB',
    value: 'lebanon'
  },
  {
    code: 'LS',
    value: 'lesotho'
  },
  {
    code: 'LR',
    value: 'liberia'
  },
  {
    code: 'LY',
    value: 'libya'
  },
  {
    code: 'LI',
    value: 'liechtenstein'
  },
  {
    code: 'LT',
    value: 'lithuania'
  },
  {
    code: 'LU',
    value: 'luxembourg'
  },
  {
    code: 'MO',
    value: 'macao'
  },
  {
    code: 'MG',
    value: 'madagascar'
  },
  {
    code: 'MW',
    value: 'malawi'
  },
  {
    code: 'MY',
    value: 'malaysia'
  },
  {
    code: 'MV',
    value: 'maldives'
  },
  {
    code: 'ML',
    value: 'mali'
  },
  {
    code: 'MT',
    value: 'malta'
  },
  {
    code: 'MH',
    value: 'marshall islands '
  },
  {
    code: 'MQ',
    value: 'martinique'
  },
  {
    code: 'MR',
    value: 'mauritania'
  },
  {
    code: 'MU',
    value: 'mauritius'
  },
  {
    code: 'YT',
    value: 'mayotte'
  },
  {
    code: 'MX',
    value: 'mexico'
  },
  {
    code: 'FM',
    value: 'micronesia'
  },
  {
    code: 'MD',
    value: 'moldova'
  },
  {
    code: 'MC',
    value: 'monaco'
  },
  {
    code: 'MN',
    value: 'mongolia'
  },
  {
    code: 'ME',
    value: 'montenegro'
  },
  {
    code: 'MS',
    value: 'montserrat'
  },
  {
    code: 'MA',
    value: 'morocco'
  },
  {
    code: 'MZ',
    value: 'mozambique'
  },
  {
    code: 'MM',
    value: 'myanmar'
  },
  {
    code: 'NA',
    value: 'namibia'
  },
  {
    code: 'NR',
    value: 'nauru'
  },
  {
    code: 'NP',
    value: 'nepal'
  },
  {
    code: 'NL',
    value: 'netherlands '
  },
  {
    code: 'NC',
    value: 'new caledonia'
  },
  {
    code: 'NZ',
    value: 'new zealand'
  },
  {
    code: 'NI',
    value: 'nicaragua'
  },
  {
    code: 'NE',
    value: 'niger '
  },
  {
    code: 'NG',
    value: 'nigeria'
  },
  {
    code: 'NU',
    value: 'niue'
  },
  {
    code: 'NF',
    value: 'norfolk island'
  },
  {
    code: 'MP',
    value: 'northern mariana islands '
  },
  {
    code: 'NO',
    value: 'norway'
  },
  {
    code: 'OM',
    value: 'oman'
  },
  {
    code: 'PK',
    value: 'pakistan'
  },
  {
    code: 'PW',
    value: 'palau'
  },
  {
    code: 'PS',
    value: 'palestine, state of'
  },
  {
    code: 'PA',
    value: 'panama'
  },
  {
    code: 'PG',
    value: 'papua new guinea'
  },
  {
    code: 'PY',
    value: 'paraguay'
  },
  {
    code: 'PE',
    value: 'peru'
  },
  {
    code: 'PH',
    value: 'philippines '
  },
  {
    code: 'PN',
    value: 'pitcairn'
  },
  {
    code: 'PL',
    value: 'poland'
  },
  {
    code: 'PT',
    value: 'portugal'
  },
  {
    code: 'PR',
    value: 'puerto rico'
  },
  {
    code: 'QA',
    value: 'qatar'
  },
  {
    code: 'MK',
    value: 'republic of north macedonia'
  },
  {
    code: 'RO',
    value: 'romania'
  },
  {
    code: 'RU',
    value: 'russian federation '
  },
  {
    code: 'RW',
    value: 'rwanda'
  },
  {
    code: 'RE',
    value: 'réunion'
  },
  {
    code: 'BL',
    value: 'saint barthélemy'
  },
  {
    code: 'SH',
    value: 'saint helena, ascension and tristan da cunha'
  },
  {
    code: 'KN',
    value: 'saint kitts and nevis'
  },
  {
    code: 'LC',
    value: 'saint lucia'
  },
  {
    code: 'MF',
    value: 'saint martin'
  },
  {
    code: 'PM',
    value: 'saint pierre and miquelon'
  },
  {
    code: 'VC',
    value: 'saint vincent and the grenadines'
  },
  {
    code: 'WS',
    value: 'samoa'
  },
  {
    code: 'SM',
    value: 'san marino'
  },
  {
    code: 'ST',
    value: 'sao tome and principe'
  },
  {
    code: 'SA',
    value: 'saudi arabia'
  },
  {
    code: 'SN',
    value: 'senegal'
  },
  {
    code: 'RS',
    value: 'serbia'
  },
  {
    code: 'SC',
    value: 'seychelles'
  },
  {
    code: 'SL',
    value: 'sierra leone'
  },
  {
    code: 'SG',
    value: 'singapore'
  },
  {
    code: 'SX',
    value: 'sint maarten'
  },
  {
    code: 'SK',
    value: 'slovakia'
  },
  {
    code: 'SI',
    value: 'slovenia'
  },
  {
    code: 'SB',
    value: 'solomon islands'
  },
  {
    code: 'SO',
    value: 'somalia'
  },
  {
    code: 'ZA',
    value: 'south africa'
  },
  {
    code: 'GS',
    value: 'south georgia and the south sandwich islands'
  },
  {
    code: 'SS',
    value: 'south sudan'
  },
  {
    code: 'ES',
    value: 'spain'
  },
  {
    code: 'LK',
    value: 'sri lanka'
  },
  {
    code: 'SD',
    value: 'sudan '
  },
  {
    code: 'SR',
    value: 'suriname'
  },
  {
    code: 'SJ',
    value: 'svalbard and jan mayen'
  },
  {
    code: 'SE',
    value: 'sweden'
  },
  {
    code: 'CH',
    value: 'switzerland'
  },
  {
    code: 'SY',
    value: 'syrian arab republic'
  },
  {
    code: 'TW',
    value: 'taiwan'
  },
  {
    code: 'TJ',
    value: 'tajikistan'
  },
  {
    code: 'TZ',
    value: 'tanzania, united republic of'
  },
  {
    code: 'TH',
    value: 'thailand'
  },
  {
    code: 'TL',
    value: 'timor-leste'
  },
  {
    code: 'TG',
    value: 'togo'
  },
  {
    code: 'TK',
    value: 'tokelau'
  },
  {
    code: 'TO',
    value: 'tonga'
  },
  {
    code: 'TT',
    value: 'trinidad and tobago'
  },
  {
    code: 'TN',
    value: 'tunisia'
  },
  {
    code: 'TR',
    value: 'turkey'
  },
  {
    code: 'TM',
    value: 'turkmenistan'
  },
  {
    code: 'TC',
    value: 'turks and caicos islands '
  },
  {
    code: 'TV',
    value: 'tuvalu'
  },
  {
    code: 'UG',
    value: 'uganda'
  },
  {
    code: 'UA',
    value: 'ukraine'
  },
  {
    code: 'AE',
    value: 'united arab emirates '
  },
  {
    code: 'GB',
    value: 'united kingdom of great britain and northern ireland '
  },
  {
    code: 'UM',
    value: 'united states minor outlying islands '
  },
  {
    code: 'US',
    value: 'united states of america '
  },
  {
    code: 'UY',
    value: 'uruguay'
  },
  {
    code: 'UZ',
    value: 'uzbekistan'
  },
  {
    code: 'VU',
    value: 'vanuatu'
  },
  {
    code: 'VE',
    value: 'venezuela'
  },
  {
    code: 'VN',
    value: 'viet nam'
  },
  {
    code: 'VG',
    value: 'british virgin islands'
  },
  {
    code: 'VI',
    value: 'united states virgin islands'
  },
  {
    code: 'WF',
    value: 'wallis and futuna'
  },
  {
    code: 'EH',
    value: 'western sahara'
  },
  {
    code: 'YE',
    value: 'yemen'
  },
  {
    code: 'ZM',
    value: 'zambia'
  },
  {
    code: 'ZW',
    value: 'zimbabwe'
  },
  {
    code: 'AX',
    value: 'åland islands'
  }
];

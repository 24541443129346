import * as React from "react"

import {cn} from "../../lib/utils"

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    (<input
      type={type}
      autoComplete='off'
      className={cn(
        "h-10 border-[1.5px] border-solid border-black/20 sm:px-4 px-3 text-tx-secondary text-md outline-0 rounded-lg bg-black/0 placeholder:text-sm placeholder:font-normal placeholder:text-tx-secondary/50 font-medium",
        className
      )}
      ref={ref}
      {...props} />)
  );
})
Input.displayName = "Input"

export {Input}
